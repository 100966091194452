import { useAuth0 } from '@auth0/auth0-react';
import { mergeStyles } from '@fluentui/react';
import {
  DealRoom,
  DealRoomArtifact,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import { useNavigate } from '../../../Hooks/useNavigate';
import { DealRoomArtifacts } from '../Artifacts/DealRoomArtifacts';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';

type DealRoomBuyersArtifactsTabProps = {
  className?: string;
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: Pick<DetailedDealRoom, 'activity' | 'artifacts'>;
  refreshDealRoom: () => Promise<unknown>;
};

export const DealRoomBuyersArtifactsTab = ({
  className,
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: DealRoomBuyersArtifactsTabProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const styles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  });

  return (
    <div className={classNames(className, styles)}>
      <DealRoomArtifacts
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        artifacts={dealRoom.artifacts}
        activity={dealRoom.activity}
        showAdd={true}
        refreshDealRoom={refreshDealRoom}
        onClick={(id: DealRoomArtifact['id']) => {
          const artifact = dealRoom.artifacts.find((a) => a.id === id);

          if (!artifact) {
            return;
          }

          switch (artifact.type) {
            case 'MEETINGFLOW': {
              navigate(
                `/organization/${organizationSlug}/plan/${artifact.meetingflowId}`,
              );
              break;
            }
            default: {
              navigate(
                `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${id}${
                  !!searchParams.get('buyerView') ? '?buyerView=true' : ''
                }`,
              );
            }
          }
        }}
        onAdd={(_) => refreshDealRoom()}
        onUpdate={() => refreshDealRoom()}
        onDelete={(_) => refreshDealRoom()}
        useTableView
      />
    </div>
  );
};
