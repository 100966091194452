import {
  Checkbox,
  Facepile,
  IPersona,
  mergeStyles,
  PrimaryButton,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { useOrganization } from '../../Hooks/useOrganization';
import { useTitle } from '../../Hooks/useTitle';

export type DealRoomSellersViewProps = {
  organizationSlug: string;
  dealRoomId: number;
};
export const DealRoomSellersView = ({
  organizationSlug,
  dealRoomId,
}: DealRoomSellersViewProps) => {
  const { internalDomains } = useOrganization();
  const theme = useTheme();
  const { dealRoom, isLoading, isError, refetch, refetchAll } = useDealRoom(
    organizationSlug,
    dealRoomId,
  );

  useTitle(dealRoom?.name ? `${dealRoom.name} - Deal Room` : 'Deal Room');

  const contactPersonas = useMemo(() => {
    return (
      dealRoom?.contacts
        ?.filter((c) => !internalDomains.includes(c.emailDomain))
        .map((c) => {
          const label = c.name || c.email;

          return {
            imageUrl: c.avatarUrl ?? undefined,
            personaName: label,
            data: c,
          } satisfies IPersona;
        }) ?? []
    );
  }, [internalDomains, dealRoom?.contacts]);

  const containerClass = mergeStyles({
    margin: '1rem',
  });
  const headerClass = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '1rem',
  });
  const contentClass = mergeStyles({
    display: 'block',
    gap: '1rem',
  });

  const dealCardClass = mergeStyles({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    gap: '1rem',
    border: `1px solid ${theme.palette.neutralLighterAlt}`,
  });

  const navigate = useNavigate();

  if (isLoading) {
    return <Spinner />;
  }

  if (!dealRoom) {
    return <div>Deal Room not found</div>;
  }

  return (
    <div className={containerClass}>
      <div className={headerClass}>
        <PrimaryButton onClick={() => navigate(`?buyerView=true`)}>
          Go to buyer's page
        </PrimaryButton>
        <Text variant="xLarge" block>
          {dealRoom.name}
        </Text>
        {dealRoom.description && (
          <Text variant="small" block>
            {dealRoom.description}
          </Text>
        )}
      </div>
      <div className={contentClass}>
        <div className={dealCardClass}>
          <Text variant="medium">Contacts</Text>
          <Facepile personas={contactPersonas} />
        </div>
      </div>
      <div className={dealCardClass}>
        <Text variant="medium" block>
          Estimated Close Date
        </Text>
        <Text variant="small" block>
          {dealRoom.expectedCloseDate
            ? humanizeDateTime(dealRoom.expectedCloseDate)
            : 'No Close Date Set'}
        </Text>
      </div>
      <div className={dealCardClass}>
        <Text variant="medium" block>
          Timeline
        </Text>
        {dealRoom.activity.map((a, idx) => (
          <div key={idx}>
            <Text variant="small" block>
              {a.type}
            </Text>
            <Text variant="tiny" block>
              {humanizeDateTime(a.createdAt)}
            </Text>
          </div>
        ))}
      </div>
      <div className={dealCardClass}>
        <Text variant="medium" block>
          Mutual Action Plan
        </Text>
        {dealRoom.mutualActionItems.length ? (
          dealRoom.mutualActionItems.map((actionitem) => {
            return (
              <Checkbox
                key={actionitem.id}
                label={actionitem.actionItem}
                checked={!!actionitem.completedAt}
              />
            );
          })
        ) : (
          <>
            <Text>No items</Text>
          </>
        )}
      </div>
    </div>
  );
};

export default DealRoomSellersView;
