import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
  Separator,
  PivotItem,
  IStackItemStyles,
} from '@fluentui/react';
import {
  IANA_TIMEZONES,
  isTimezone,
  Timezone,
} from '@meetingflow/common/Timezones';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect, useMemo } from 'react';
import { StyledSpinner } from './StyledSpinner';
import { DEFAULT_STACK_TOKENS } from '../Helpers/Layout';
import { useTitle } from '../Hooks/useTitle';
import { useUserProfile } from '../Hooks/useProfile';
import { useLightOrDarkMode } from '../Hooks/useLightOrDarkMode';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { OmitValues } from '@meetingflow/common/ObjectHelpers';
import {
  ReceivingDailyEmailPreference,
  ReceivingMeetingNotificationPreference,
  ReceivingMentionNotificationEmailPreference,
  ReceivingTaskAssignedNotificationPreference,
  ReceivingTaskCompletedNotificationPreference,
  ReceivingWeeklyEmailPreference,
  AutomaticAiSummaryPreference,
  ThemePreference,
  DailyEmailAiSummaryPreference,
  NewMeetingflowDialogPreference,
} from '@meetingflow/common/Api/data-contracts';
import { UserApiClient } from '../Services/NetworkCommon';
import {
  SettingsPageLayout,
  settingsIndentedControlClass,
  settingsPrimaryButtonClass,
  settingsRootStackStyles,
  settingsSubHeadClass,
  settingsToggleStyles,
} from './Organization/Settings/SettingsPageLayout';
import { SettingsStyledPivot } from './Organization/Settings/StyledPivot';
import useBreakpoints from '../Hooks/useBreakpoints';

const timezones = [...IANA_TIMEZONES];

const timezoneOptions: IDropdownOption[] = timezones.map((t) => {
  return {
    key: t,
    text: t,
  };
});

const themeOptions: IDropdownOption<ThemePreference>[] = [
  {
    key: 'SYSTEM',
    text: 'Use system theme',
    data: 'SYSTEM',
  },
  {
    key: 'LIGHT',
    text: 'Light theme',
    data: 'LIGHT',
  },
  {
    key: 'DARK',
    text: 'Dark theme',
    data: 'DARK',
  },
];

type MeetingPlanUserProfileForm = {
  name: string;
  timezone?: Timezone;
  preferenceTheme: ThemePreference;
  preferenceReceivingDailyEmail: ReceivingDailyEmailPreference;
  preferenceReceivingWeeklyEmail: ReceivingWeeklyEmailPreference;
  preferenceReceivingMentionNotificationEmail: ReceivingMentionNotificationEmailPreference;
  preferenceReceivingMeetingNotification: ReceivingMeetingNotificationPreference;
  preferenceReceivingTaskAssignedNotification: ReceivingTaskAssignedNotificationPreference;
  preferenceReceivingTaskCompletedNotification: ReceivingTaskCompletedNotificationPreference;
  preferenceAutomaticAiSummary: AutomaticAiSummaryPreference;
  preferenceDailyEmailAiSummary: DailyEmailAiSummaryPreference;
  preferenceNewMeetingflowDialog: NewMeetingflowDialogPreference;
};

const formSchema = yup
  .object({
    name: yup.string().required(),
    timezone: yup.string().oneOf(timezones, 'Invalid timezone').optional(),
    themePreference: yup.string().oneOf(['SYSTEM', 'DARK', 'LIGHT']),
  })
  .required();

export const Profile = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const appInsights = useAppInsightsContext();
  const { setThemePreference } = useLightOrDarkMode();

  const defaultTz = useMemo(() => {
    const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (timezones as string[]).includes(browserTz)
      ? (browserTz as Timezone)
      : undefined;
  }, []);

  const {
    user: profileData,
    updateUserProfileAsync,
    isLoading: profileIsLoading,
    refetch: refetchProfile,
  } = useUserProfile();

  const { name } = user!;

  useTitle(`${name} Profile`);

  const breakpoints = useBreakpoints();

  const {
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { isValid, errors, isDirty },
  } = useForm<MeetingPlanUserProfileForm>({
    defaultValues: {
      name: profileData?.name || name,
      timezone: isTimezone(profileData?.timezone)
        ? profileData?.timezone
        : defaultTz,
      preferenceTheme: profileData?.preferenceTheme || 'SYSTEM',
      preferenceReceivingDailyEmail:
        profileData?.preferenceReceivingDailyEmail || 'OPT_IN',
      preferenceReceivingWeeklyEmail:
        profileData?.preferenceReceivingWeeklyEmail || 'OPT_IN',
      preferenceReceivingMentionNotificationEmail:
        profileData?.preferenceReceivingMentionNotificationEmail || 'OPT_IN',
      preferenceReceivingMeetingNotification:
        profileData?.preferenceReceivingMeetingNotification || 'OPT_IN',
      preferenceReceivingTaskAssignedNotification:
        profileData?.preferenceReceivingTaskAssignedNotification || 'OPT_IN',
      preferenceReceivingTaskCompletedNotification:
        profileData?.preferenceReceivingTaskCompletedNotification || 'OPT_IN',
      preferenceAutomaticAiSummary:
        profileData?.preferenceAutomaticAiSummary || 'ENABLED',
      preferenceDailyEmailAiSummary:
        profileData?.preferenceDailyEmailAiSummary || 'OPT_IN',
      preferenceNewMeetingflowDialog:
        profileData?.preferenceNewMeetingflowDialog || 'ENABLED',
    },
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  useEffect(() => {
    if (profileData) {
      setValue('name', profileData.name ?? '');
      setValue(
        'timezone',
        isTimezone(profileData.timezone) ? profileData.timezone : defaultTz,
      );
      setValue('preferenceTheme', profileData.preferenceTheme);
      setValue(
        'preferenceReceivingDailyEmail',
        profileData.preferenceReceivingDailyEmail,
      );
      setValue(
        'preferenceReceivingWeeklyEmail',
        profileData.preferenceReceivingWeeklyEmail,
      );
      setValue(
        'preferenceReceivingMentionNotificationEmail',
        profileData.preferenceReceivingMentionNotificationEmail,
      );
      setValue(
        'preferenceReceivingMeetingNotification',
        profileData.preferenceReceivingMeetingNotification,
      );
      setValue(
        'preferenceReceivingTaskAssignedNotification',
        profileData.preferenceReceivingTaskAssignedNotification,
      );
      setValue(
        'preferenceReceivingTaskCompletedNotification',
        profileData.preferenceReceivingTaskCompletedNotification,
      );
      setValue(
        'preferenceAutomaticAiSummary',
        profileData.preferenceAutomaticAiSummary,
      );
      setValue(
        'preferenceDailyEmailAiSummary',
        profileData.preferenceDailyEmailAiSummary,
      );
      setValue(
        'preferenceNewMeetingflowDialog',
        profileData.preferenceNewMeetingflowDialog,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  const onSubmit = handleSubmit(async (data) => {
    if (isValid) {
      const result = await updateUserProfileAsync(data);
      if (result.status === 200) {
        setThemePreference(data.preferenceTheme);
        reset({
          name: result.data.name || name,
          timezone: isTimezone(result.data.timezone)
            ? result.data.timezone
            : defaultTz,
          preferenceTheme: result.data.preferenceTheme,
          preferenceReceivingDailyEmail:
            result.data.preferenceReceivingDailyEmail,
          preferenceReceivingWeeklyEmail:
            result.data.preferenceReceivingWeeklyEmail,
          preferenceReceivingMentionNotificationEmail:
            result.data.preferenceReceivingMentionNotificationEmail,
          preferenceReceivingMeetingNotification:
            result.data.preferenceReceivingMeetingNotification,
          preferenceReceivingTaskAssignedNotification:
            result.data.preferenceReceivingTaskAssignedNotification,
          preferenceReceivingTaskCompletedNotification:
            result.data.preferenceReceivingTaskCompletedNotification,
          preferenceAutomaticAiSummary:
            result.data.preferenceAutomaticAiSummary,
          preferenceDailyEmailAiSummary:
            result.data.preferenceDailyEmailAiSummary,
          preferenceNewMeetingflowDialog:
            result.data.preferenceNewMeetingflowDialog,
        });

        if (
          result.data.preferenceReceivingDailyEmail !==
          profileData?.preferenceReceivingDailyEmail
        ) {
          appInsights.trackEvent({
            name: 'DAILY_EMAIL_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceReceivingDailyEmail,
            },
          });
        }

        if (
          result.data.preferenceReceivingWeeklyEmail !==
          profileData?.preferenceReceivingWeeklyEmail
        ) {
          appInsights.trackEvent({
            name: 'WEEKLY_EMAIL_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceReceivingWeeklyEmail,
            },
          });
        }

        if (
          result.data.preferenceReceivingMentionNotificationEmail !==
          profileData?.preferenceReceivingMentionNotificationEmail
        ) {
          appInsights.trackEvent({
            name: 'MENTION_NOTIFICATION_EMAIL_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceReceivingMentionNotificationEmail,
            },
          });
        }

        if (
          result.data.preferenceReceivingMeetingNotification !==
          profileData?.preferenceReceivingMeetingNotification
        ) {
          appInsights.trackEvent({
            name: 'MEETING_NOTIFICATION_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceReceivingMeetingNotification,
            },
          });
        }

        if (
          result.data.preferenceReceivingTaskAssignedNotification !==
          profileData?.preferenceReceivingTaskAssignedNotification
        ) {
          appInsights.trackEvent({
            name: 'TASK_ASSIGNED_NOTIFICATION_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceReceivingTaskAssignedNotification,
            },
          });
        }

        if (
          result.data.preferenceReceivingTaskCompletedNotification !==
          profileData?.preferenceReceivingTaskCompletedNotification
        ) {
          appInsights.trackEvent({
            name: 'TASK_COMPLETED_NOTIFICATION_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceReceivingTaskCompletedNotification,
            },
          });
        }

        if (
          result.data.preferenceAutomaticAiSummary !==
          profileData?.preferenceAutomaticAiSummary
        ) {
          appInsights.trackEvent({
            name: 'AUTOMATIC_AI_SUMMARY_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceAutomaticAiSummary,
            },
          });
        }

        if (
          result.data.preferenceDailyEmailAiSummary !==
          profileData?.preferenceDailyEmailAiSummary
        ) {
          appInsights.trackEvent({
            name: 'DAILY_EMAIL_AI_SUMMARY_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceDailyEmailAiSummary,
            },
          });
        }

        if (
          result.data.preferenceNewMeetingflowDialog !==
          profileData?.preferenceNewMeetingflowDialog
        ) {
          appInsights.trackEvent({
            name: 'NEW_MEETINGFLOW_DIALOG_PREFERENCE_TOGGLE',
            properties: {
              status: result.data.preferenceNewMeetingflowDialog,
              surface: 'PROFILE',
            },
          });
        }

        await refetchProfile();
      }
    }
  });

  const halfWidthStackItemStyles = {
    root: {
      maxWidth: breakpoints.md ? 'calc(50% - 1rem)' : 'calc(100% - 1rem)',
    },
  } as IStackItemStyles;

  if (profileIsLoading) {
    return <StyledSpinner />;
  }

  return (
    <SettingsPageLayout title="Your Profile" subtitle={user?.name}>
      <SettingsStyledPivot>
        <PivotItem key="personal" headerText="Personal">
          <Stack tokens={DEFAULT_STACK_TOKENS} styles={settingsRootStackStyles}>
            <h2 className={settingsSubHeadClass}>About You</h2>
            <div className={settingsIndentedControlClass}>
              <Stack tokens={DEFAULT_STACK_TOKENS}>
                <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue={name}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        errorMessage={errors.name?.message}
                        label="Name"
                        value={value}
                        onBlur={onBlur}
                        onChange={(_e, newText) => onChange(newText || '')}
                      />
                    )}
                  />{' '}
                </Stack.Item>
                <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
                  <TextField
                    value={user?.email}
                    label="Email Address"
                    disabled
                  />
                </Stack.Item>
                <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
                  <Controller
                    name="timezone"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        errorMessage={errors.timezone?.message}
                        options={timezoneOptions}
                        selectedKey={field.value}
                        onBlur={field.onBlur}
                        onChange={(_evt, option, _index) =>
                          field.onChange(option?.key as Timezone | undefined)
                        }
                        label="Timezone"
                      />
                    )}
                  />
                </Stack.Item>
                <Stack.Item>
                  <PrimaryButton
                    className={settingsPrimaryButtonClass}
                    disabled={!isValid || !isDirty}
                    text="Save"
                    onClick={onSubmit}
                  />
                </Stack.Item>
              </Stack>
            </div>
          </Stack>
        </PivotItem>

        <PivotItem key="notifications" headerText="Notifications">
          <Stack tokens={DEFAULT_STACK_TOKENS}>
            <h2 className={settingsSubHeadClass}>Meeting Reminders</h2>
            <div className={settingsIndentedControlClass}>
              <Stack.Item grow={1}>
                <Controller
                  name="preferenceReceivingDailyEmail"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Receive morning email detailing external meetings"
                      styles={settingsToggleStyles}
                      checked={field.value === 'OPT_IN'}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'OPT_IN' : 'OPT_OUT');
                      }}
                      role="checkbox"
                    />
                  )}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <Controller
                  name="preferenceReceivingWeeklyEmail"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Receive weekly activity summaries"
                      styles={settingsToggleStyles}
                      checked={field.value === 'OPT_IN'}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'OPT_IN' : 'OPT_OUT');
                      }}
                      role="checkbox"
                    />
                  )}
                />
              </Stack.Item>

              <Stack.Item grow={1}>
                <Controller
                  name="preferenceReceivingMeetingNotification"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Receive Slack notification reminders for external meetings"
                      styles={settingsToggleStyles}
                      checked={field.value === 'OPT_IN'}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'OPT_IN' : 'OPT_OUT');
                      }}
                      role="checkbox"
                    />
                  )}
                />
              </Stack.Item>

              <Stack.Item grow={1}>
                <Controller
                  name="preferenceReceivingTaskAssignedNotification"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Receive email notifications when you are assigned Action Items"
                      styles={settingsToggleStyles}
                      checked={field.value === 'OPT_IN'}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'OPT_IN' : 'OPT_OUT');
                      }}
                    />
                  )}
                />
              </Stack.Item>

              <Stack.Item grow={1}>
                <Controller
                  name="preferenceReceivingTaskCompletedNotification"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Receive email notifications when an Action Items you created, or are assigned, are marked complete"
                      checked={field.value === 'OPT_IN'}
                      styles={settingsToggleStyles}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'OPT_IN' : 'OPT_OUT');
                      }}
                      role="checkbox"
                    />
                  )}
                />
              </Stack.Item>

              <Stack.Item grow={1}>
                <Controller
                  name="preferenceReceivingMentionNotificationEmail"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Receive email notifications when you are mentioned in Meetingflows"
                      checked={field.value === 'OPT_IN'}
                      styles={settingsToggleStyles}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'OPT_IN' : 'OPT_OUT');
                      }}
                      role="checkbox"
                    />
                  )}
                />
              </Stack.Item>
              <Stack.Item>
                <PrimaryButton
                  className={settingsPrimaryButtonClass}
                  disabled={!isValid || !isDirty}
                  text="Save"
                  onClick={onSubmit}
                />
              </Stack.Item>
            </div>
          </Stack>
        </PivotItem>

        <PivotItem key="preferences" headerText="Preferences">
          <Stack tokens={DEFAULT_STACK_TOKENS}>
            <h2 className={settingsSubHeadClass}>AI Functionality</h2>
            <div className={settingsIndentedControlClass}>
              <Stack.Item grow={1}>
                <Controller
                  name="preferenceAutomaticAiSummary"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Automatically generate AI summaries"
                      styles={settingsToggleStyles}
                      checked={field.value === 'ENABLED'}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'ENABLED' : 'DISABLED');
                      }}
                      role="checkbox"
                    />
                  )}
                />
              </Stack.Item>
              <Stack.Item grow={1}>
                <Controller
                  name="preferenceDailyEmailAiSummary"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label="Include AI summaries in daily email"
                      styles={settingsToggleStyles}
                      checked={field.value === 'OPT_IN'}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'OPT_IN' : 'OPT_OUT');
                      }}
                      role="checkbox"
                    />
                  )}
                />
              </Stack.Item>
            </div>

            <h2 className={settingsSubHeadClass}>User Interface</h2>
            <div className={settingsIndentedControlClass}>
              <Stack.Item grow={1}>
                <Controller
                  name="preferenceNewMeetingflowDialog"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      label='Show the "New Meetingflow" dialog'
                      styles={settingsToggleStyles}
                      checked={field.value === 'ENABLED'}
                      onChange={(_e, checked) => {
                        field.onChange(checked ? 'ENABLED' : 'DISABLED');
                      }}
                      role="checkbox"
                    />
                  )}
                />
              </Stack.Item>
            </div>

            <h2 className={settingsSubHeadClass}>Theme</h2>
            <div className={settingsIndentedControlClass}>
              <Stack.Item grow={1} styles={halfWidthStackItemStyles}>
                <Controller
                  name="preferenceTheme"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      errorMessage={errors.preferenceTheme?.message}
                      options={themeOptions}
                      selectedKey={field.value}
                      onBlur={field.onBlur}
                      onChange={(_evt, option, _index) => {
                        if (option?.key) {
                          field.onChange(option?.key as ThemePreference);
                        }
                      }}
                      label="Theme"
                    />
                  )}
                />
              </Stack.Item>
            </div>
            <Stack.Item>
              <PrimaryButton
                className={settingsPrimaryButtonClass}
                disabled={!isValid || !isDirty}
                text="Save"
                onClick={onSubmit}
              />
            </Stack.Item>
          </Stack>
        </PivotItem>
      </SettingsStyledPivot>
    </SettingsPageLayout>
  );
};

export default Profile;
