import { useAuth0 } from '@auth0/auth0-react';
import {
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  Text,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { useForceUpdate } from '@fluentui/react-hooks';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  uploadFileToDealRoom,
} from '../../../Helpers/FileHelpers';
import { externalize, isUrl } from '../../../Helpers/URLHelpers';
import { AsyncPrimaryButton } from '../../HOC/AsyncButton';
import { BaseModal } from '../../MeetingPlans/Dialogs/BaseModal';
import { DealRoomsApiClient, MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { MeetingPlanQuery, OrganizationMeetingPlansQuery, OrganizationMeetingsHappeningSoon, OrganizationUpcomingMeetings } from '../../../QueryNames';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import { useQueryClient } from 'react-query';

const choiceStyle = { gridArea: 'type' };
const nameStyle = { root: { gridArea: 'title' } };
const linkStyle = { root: { gridArea: 'url' } };
const filePickerStyle = { gridArea: 'url' };
const cancelButtonStyle = { gridArea: 'cancel', marginTop: '3rem' };
const addButtonStyle = { gridArea: 'accept', marginTop: '3rem' };

const options: IChoiceGroupOption[] = [
  { key: 'LINK', text: 'Link' },
  { key: 'FILE', text: 'File' },
  { key: 'MEETINGFLOW', text: 'Meeting' },
];

type AddDealRoomArtifactDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  file?: File;
  onAdd: (item: DealRoomArtifact) => void;
  onDismiss: () => void;
};
export const AddDealRoomArtifactDialog = ({
  organizationSlug,
  dealRoomId,
  file,
  onAdd,
  onDismiss,
}: AddDealRoomArtifactDialogProps) => {
  const forceUpdate = useForceUpdate();
  const { getAccessTokenSilently } = useAuth0();
  const [artifactType, setArtifactType] = useState<DealRoomArtifact['type']>(
    file ? 'FILE' : 'LINK',
  );
  const [title, setTitle] = useState(file?.name || '');
  const [url, setUrl] = useState('');

  const filePickerRef = useRef<HTMLInputElement>(null);

  const client = useQueryClient();

  const modalStyles = mergeStyles({
    '.ms-Dialog-main': {
      width: '60%',
      height: 'auto',
      maxHeight: '60%',
      paddingBottom: '.5rem',
    },
  });

  const dialogContentStyle = mergeStyles({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr auto auto',
    gridTemplateRows: 'auto auto auto auto',
    gridTemplateAreas: `
    'type type type type'
    'title title title title'
    'url url url url'
    'none0 none1 cancel accept'
    `,
    gridColumnGap: '0.5rem',
    gridRowGap: '0.5rem',
    padding: '1rem',
  });

  const onAddClick = async () => {
    if (!title) {
      return;
    }

    if (artifactType === 'FILE') {
      if (
        !file &&
        (!filePickerRef.current || !filePickerRef.current.files?.length)
      ) {
        return;
      }

      const uploadFile = file || filePickerRef.current?.files?.[0];

      if (!uploadFile) {
        return;
      }

      const token = await getAccessTokenSilently();
      const uploadPromise = uploadFileToDealRoom(token, organizationSlug, dealRoomId, uploadFile)

      const createdArtifact = await toast.promise(uploadPromise, {
        loading: 'Uploading artifact',
        success: 'Successfully uploaded artifact',
        error: 'Failed to upload artifact',
      });

      onAdd(createdArtifact);
    } else {
      if (!url || !isUrl(externalize(url))) {
        return;
      }

      const token = await getAccessTokenSilently();

      if (artifactType === 'LINK') {

        const createdArtifactResponse = await DealRoomsApiClient.createLinkArtifact(organizationSlug, dealRoomId, {
          type: 'LINK',
          name: title,
          url,
        }, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });

        onAdd(createdArtifactResponse.data);

      } else if (artifactType === 'MEETINGFLOW') {

        const createdMeetingFlow = await toast.promise(
          MeetingflowsApiClient.postMeetingflow(organizationSlug, {
            title,
            location: url,
            record: true             
          }, {
            headers: { Authorization: `Bearer ${token}` },
            validateStatus: (code) => code === 201 || code === 302,
          }),
          {
            loading: 'Creating Meetingflow',
            success: (result) => {
              Promise.all([
                client.invalidateQueries(
                  OrganizationMeetingPlansQuery(organizationSlug),
                ),
                client.invalidateQueries(
                  OrganizationMeetingsHappeningSoon(organizationSlug),
                ),
                client.invalidateQueries(
                  OrganizationUpcomingMeetings(organizationSlug),
                ),
              ]);
  
              client.setQueryData(
                MeetingPlanQuery(organizationSlug, result.data.id),
                result,
              );
    
              if (result.status === 201) {
                return `A Meetingflow has been created!`;
              } else if (result.status === 302) {
                return `A Meetingflow has been created!`;
              }
  
              return null;
            },
            error: (err) => {
              if (err && isAxiosErrorResponse(err, 403)) {
                return `You don't have permission to create Meetingflows in this workspace`;
              }
  
              return 'Something went wrong creating the Meetingflow, please try again';
            },
          },
        );
  
        const createdArtifactResponse = await DealRoomsApiClient.createLinkArtifact(organizationSlug, dealRoomId, {
          type: 'MEETINGFLOW',
          meetingflowId: createdMeetingFlow.data.id//"47103014-11e4-40fe-b703-36b155608162",
        }, {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });

        onAdd(createdArtifactResponse.data);
      }
    }
  };

  return (
    <BaseModal
      className={modalStyles}
      isOpen
      title="Add document to document drive"
      onDismiss={onDismiss}
    >
      <div className={dialogContentStyle}>
        {!file ? (
          <ChoiceGroup
            label="Artifact type"
            style={choiceStyle}
            selectedKey={artifactType}
            options={options}
            onChange={(e, o) =>
              setArtifactType((o?.key as DealRoomArtifact['type']) || 'LINK')
            }
          />
        ) : (
          <Text>Adding {file.name}</Text>
        )}
        <TextField
          styles={nameStyle}
          label="Name"
          title="Name"
          value={title}
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter') {
              onAddClick();
            }
          }}
          onChange={(e, newValue) => setTitle(newValue ?? '')}
        />
        {artifactType === 'LINK' || artifactType === 'MEETINGFLOW' ? (
          <TextField
            styles={linkStyle}
            label="URL"
            title="URL"
            value={url}
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onAddClick();
              }
            }}
            onChange={(e, newValue) => setUrl(newValue ?? '')}
          />
        ) : !file ? (
          <input
            ref={filePickerRef}
            style={filePickerStyle}
            type="file"
            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onAddClick();
              }
            }}
            onChange={() => {
              if (filePickerRef.current?.files?.length && !title) {
                setTitle(filePickerRef.current.files[0].name);
              }
              forceUpdate();
            }}
          />
        ) : null}
        <DefaultButton style={cancelButtonStyle} onClick={onDismiss}>
          Cancel
        </DefaultButton>
        <AsyncPrimaryButton
          style={addButtonStyle}
          disabled={
            !title ||
            (artifactType === 'MEETINGFLOW' && !isUrl(externalize(url))) ||
            (artifactType === 'LINK' && !isUrl(externalize(url))) ||
            (artifactType === 'FILE' &&
              !file &&
              !filePickerRef.current?.files?.length)
          }
          onClick={onAddClick}
        >
          Add
        </AsyncPrimaryButton>
      </div>
    </BaseModal>
  );
};
