export const OrganizationForbidden = () => {
  return (
    <div>
      Welcome to {import.meta.env.VITE_SITE_NAME}! You don't have access to that
      page. If you believe you should be able to view this, contact the owner of
      the workspace or the person who shared this link with you.
    </div>
  );
};

export default OrganizationForbidden;
