import { mergeStyles, Text, useTheme } from '@fluentui/react';
import classNames from 'classnames';
import DealRoomCard from './DealRoomCard';
import DealRoomRadioButton from './DealRoomRadioButton';

type DealRoomRadioPreferenceProps = {
  icon?: string;
  label: string;
  value: number | null;
  min: number;
  max: number;
  leftLabel: string;
  rightLabel: string;
  onChange: (value: number) => void;
};

export const DealRoomRadioPreference = ({
  icon,
  label,
  value,
  min,
  max,
  leftLabel,
  rightLabel,
  onChange,
}: DealRoomRadioPreferenceProps) => {
  const theme = useTheme();

  const containerStyle = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    '.content': {
      padding: '12px 24px 12px 24px',
    },
    '.labels': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8px',

      '.label': {
        color: '#6F7288',
      },
    },

    '.radio-container': {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      padding: '8px',
      backgroundColor: theme.palette.neutralLighterAlt,
      borderRadius: '4px',
      borderStyle: 'solid',
      borderColor: theme.palette.neutralLight,

      '.radio-input': {
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        border: '2px solid black',
        backgroundColor: 'transparent',
        cursor: 'pointer',
      },

      '.radio-input:checked': {
        accentColor: theme.palette.themePrimary,
      },
    },
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsed = parseInt(e.target.value, 10);

    if (isNaN(parsed)) {
      return;
    }

    if (parsed < min || parsed > max) {
      return;
    }

    onChange(parsed);
  };

  return (
    <DealRoomCard
      icon={icon}
      title={label}
      className={classNames(containerStyle)}
    >
      <div className="content">
        <div className="labels">
          <div>
            <Text className="label" variant="small">
              {leftLabel}
            </Text>
          </div>
          <div>
            <Text className="label" variant="small">
              {rightLabel}
            </Text>
          </div>
        </div>
        <div className="radio-container">
          {Array.from({ length: max - min + 1 }).map((_, index) => {
            const radioValue = index + min;
            return (
              <DealRoomRadioButton
                key={radioValue}
                radioKey={radioValue}
                checked={value === radioValue}
                onChange={handleChange}
              />
            );
          })}
        </div>
      </div>
    </DealRoomCard>
  );
};
