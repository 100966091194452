import { Icon, ITheme, mergeStyleSets, Text, useTheme } from '@fluentui/react';
import {
  DealRoomArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useAddDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useAddDealRoomArtifactDialog';
import { DropTarget } from '../../Common/DropTarget';
import { DealRoomArtifactsTable } from './DealRoomArtifactsTable';
import { DealRoomAsyncCommandBarButton } from '../Components/DealRoomButton';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import { AudioArtifactCard } from './AudioArtifactCard';
import { DocumentArtifactCard } from './DocumentArtifactCard';
import { FileArtifactCard } from './FileArtifactCard';
import { ImageArtifactCard } from './ImageArtifactCard';
import { LinkArtifactCard } from './LinkArtifactCard';
import { MeetingflowArtifactCard } from './MeetingflowArtifactCard';
import { VideoArtifactCard } from './VideoArtifactCard';

type DealRoomArtifactsProps = {
  className?: string;
  artifactContainerClassName?: string;
  organizationSlug: string;
  dealRoomId: number;
  showAdd?: boolean;
  activity?: DetailedDealRoom['activity'];
  artifacts?: DetailedDealRoom['artifacts'];
  refreshDealRoom: () => Promise<unknown>;
  onClick: (id: DealRoomArtifact['id']) => unknown;
  onAdd: (item: DealRoomArtifact) => Promise<unknown>;
  onUpdate: (idx: number | string, props: Partial<DealRoomArtifact>) => void;
  onDelete: (id: DealRoomArtifact['id']) => Promise<unknown>;
  useTableView?: boolean;
};

export const DealRoomArtifacts = ({
  className,
  artifactContainerClassName,
  organizationSlug,
  dealRoomId,
  showAdd,
  activity,
  artifacts,
  onClick,
  refreshDealRoom,
  onAdd,
  onUpdate,
  onDelete,
  useTableView,
}: DealRoomArtifactsProps) => {
  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useAddDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });

  const generateStyles = useCallback((theme: ITheme) => {
    const { palette } = theme;
    return mergeStyleSets({
      containerStyle: {
        position: 'relative',
        display: 'flex',
        width: 'calc(100% - 2px)',
        height: '100%',
      },

      noArtifactsStyle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        minHeight: '10rem',
      },

      artifactContainerStyle: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '1rem',
      },
      '.action-buttons-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    });
  }, []);

  const theme = useTheme();
  const styles = useMemo(() => generateStyles(theme), [theme, generateStyles]);

  return (
    <>
      <div className="action-buttons-container">
        <DealRoomAsyncCommandBarButton
          iconProps={{ iconName: 'Add' }}
          text="Add artifact"
          buttonStyleType="COMMAND_BAR"
          onClick={async () => {
            const result = await showAddDealRoomArtifactDialog().promise;
            if (result) {
              await onAdd(result);
            }
          }}
        />
      </div>

      <div
        id="dealroom-artifacts"
        className={classNames(styles.containerStyle, className)}
      >
        <DropTarget
          overlayText="Add artifact to deal room"
          onDrop={async (file) => {
            const result = await showAddDealRoomArtifactDialog(file).promise;
            if (result) {
              await onAdd(result);
            }
          }}
        >
          {artifacts?.length ? (
            useTableView ? (
              <DealRoomArtifactsTable
                artifacts={artifacts}
                onClick={onClick}
                onDelete={onDelete}
                dealRoomId={dealRoomId}
                organizationSlug={organizationSlug}
                refreshDealRoom={refreshDealRoom}
              />
            ) : (
              <div
                className={classNames(
                  'artifacts',
                  styles.artifactContainerStyle,
                  artifactContainerClassName,
                )}
              >
                {artifacts.map((a) => {
                  const viewCount =
                    DeduplicateArray(
                      activity
                        ?.filter(
                          (activity) =>
                            activity.type === 'VIEW_ARTIFACT' &&
                            activity.additionalDetails.artifactId === a.id,
                        )
                        .map((a) => a.userId) ?? [],
                    ).length ?? 0;
                  switch (a.type) {
                    case 'MEETINGFLOW':
                      return (
                        <MeetingflowArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refreshDealRoom}
                          onClick={onClick}
                          onDelete={onDelete}
                        />
                      );

                    case 'AUDIO':
                      return (
                        <AudioArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refreshDealRoom}
                          onClick={onClick}
                          onDelete={onDelete}
                        />
                      );

                    case 'DOCUMENT':
                      return (
                        <DocumentArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refreshDealRoom}
                          onClick={onClick}
                          onDelete={onDelete}
                        />
                      );

                    case 'FILE':
                      return (
                        <FileArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refreshDealRoom}
                          onClick={onClick}
                          onDelete={onDelete}
                        />
                      );

                    case 'IMAGE':
                      return (
                        <ImageArtifactCard
                          key={a.id}
                          {...a}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refreshDealRoom}
                          onClick={onClick}
                          onDelete={onDelete}
                        />
                      );

                    case 'LINK':
                      return (
                        <LinkArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refreshDealRoom}
                          onClick={onClick}
                          onDelete={onDelete}
                        />
                      );

                    case 'VIDEO':
                      return (
                        <VideoArtifactCard
                          key={a.id}
                          {...a}
                          viewCount={viewCount}
                          orgSlug={organizationSlug}
                          dealRoomId={dealRoomId}
                          refreshDealRoom={refreshDealRoom}
                          onClick={onClick}
                          onDelete={onDelete}
                        />
                      );
                  }
                })}
              </div>
            )
          ) : (
            <div className={styles.noArtifactsStyle}>
              <Text>No artifacts</Text>
            </div>
          )}
        </DropTarget>
      </div>
      {addDealRoomArtifactDialog}
    </>
  );
};
