import {
  DirectionalHint,
  IFacepilePersona,
  IPersonaProps,
  IPersonaStyles,
  Persona,
  PersonaPresence,
  PersonaSize,
  TooltipHost,
} from '@fluentui/react';
import { Contact, User } from '@meetingflow/common/Api/data-contracts';
import { CompanyCard, ContactCard } from './MeetingPlans/MeetingPlanAttendees';

interface PersonaWithTooltipProps {
  persona: IFacepilePersona;
  styles?: Partial<IPersonaStyles>;
  organizationSlug: string;
  onClick?: ((c: Contact['id']) => void) | undefined;
  onPersonaCoinClick?: () => void;
  attendeeCount?: number;
  isCompany?: boolean;
  tooltipStyles?: React.CSSProperties;
}

export const PersonaWithTooltip = ({
  persona,
  presence = PersonaPresence.none,
  presenceTitle,
  styles,
  attendeeCount,
  tooltipStyles,
  organizationSlug,
  onClick,
  onPersonaCoinClick,
  isCompany,
  size,
  imageUrl,
}: PersonaWithTooltipProps &
  Pick<IPersonaProps, 'imageUrl' | 'presence' | 'presenceTitle' | 'size'>) => {
  return (
    <TooltipHost
      styles={{
        root: {
          display: 'inline-block',
        },
      }}
      content={
        isCompany && persona?.data?.company ? (
          <CompanyCard
            inTooltip
            organizationSlug={organizationSlug}
            company={persona?.data?.company}
          />
        ) : persona?.data?.contact ? (
          <ContactCard
            key={persona.data.contact.email}
            inTooltip
            organizationSlug={organizationSlug}
            contact={persona.data.contact}
            onContactClick={() => onClick?.(persona.data.contact)}
            domain={persona.data.contact.emailDomain}
            hideBottomBorder
          />
        ) : (
          <ContactCard
            key={persona.personaName}
            inTooltip
            organizationSlug={organizationSlug}
            domain={''}
            hideBottomBorder
            contact={
              {
                id: 0,
                email: persona.data?.email || `unknown.user@meetingflow.com`,
                name: persona.personaName || `Unknown User`,
                linkedInUrl: '',
              } as Contact | User
            }
          />
        )
      }
      id={persona?.personaName}
      color="#ffffff"
      directionalHint={DirectionalHint.topCenter}
    >
      <Persona
        size={size ?? PersonaSize.size32}
        onClick={() =>
          persona?.data?.contact && onClick?.(persona.data.contact)
        }
        imageUrl={imageUrl || persona?.imageUrl}
        text={`${persona?.personaName}${
          attendeeCount
            ? ` • ${attendeeCount} attendee${attendeeCount > 1 ? 's' : ''}`
            : ''
        }`}
        hidePersonaDetails
        coinProps={{
          text: persona?.personaName || '',
          title: persona?.personaName || '',
          imageUrl: imageUrl || persona?.imageUrl,
          onClick: onPersonaCoinClick,
          styles: {
            image: {
              borderRadius: isCompany ? '.15rem' : undefined,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              img: {
                width: '100%',
                height: 'auto !important',
              },
            },
            initials: {
              borderRadius: isCompany ? '.15rem' : undefined,
              color: 'white !important',
            },
          },
        }}
        presenceTitle={presenceTitle}
        presence={presence}
        {...persona}
        styles={
          styles
            ? styles
            : {
                root: {
                  width: size === PersonaSize.size24 ? '24px' : '32px',
                  display: 'inline-block',
                  color: 'white !important',
                  cursor: 'pointer',
                },
              }
        }
      />
    </TooltipHost>
  );
};
