import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { UserApiClient } from '../Services/NetworkCommon';
import { useLocalStorageState } from './useLocalStorageState';
import { useUserProfile } from './useProfile';
import { ThemePreference } from '@meetingflow/common/Api/data-contracts';

type ThemeMode = 'light' | 'dark';

export const useLightOrDarkMode = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { user: userProfile } = useUserProfile();

  const [currentTheme, setCurrentTheme] = useLocalStorageState<ThemeMode>(
    'lightOrDarkMode',
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light',
  );
  const themePreference = userProfile?.preferenceTheme ?? 'SYSTEM';

  useEffect(() => {
    if (userProfile?.preferenceTheme === 'DARK' && currentTheme !== 'dark') {
      console.info(
        `Current theme (${currentTheme}) doesn't match user preference (dark), reloading to apply`,
      );
      setCurrentTheme('dark');
      window.location.reload();
    } else if (
      userProfile?.preferenceTheme === 'LIGHT' &&
      currentTheme !== 'light'
    ) {
      console.info(
        `Current theme (${currentTheme}) doesn't match user preference (light), reloading to apply`,
      );
      setCurrentTheme('light');
      window.location.reload();
    } else if (userProfile?.preferenceTheme === 'SYSTEM') {
      const mode =
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light';
      if (currentTheme !== mode) {
        console.info(
          `Current theme (${currentTheme}) doesn't match user preference (system, ${mode}), reloading to apply`,
        );
        setCurrentTheme(mode);
        window.location.reload();
      }
    }

    if (userProfile?.preferenceTheme === 'SYSTEM') {
      const onMediaMatch = (e: MediaQueryListEvent) => {
        if (
          (e.matches && currentTheme !== 'dark') ||
          (!e.matches && currentTheme !== 'light')
        ) {
          setCurrentTheme(e.matches ? 'dark' : 'light');
          console.info(
            `OS theme changed, reloading window to reflect theme change.`,
          );
          window.location.reload();
        }
      };

      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', onMediaMatch);

      return () => {
        window
          .matchMedia('(prefers-color-scheme: dark)')
          .removeEventListener('change', onMediaMatch);
      };
    }
  }, [currentTheme, setCurrentTheme, userProfile?.preferenceTheme]);

  const setThemePreference = async (mode: ThemePreference) => {
    if (mode === themePreference) {
      return;
    }

    const token = await getAccessTokenSilently();
    const result = await UserApiClient.patchMe(
      {
        preferenceTheme: mode,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    if (result.status === 200) {
      let newTheme: ThemeMode = 'light';
      switch (mode) {
        case 'DARK': {
          newTheme = 'dark';
          break;
        }
        case 'LIGHT': {
          newTheme = 'light';
          break;
        }
        case 'SYSTEM': {
          newTheme =
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
              ? 'dark'
              : 'light';
          break;
        }
      }

      if (newTheme !== currentTheme) {
        setCurrentTheme(newTheme);
        window.location.reload();
      }
    }
  };

  return {
    currentTheme,
    themePreference,
    setThemePreference,
    isDark: currentTheme === 'dark',
  } as const;
};
