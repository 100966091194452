import { useAuth0 } from '@auth0/auth0-react';
import {
  Checkbox,
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyles,
  NeutralColors,
  Persona,
  PersonaSize,
  Spinner,
  Text,
  useTheme,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import {
  Contact,
  PatchTaskPayload,
  Resource,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { YjsEditor } from '@slate-yjs/core';
import syncedStore, { Y } from '@syncedstore/core';
import { useSyncedStore } from '@syncedstore/react';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import saveAs from 'file-saver';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { Descendant } from 'slate';
import { Editable, Slate } from 'slate-react';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import { hasContent } from '../../../Helpers/SlateHelpers';
import { externalize } from '../../../Helpers/URLHelpers';
import { sendWSMessage } from '../../../Helpers/WSHelpers';
import { useLinkDialog } from '../../../Hooks/Modals/useLinkDialog';
import { useCollabProvider } from '../../../Hooks/useCollabProvider';
import { useCollapsibleHeadings } from '../../../Hooks/useCollapsibleHeadings';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MeetingPlanTasksQuery } from '../../../QueryNames';
import { TasksApiClient } from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { Chip } from '../../Chip';
import { getYjsEditor } from '../../Collab/Helpers/EditorHelpers';
import { RenderElement } from '../../Collab/RenderElement';
import { RenderLeaf } from '../../Collab/RenderLeaf';
import {
  MeetingflowCard,
  MeetingFlowCardMeetingflow,
} from '../MeetingflowCard';

export interface PlanReaderListProps {
  meetingPlans: MeetingFlowCardMeetingflow[];
  refetchMeetingflows: () => Promise<unknown>;
  organizationSlug: string;
  maxCount?: number;
  internalDomains?: string[];
  containerPadding?: string;
  onContactClick?: (c: Contact['id']) => void;
  loading?: boolean;
  color: string;
  stickyTopPosition?: string;
}

export const PlanReaderList = ({
  meetingPlans,
  refetchMeetingflows,
  organizationSlug,
  maxCount,
  internalDomains,
  containerPadding = '0 .5rem .5rem .5rem',
  onContactClick,
  loading = false,
  color,
  stickyTopPosition = '0px',
}: PlanReaderListProps) => {
  const plans = maxCount ? meetingPlans?.slice(0, maxCount) : meetingPlans;

  const meetingflowsListClass = mergeStyles({
    '@container (width >= 40rem)': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      columnGap: '.5rem',
      rowGap: '.5rem',

      '> div': {
        flexBasis: 'calc(50% - .25rem)',
        maxWidth: 'calc(50% - 0.25rem)',
      },

      '> div:only-child': {
        flexBasis: '100%',
        maxWidth: '100%',
      },
    },

    '@container (width < 40rem)': {
      display: 'block',

      '> div': {
        marginBottom: '.5rem',
        width: '100%',
      },
    },
  });

  return (
    <div
      className={meetingflowsListClass}
      style={{ padding: containerPadding }}
    >
      {loading ? <Spinner /> : null}
      {!loading && !plans?.length ? (
        <p>There are no Meetingflows to show.</p>
      ) : null}
      {plans?.map((p) => (
        <PlanReader
          key={p.id}
          meetingPlan={p}
          refetchMeetingflow={refetchMeetingflows}
          internalDomains={internalDomains}
          organizationSlug={organizationSlug}
          onContactClick={onContactClick}
          color={color}
          stickyTopPosition={stickyTopPosition}
        />
      ))}
    </div>
  );
};

interface PlanReaderProps {
  meetingPlan: MeetingFlowCardMeetingflow;
  refetchMeetingflow: () => Promise<unknown>;
  internalDomains?: string[];
  organizationSlug: string;
  showInternalDomains?: boolean;
  onContactClick?: (c: Contact['id']) => void;
  color: string;
  stickyTopPosition?: string;
}

export const PlanReader = ({
  meetingPlan,
  refetchMeetingflow,
  internalDomains,
  organizationSlug,
  showInternalDomains = true,
  onContactClick,
  color,
  stickyTopPosition = '0px',
}: PlanReaderProps) => {
  const appInsights = useAppInsightsContext();
  const { user, getAccessTokenSilently } = useAuth0();
  const { name, email, picture } = user!;
  const { isDark } = useLightOrDarkMode();
  const theme = useTheme();
  const [cardExpanded, { toggle: toggleCardExpanded }] = useBoolean(false);
  const [attendeesExpanded, { toggle: toggleAttendeesExpanded }] =
    useBoolean(false);
  const [resourcesExpanded, { toggle: toggleResourcesExpanded }] =
    useBoolean(false);
  const [objectivesExpanded, { toggle: toggleObjectivesExpanded }] =
    useBoolean(false);
  const [notesExpanded, { toggle: toggleNotesExpanded }] = useBoolean(true);
  const [actionItemsExpanded, { toggle: toggleActionItemsExpanded }] =
    useBoolean(true);

  const [objectives, setObjectives] = useState<Descendant[]>([]);
  const [notes, setNotes] = useState<Descendant[]>([]);

  const navigate = useNavigate();

  const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
    useLinkDialog();

  const client = useQueryClient();

  const {
    data: tasksData,
    isFetched: tasksFetched,
    refetch: refetchTasks,
  } = useQuery(
    MeetingPlanTasksQuery(organizationSlug, meetingPlan.id),
    async () => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.listTasks(
        {
          organizationSlug,
          meetingPlanId: meetingPlan.id,
          sortBy: 'createdAt',
          sortOrder: 'asc',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: cardExpanded,
    },
  );

  useEffect(() => {
    if (cardExpanded) {
      refetchTasks();
    }
  }, [cardExpanded, refetchTasks]);

  const { mutate: patchTask } = useMutation({
    mutationFn: async (update: { id: Task['id'] } & PatchTaskPayload) => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.patchTask(
        organizationSlug,
        meetingPlan.id,
        update.id,
        {
          text: update.text,
          completed: update.completed,
          dueDate: update.dueDate,
          assigneeIdOrEmail: update.assigneeIdOrEmail,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    onMutate: async (update) => {
      await client.cancelQueries({
        queryKey: MeetingPlanTasksQuery(organizationSlug, meetingPlan.id),
      });

      const previous = client.getQueryData(
        MeetingPlanTasksQuery(organizationSlug, meetingPlan.id),
      ) as AxiosResponse<Task[]>;

      if (previous.data) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlan.id),
          {
            ...previous,
            data: previous.data?.map((t) =>
              t.id !== update.id
                ? t
                : ({
                    ...t,
                    text: update.text ?? t.text,
                    completed: update.completed ?? t.completed,
                    dueDate: update.dueDate ?? t.dueDate,
                  } satisfies Task),
            ),
          },
        );
      }

      return {
        previous,
      };
    },
    onError: (err, update, ctx) => {
      toast.error(`Something went wrong updating Task`);

      appInsights.trackException({
        exception: err instanceof Error ? err : new Error(`${err}`),
        properties: {
          organizationSlug,
          status: isAxiosErrorResponse(err) ? err.response?.status : undefined,
          statusText: isAxiosErrorResponse(err)
            ? err.response?.statusText
            : undefined,
        },
      });

      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlan.id),
          ctx.previous,
        );
      }
    },
    onSuccess: (response, update, ctx) => {
      sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_ACTION_ITEMS);
      appInsights.trackEvent({
        name: 'TOGGLE_ACTION_ITEM_COMPLETED',
        properties: {
          organizationSlug,
          meetingPlanId: meetingPlan.id,
          id: update.id,
          completed: update.completed,
          context: 'RELATED_PLAN_SIDEBAR',
        },
      });
      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, meetingPlan.id),
          {
            ...ctx.previous,
            data: ctx.previous.data?.map((t) =>
              t.id !== update.id ? t : response.data,
            ),
          },
        );
      }
    },
    onSettled: () => {
      client.invalidateQueries(
        MeetingPlanTasksQuery(organizationSlug, meetingPlan.id),
      );
    },
  });

  const peopleByDomain = useMemo(
    () =>
      meetingPlan?.attendees
        ? GroupBy(meetingPlan?.attendees, (a) => a.emailDomain)
        : {},
    [meetingPlan?.attendees],
  );

  const companies = useMemo(
    () =>
      !showInternalDomains
        ? meetingPlan?.companies.filter((c) => !c.isInternal)
        : meetingPlan?.companies,
    [meetingPlan?.companies, showInternalDomains],
  );

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, meetingPlan.id]);

  const objectivesYArray = useMemo(() => {
    return ydoc.get('objectives', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  const notesYArray = useMemo(() => {
    return ydoc.get('notes', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  const syncStore = useMemo(() => {
    return syncedStore(
      {
        resources: [] as Resource[],
      },
      ydoc,
    );
  }, [ydoc]);

  const store = useSyncedStore(syncStore);

  const onWSMessage = useCallback(
    (messageType: number) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_ACTION_ITEMS: {
          refetchTasks();
          return true;
        }
        case WS_MESSAGE.HEARTBEAT:
        case WS_MESSAGE.RECREATE:
        case WS_MESSAGE.REFRESH_PLAN:
        case WS_MESSAGE.SAVE: {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    [refetchTasks],
  );

  const { provider, isConnected, isSynced } = useCollabProvider({
    providerName: 'RELATED MEETINGPLAN',
    documentName: `MeetingPlan__${organizationSlug}__${meetingPlan.id}`,
    ydoc,
    connect: false,
    color,
    email: user!.email!,
    name: user!.name,
    picture: user!.picture,
    onMessage: onWSMessage,
  });

  const objectivesEditor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      objectivesYArray,
      provider,
      {
        cursorStateField: 'objectivesCursorState',
        cursorDataField: 'objectivesCursorData',
        data: {
          alphaColor: isDark
            ? color.slice(0, -2) + '0.5)'
            : color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
      meetingPlan.id,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectivesYArray, provider]);

  const notesEditor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      notesYArray,
      provider,
      {
        cursorStateField: 'notesCursorState',
        cursorDataField: 'notesCursorData',
        data: {
          alphaColor: isDark
            ? color.slice(0, -2) + '0.5)'
            : color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
      meetingPlan.id,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesYArray, provider]);

  useEffect(() => {
    if (!provider) {
      return;
    }

    if (cardExpanded && (!provider.wsconnected || !provider.wsconnecting)) {
      try {
        provider.connect();
      } catch {}
    } else if (
      !cardExpanded &&
      (provider.wsconnected || provider.wsconnecting)
    ) {
      try {
        provider.disconnect();
      } catch {}
    }
  }, [provider, cardExpanded]);

  useEffect(() => {
    if (!objectivesEditor || !notesEditor) {
      return;
    }

    if (
      isSynced &&
      (!YjsEditor.connected(objectivesEditor) ||
        !YjsEditor.connected(notesEditor))
    ) {
      try {
        YjsEditor.connect(objectivesEditor);
      } catch {}
      try {
        YjsEditor.connect(notesEditor);
      } catch {}
    } else if (
      YjsEditor.connected(objectivesEditor) ||
      YjsEditor.connected(notesEditor)
    ) {
      try {
        YjsEditor.disconnect(objectivesEditor);
      } catch {}
      try {
        YjsEditor.disconnect(notesEditor);
      } catch {}
    }
  }, [isSynced, notesEditor, objectivesEditor]);

  const hasObjectives =
    meetingPlan && objectivesEditor && hasContent(objectivesEditor.children);
  const hasNotes =
    meetingPlan && notesEditor && hasContent(notesEditor.children);
  const hasResources = store?.resources?.length > 0;
  const hasActionItems = tasksData?.data && tasksData.data.length > 0;

  const {
    collapsedHeadings: collapsedObjectivesHeadings,
    setCollapsedHeadings: setCollapsedObjectivesHeadings,
    uncollapseHeading: uncollapseObjectivesHeadings,
    hiddenElements: hiddenObjectivesElements,
  } = useCollapsibleHeadings({
    editor: objectivesEditor,
  });

  const renderObjectivesElement = useMemo(
    () =>
      RenderElement({
        editor: objectivesEditor,
        collapsedHeadings: collapsedObjectivesHeadings,
        hiddenElements: hiddenObjectivesElements,
        setCollapsedHeadings: setCollapsedObjectivesHeadings,
        uncollapseHeading: uncollapseObjectivesHeadings,
      }),
    [
      collapsedObjectivesHeadings,
      hiddenObjectivesElements,
      objectivesEditor,
      setCollapsedObjectivesHeadings,
      uncollapseObjectivesHeadings,
    ],
  );

  const {
    collapsedHeadings: collapsedNotesHeadings,
    setCollapsedHeadings: setCollapsedNotesHeadings,
    uncollapseHeading: uncollapseNotesHeadings,
    hiddenElements: hiddenNotesElements,
  } = useCollapsibleHeadings({
    editor: notesEditor,
  });

  const renderNotesElement = useMemo(
    () =>
      RenderElement({
        editor: notesEditor,
        collapsedHeadings: collapsedNotesHeadings,
        hiddenElements: hiddenNotesElements,
        setCollapsedHeadings: setCollapsedNotesHeadings,
        uncollapseHeading: uncollapseNotesHeadings,
      }),
    [
      collapsedNotesHeadings,
      hiddenNotesElements,
      notesEditor,
      setCollapsedNotesHeadings,
      uncollapseNotesHeadings,
    ],
  );

  const renderLeaf = useCallback(RenderLeaf, []);

  const planCardClass = mergeStyles({
    borderRadius: '.25rem',
    borderBottom: `1px solid ${
      isDark ? NeutralColors.gray170 : NeutralColors.gray20
    }`,
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    padding: '0 .25rem',
    paddingBottom: cardExpanded ? '.5rem' : undefined,
    boxSizing: 'border-box',

    '.plan-title': {
      display: 'flex',
      flexDirection: 'row',
      fontSize: FontSizes.medium,
      fontWeight: FontWeights.semibold,
      lineHeight: '1rem',

      '> .plan-title-text': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        flexShrink: 1,
      },

      '> .plan-link': {},
    },

    '.plan-details': {
      display: 'block',
      fontSize: FontSizes.xSmall,
      lineHeight: '.75rem',
      color: NeutralColors.gray130,
      position: 'sticky',
      top: stickyTopPosition,
      zIndex: 6,
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
        : MEETINGFLOW_COLORS.white,
      paddingBottom: '.25rem',
      paddingTop: '.5rem',
      paddingLeft: '1rem',
      paddingRight: '.25rem',

      '> .chevron': {
        float: 'left',
        fontSize: FontSizes.small,
        position: 'relative',
        top: '.25rem',
        marginLeft: '-1rem',
        color: NeutralColors.gray100,
      },
    },

    '.plan-attendees-container': {
      paddingTop: 0,
      borderRadius: '.25rem',
      marginBottom: 0,
      maxHeight: '0',
      overflow: 'hidden',
      transition: '.3s max-height ease-in-out',
      paddingLeft: '.25rem',
      paddingRight: '.25rem',

      '.plan-attendees-company': {},

      '.plan-attendees-company-name': {
        fontWeight: FontWeights.semibold,
        fontSize: FontSizes.small,
        lineHeight: '1rem',
        paddingBottom: '.25rem',
        marginLeft: '1.25rem',
        position: 'relative',

        img: {
          height: '1rem',
          width: '1rem',
          position: 'absolute',
          left: '-1.25rem',
          top: 0,
          objectFit: 'contain',
          overflow: 'hidden',
        },
      },

      '.plan-attendees-attendee-group': {
        marginBottom: '.5rem',
        marginLeft: '.25rem',
        fontSize: FontSizes.small,
      },
    },

    '.plan-attendees-container.expanded': {
      marginLeft: '.75rem',
      maxHeight: '5000rem',
      marginBottom: '.5rem',
      paddingTop: '.25rem',
    },

    '.plan-editor-container': {
      marginBottom: '0',
      paddingTop: '0',
      height: 'auto',
      maxHeight: '0',
      overflow: 'hidden',
      transition: '.3s max-height ease-in-out',
      paddingLeft: '.25rem',
      paddingRight: '.25rem',

      ':last-child': {
        marginBottom: '0',
      },
    },

    '.plan-editor-container-resources': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      alignContent: 'space-around',
      gap: '0.1rem 0.25rem',

      '.plan-editor-resource-item': {
        columnGap: '0.5rem',
        height: '1.25rem',
        fontSize: '0.7rem',
        paddingLeft: '0.7rem',
        button: {
          height: '0.75rem',
          width: '0.75rem',
        },
      },
    },

    '.plan-editor-container.expanded': {
      marginLeft: '.75rem',
      maxHeight: cardExpanded ? '5000rem' : 0,
      marginBottom: cardExpanded ? '.5rem' : 0,
      paddingTop: cardExpanded ? '.25rem' : 0,
    },

    '.plan-section-header': {
      display: 'block',
      position: 'sticky',
      cursor: 'pointer',
      top: `calc(${stickyTopPosition} + 3.5rem)`,
      padding: '.25rem 0',
      zIndex: 5,
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
        : 'white',
      margin: '0',
      fontSize: FontSizes.mini,
      fontWeight: FontWeights.semibold,
      color: NeutralColors.gray120,
      lineHeight: '.65rem',
      height: '.75rem',
      transition: '.3s ease-in-out all',
      ':first-of-type': {
        marginTop: '0',
      },

      '.chevron': {
        marginLeft: '1px',
        marginRight: '.3rem',
        fontSize: FontSizes.mini,
        position: 'relative',
        top: '1px',
        color: isDark ? NeutralColors.gray140 : NeutralColors.gray80,
      },

      ':hover': {
        borderRadius: '.25rem',
        backgroundColor: isDark
          ? NeutralColors.gray200
          : MEETINGFLOW_COLORS.purpleGrey,
        textDecoration: 'underline',
      },
    },
  });

  const fadeInClass = mergeStyles({
    position: 'relative',
    animationName: 'fadeInAnimation',
    animationDuration: '1s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    width: 'auto',
    transition: '.3s ease-in-out all',
  });

  const taskClass = useMemo(
    () =>
      mergeStyles({
        display: 'grid',
        width: '100%',
        minHeight: '1.5rem',
        alignItems: 'top',
        padding: '0.1rem',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1rem 1fr 1fr',
        gridTemplateAreas: `
    'checkbox text text'
    'checkbox assignee duedate '
    `,
        gridColumnGap: '0.3rem',
        gridRowGap: '0.1rem',
        '.completed-checkbox': {
          gridArea: 'checkbox',
          justifyContent: 'center',
        },
        '.complete': {
          textDecoration: 'line-through',
        },
        '.task-text': {
          gridArea: 'text',
          fontSize: `${FontSizes.small} !important`,
        },
        '.task-assignee': {
          gridArea: 'assignee',
          marginRight: '0.2rem',
        },
        '.task-assignee-text': {
          fontWeight: 'bold',
          fontSize: `${FontSizes.small} !important`,
        },
        '.assignee-me': {
          color: MEETINGFLOW_COLORS.teal,
        },
        '.task-duedate': {
          gridArea: 'duedate',
          fontSize: `${FontSizes.small} !important`,
        },
        '.no-due-date': {
          fontStyle: 'italic',
        },
      }),
    [],
  );

  const editableClass = mergeStyles({
    height: 'auto',
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'column',
    overflowWrap: 'break-word',
    borderRadius: '.25rem',
    overflow: 'auto',
    position: 'relative',
    animationName: 'fadeInAnimation',
    animationDuration: '.3s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    padding: '.5rem .5rem 2rem 1.25rem ',

    color: isDark ? NeutralColors.gray60 : '#555',

    '[data-slate-placeholder="true"]': {
      width: 'auto !important',
    },

    '[data-slate-node="element"][href]': {
      color: isDark ? theme.palette.greenLight : theme.palette.themePrimary,
    },

    '*[data-slate-node="element"]:only-child': {
      marginTop: 0,
    },

    '*[data-slate-node="element"]:last-child': {
      marginBottom: '.25rem',
    },

    '[data-meetingflow="header-collapsible"]': {
      margin: '0 0 .5rem -.75rem !important',
    },

    '[data-meetingflow="header-collapse-arrow"]': {
      fontSize: FontSizes.small,
      position: 'absolute',
      left: '.25rem',
      cursor: 'pointer',
    },

    h2: {
      fontFamily: 'Helvetica',
      fontSize: FontSizes.xLarge,
      margin: '0 0 0 .65rem',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
    },

    h3: {
      fontSize: FontSizes.large,
      margin: '0 0 0 .65rem',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
    },

    h4: {
      fontSize: FontSizes.mediumPlus,
      margin: '0 0 0 .65rem',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    h5: {
      fontSize: FontSizes.medium,
      margin: '0 0 0 .65rem',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    h6: {
      fontSize: FontSizes.small,
      margin: '0 0 0 .65rem',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    strong: {
      fontWeight: FontWeights.semibold,
      color: isDark ? 'white' : NeutralColors.black,
    },

    blockquote: {
      backgroundColor: isDark ? NeutralColors.gray200 : NeutralColors.gray30,
      marginLeft: '.5rem',
      marginRight: '.5rem',
      marginTop: 0,
      marginBottom: '.5rem',
      padding: '.5rem',
      position: 'relative',
      borderRadius: '.5rem',
      fontFamily: 'Georgia, serif',
      fontStyle: 'italic',
      'p:only-child': {
        marginBottom: 0,
      },
    },

    'span, p, ol, ul': {
      fontSize: '12px',
      lineHeight: '1rem',
      margin: '0 0 .5rem 0',
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray200,
    },

    'ol, ul': {
      padding: `0 0 0 1rem`,
    },

    'ol ol, ul ul': {
      padding: `0 0 0 1rem`,
      marginBottom: 0,
    },

    li: {
      marginLeft: 0,
    },

    img: {
      margin: '0',
    },

    '> div': {
      margin: '0 0 .5rem 0 !important',
    },

    '.checklist': {
      marginLeft: '0 !important',
    },

    'div.empty-text': {
      display: 'none',
    },

    br: {
      margin: '0 0 .5rem 0 !important',
    },

    a: {
      display: 'inline',
    },

    'span[data-slate-length="0"]': {
      height: `0 !important`,
    },
  });

  const onMeetingflowClick = useCallback(() => {
    navigate(`/organization/${organizationSlug}/plan/${meetingPlan.id}`);
  }, [meetingPlan.id, navigate, organizationSlug]);

  const planAttendees = useMemo(
    () =>
      companies?.map((c) => {
        let attendees =
          c.domains
            ?.map((domain) => domain.domain)
            ?.flatMap(
              (domain) =>
                Object.keys(peopleByDomain)
                  .filter((key) => key === domain || key.endsWith(domain))
                  .flatMap((key) => peopleByDomain[key]) ?? [],
            ) ?? [];

        if (!showInternalDomains && internalDomains) {
          attendees = attendees.filter(
            (a) => !internalDomains.includes(a.emailDomain),
          );
        }

        return (
          <div key={c.id} className="plan-attendees-company">
            <Text className="plan-attendees-company-name">
              {c.logo ? (
                <img alt={c.name!} src={c.logo} />
              ) : (
                <Persona
                  text={c.name!}
                  size={PersonaSize.size24}
                  styles={{
                    root: {
                      display: 'inline-block',
                      height: '16px',
                      width: '16px',
                      minWidth: '16px',
                      marginLeft: '-1.25rem',
                      marginRight: '.25rem',

                      '.ms-Persona-primaryText': {
                        display: 'none',
                      },
                    },
                  }}
                  coinProps={{
                    styles: {
                      image: {
                        height: '16px',
                        width: '16px',
                      },
                      imageArea: {
                        height: '16px',
                        width: '16px',
                      },
                      initials: {
                        height: '16px',
                        width: '16px',
                        lineHeight: '16px',
                        borderRadius: '.15rem',
                      },
                    },
                  }}
                />
              )}
              {c.name?.substring(0, 25)}:
            </Text>
            <span className="plan-attendees-attendee-group">
              {attendees.map((a, idx) => (
                <span key={a.id}>
                  <Link
                    onClick={
                      onContactClick
                        ? () => {
                            onContactClick(a.id);
                            appInsights.trackEvent({
                              name: 'RELATED_PLAN_CLICKED_CONTACT',
                              properties: {
                                sub: user?.sub,
                                name: user?.name,
                                email: user?.email,
                                meetingPlanId: meetingPlan.id,
                                contactId: a.id,
                              },
                            });
                          }
                        : undefined
                    }
                    key={a.id}
                  >
                    {a.name || a.email.split('@')[0]}
                  </Link>
                  {idx === attendees.length - 1 ? '' : ', '}
                </span>
              ))}
            </span>
          </div>
        );
      }),
    [
      appInsights,
      companies,
      internalDomains,
      meetingPlan.id,
      onContactClick,
      peopleByDomain,
      showInternalDomains,
      user?.email,
      user?.name,
      user?.sub,
    ],
  );

  return (
    <div className={planCardClass} key={meetingPlan.id}>
      <div
        className="plan-details"
        onClick={toggleCardExpanded}
        style={{ cursor: 'pointer' }}
      >
        <FontIcon
          iconName={cardExpanded ? 'ChevronDownMed' : 'ChevronRightMed'}
          className="chevron"
        />
        <div style={{ position: 'relative', top: '-.2rem' }}>
          <MeetingflowCard
            organizationSlug={organizationSlug}
            meetingflowId={meetingPlan.id}
            meetingflowObj={meetingPlan}
            refetchMeetingflow={refetchMeetingflow}
            onContactClick={onContactClick}
            onClick={onMeetingflowClick}
            hideShowConferenceJoinButton
            showCompanies
            showCallRecordingButton
          />
        </div>
      </div>
      <div
        className={fadeInClass}
        style={{
          display: cardExpanded ? 'block' : 'none',
        }}
      >
        {companies?.length ? (
          <>
            <Text
              block
              className="plan-section-header"
              onClick={() => {
                toggleAttendeesExpanded();
                appInsights.trackEvent({
                  name: attendeesExpanded
                    ? 'RELATED_PLAN_COLLAPSED_ABOUT'
                    : 'RELATED_PLAN_EXPANDED_ABOUT',
                  properties: {
                    sub: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    meetingPlanId: meetingPlan.id,
                  },
                });
              }}
            >
              <FontIcon
                iconName={
                  attendeesExpanded ? 'ChevronDownMed' : 'ChevronRightMed'
                }
                className="chevron"
              />
              About
            </Text>
            <div
              className={`plan-attendees-container ${
                attendeesExpanded && 'expanded'
              }`}
            >
              {planAttendees}
            </div>
          </>
        ) : null}

        <>
          <Text
            block
            style={{ display: hasResources ? undefined : 'none' }}
            className={`plan-section-header ${resourcesExpanded && 'expanded'}`}
            onClick={() => {
              toggleResourcesExpanded();
              appInsights.trackEvent({
                name: resourcesExpanded
                  ? 'RELATED_PLAN_COLLAPSED_RESOURCES'
                  : 'RELATED_PLAN_EXPANDED_RESOURCES',
                properties: {
                  sub: user?.sub,
                  name: user?.name,
                  email: user?.email,
                  meetingPlanId: meetingPlan.id,
                },
              });
            }}
          >
            <FontIcon
              iconName={
                resourcesExpanded ? 'ChevronDownMed' : 'ChevronRightMed'
              }
              className="chevron"
            />
            Resources
          </Text>

          <div
            style={{
              display: hasResources ? 'flex' : 'none',
            }}
            className={`plan-editor-container plan-editor-container-resources ${
              resourcesExpanded && 'expanded'
            }`}
          >
            {isConnected && isSynced ? (
              store.resources.map((resource, idx) => {
                return (
                  <Chip
                    key={resource.id || idx}
                    className="plan-editor-resource-item"
                    type={resource.type}
                    text={resource.title}
                    mimeType={
                      resource.type === 'file' ? resource.mimeType : undefined
                    }
                    onClick={() => {
                      if (resource.type === 'file') {
                        getAccessTokenSilently().then(async (token) => {
                          const headers = new Headers();
                          headers.append('Authorization', `Bearer ${token}`);
                          if (resource.mimeType) {
                            headers.append('Accept', resource.mimeType);
                          }
                          const fetchPromise = fetch(resource.url, {
                            method: 'GET',
                            headers,
                          });

                          toast.promise(fetchPromise, {
                            loading: 'Downloading file',
                            success: 'Successfully downloaded file',
                            error: 'Something went wrong downloading file',
                          });

                          const response = await fetchPromise;
                          const blob = await response.blob();
                          saveAs(blob, resource.name);
                        });
                      } else {
                        window.open(externalize(resource.url), '_blank');
                      }
                    }}
                  />
                );
              })
            ) : (
              <Spinner />
            )}
          </div>
        </>
        {objectivesEditor ? (
          <>
            <Text
              block
              style={{ display: hasObjectives ? undefined : 'none' }}
              className={`plan-section-header ${
                objectivesExpanded && 'expanded'
              }`}
              onClick={() => {
                toggleObjectivesExpanded();
                appInsights.trackEvent({
                  name: objectivesExpanded
                    ? 'RELATED_PLAN_COLLAPSED_OBJECTIVES'
                    : 'RELATED_PLAN_EXPANDED_OBJECTIVES',
                  properties: {
                    sub: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    meetingPlanId: meetingPlan.id,
                  },
                });
              }}
            >
              <FontIcon
                iconName={
                  objectivesExpanded ? 'ChevronDownMed' : 'ChevronRightMed'
                }
                className="chevron"
              />
              Plan
            </Text>

            <div
              style={{ display: hasObjectives ? undefined : 'none' }}
              className={`plan-editor-container ${
                objectivesExpanded && 'expanded'
              }`}
            >
              <Slate
                editor={objectivesEditor}
                initialValue={objectives}
                onChange={setObjectives}
              >
                <Editable
                  readOnly={true}
                  renderElement={renderObjectivesElement}
                  renderLeaf={renderLeaf}
                  className={editableClass}
                />
              </Slate>
            </div>
          </>
        ) : null}

        {notesEditor ? (
          <>
            <Text
              block
              style={{ display: hasNotes ? undefined : 'none' }}
              className={`plan-section-header ${notesExpanded && 'expanded'}`}
              onClick={() => {
                toggleNotesExpanded();
                appInsights.trackEvent({
                  name: notesExpanded
                    ? 'RELATED_PLAN_COLLAPSED_NOTES'
                    : 'RELATED_PLAN_EXPANDED_NOTES',
                  properties: {
                    sub: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    meetingPlanId: meetingPlan.id,
                  },
                });
              }}
            >
              <FontIcon
                iconName={notesExpanded ? 'ChevronDownMed' : 'ChevronRightMed'}
                className="chevron"
              />
              Notes
            </Text>

            <div
              style={{ display: hasNotes ? undefined : 'none' }}
              className={`plan-editor-container ${notesExpanded && 'expanded'}`}
            >
              <Slate
                editor={notesEditor}
                initialValue={notes}
                onChange={setNotes}
              >
                <Editable
                  readOnly={true}
                  renderElement={renderNotesElement}
                  renderLeaf={RenderLeaf}
                  className={editableClass}
                />
              </Slate>
            </div>
          </>
        ) : null}

        <>
          <Text
            block
            style={{ display: hasActionItems ? undefined : 'none' }}
            className={`plan-section-header ${
              actionItemsExpanded && 'expanded'
            }`}
            onClick={() => {
              toggleActionItemsExpanded();
              appInsights.trackEvent({
                name: actionItemsExpanded
                  ? 'RELATED_PLAN_COLLAPSED_ACTION_ITEMS'
                  : 'RELATED_PLAN_EXPANDED_ACTION_ITEMS',
                properties: {
                  sub: user?.sub,
                  name: user?.name,
                  email: user?.email,
                  meetingPlanId: meetingPlan.id,
                },
              });
            }}
          >
            <FontIcon
              iconName={
                actionItemsExpanded ? 'ChevronDownMed' : 'ChevronRightMed'
              }
              className="chevron"
            />
            Follow Up
          </Text>

          <div
            style={{ display: hasActionItems ? undefined : 'none' }}
            className={`plan-editor-container ${
              actionItemsExpanded && 'expanded'
            }`}
          >
            {tasksFetched && isConnected && isSynced ? (
              tasksData?.data?.map((item) => {
                return (
                  <div key={item.id} className={taskClass}>
                    <Checkbox
                      className="completed-checkbox"
                      checked={item.completed}
                      onChange={(_e, checked) => {
                        patchTask({ id: item.id, completed: !!checked });
                      }}
                      styles={{
                        root: {
                          padding: '0',
                          fontSize: FontSizes.small,
                        },
                        checkbox: {
                          height: '.75rem',
                          width: '.75rem',
                          position: 'relative',
                          top: '.25rem',
                        },
                        checkmark: {
                          color: 'white',
                          fontSize: `${FontSizes.small} !important`,
                        },
                        text: {
                          fontSize: `${FontSizes.small} !important`,
                          lineHeight: '1rem',
                        },
                        label: {
                          fontSize: `${FontSizes.small} !important`,
                        },
                      }}
                    />
                    <div
                      className={classNames(
                        'task-text',
                        item.completed ? 'complete' : undefined,
                      )}
                    >
                      <span>{item.text}</span>
                    </div>
                    <div className={classNames('task-assignee')}>
                      <Text
                        variant="small"
                        className={classNames(
                          'task-assignee-text',
                          item.assignee?.email === email?.toLowerCase()
                            ? 'assignee-me'
                            : undefined,
                        )}
                      >
                        {item.assignee?.name ??
                          item.assignee?.email ??
                          'Unassigned'}
                      </Text>
                    </div>
                    <div
                      className={classNames(
                        'task-duedate',
                        !item.dueDate && 'no-due-date',
                      )}
                    >
                      <Text variant="small">
                        {item.dueDate
                          ? humanizeDateTime(DateTime.fromISO(item.dueDate), {})
                          : 'No due date set'}
                      </Text>
                    </div>
                  </div>
                );
              })
            ) : (
              <Spinner />
            )}
          </div>
        </>
      </div>
      {linkDialog}
    </div>
  );
};
