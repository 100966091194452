import { mergeStyles, Stack, Text } from '@fluentui/react';
import { PricingAndFinancialsLogo } from './PricingAndFinancialsLogo';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { PricingAndFinancialsContent } from './PricingAndFinancialsContent';
import {
  DealRoom,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import { PricingAndFinancialsPlaceholder } from './PricingAndFinancialsPlaceholder';

export type PricingAndFinancialsProps = {
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: DetailedDealRoom;
  refreshDealRoom: () => Promise<unknown>;
};

export const PricingAndFinancials = ({
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: PricingAndFinancialsProps) => {
  const wrapperContainer = mergeStyles({
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    borderRadius: '4px',
    boxShadow: '0px 2px 3px 0px #00000008',

    '.header': {
      display: 'flex',
      padding: '1rem 1.5rem',
      width: 'calc(100% - 3rem)',
      alignItems: 'center',
      borderRadius: '4px',
      backgroundColor: DEALROOMS_COLORS.white,
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      gap: '.375rem',

      '.title': {
        fontSize: '1.5rem',
        fontWeight: '400',
        lineHeight: '2.5rem',
        padding: '0',
        margin: '0',
      },
    },

    '.content': {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
      padding: '2rem',
    },
  });

  return (
    <Stack className={wrapperContainer}>
      <Stack.Item className="header">
        <PricingAndFinancialsLogo />
        <Text className="title">Pricing and Financials</Text>
      </Stack.Item>
      <Stack.Item className="content">
        {/*<PricingAndFinancialsPlaceholder />*/}
        <PricingAndFinancialsContent
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          dealRoom={dealRoom}
          refreshDealRoom={refreshDealRoom}
        />
      </Stack.Item>
    </Stack>
  );
};
