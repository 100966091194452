import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  NeutralColors,
  Text,
  mergeStyles,
} from '@fluentui/react';
import {
  Contact,
  DetailedMeetingflow,
  UserActivity,
  UserActivityType,
} from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { orderBy } from 'lodash';
import React from 'react';
import { titleCase } from '../../Helpers/Typography';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import StyledDateTime from '../StyledDateTime';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';

interface MeetingPlanUserActivityListProps {
  meetingPlan: Pick<DetailedMeetingflow, 'id' | 'attendees' | 'userActivity'>;
  organizationSlug: string;
  onContactClick: (c: Contact['id']) => void;
  maxItems?: number;
}

interface MeetingPlanUserActivityProps {
  meetingPlan: Pick<DetailedMeetingflow, 'id' | 'attendees'>;
  organizationSlug: string;
  activity: UserActivity;
  contact: Contact | undefined;
  onContactClick: (c: Contact['id']) => void;
  children?: React.ReactNode;
}

const PLAN_NAME = 'Meetingflow';

const HIDE_ACTIVITY_TYPES = ['VIEW_PLAN'];

const ACTIVITY_TYPE_ICONS: { [key: string]: string } = {
  CREATE_PLAN: 'PageAdd',
  APPLY_TEMPLATE: 'FileTemplate',
  VIEW_PLAN: 'EntryView',
  SHARE_PLAN: 'Share',
  EMAIL_SUMMARY: 'Mail',
  SLACK_SUMMARY: 'Message',
  CREATE_PREP_MEETING: 'AddEvent',
  UPDATE_PREP_MEETING: 'EventInfo',
  ASSOCIATE_CRM_OBJECT: 'CRMServices',
  DISASSOCIATE_CRM_OBJECT: 'CRMServices',
  UPDATE_CRM_OBJECT: 'CRMServices',
  LOG_MEETING_TO_CRM: 'CRMReport',
  GENERATE_DOSSIER: 'DocumentSearch',
  CREATE_TASK: 'SkypeCheck',
  COMPLETE_TASK: 'SkypeCircleCheck',
  ASSIGN_TASK: 'InboxCheck',
  SCHEDULE_CALL_RECORDING: 'Record2',
  CANCEL_SCHEDULED_CALL_RECORDING: 'Record2',
  START_CALL_RECORDING: 'Record2',
  STOP_CALL_RECORDING: 'CircleStop',
  DELETE_CALL_RECORDING: 'Delete',
  VIEW_RECORDING_SHARE: 'EntryView',
};

export const BasePlanActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
  children,
}: MeetingPlanUserActivityProps) => {
  const appInsights = useAppInsightsContext();
  const { isDark } = useLightOrDarkMode();
  const { user } = useAuth0();
  const userDisplayName =
    activity?.user?.name ||
    contact?.name ||
    activity?.user?.email?.split('@')[0] ||
    contact?.email.split('@')[0];

  const activityClass = mergeStyles({
    margin: '0',
    padding: '.5rem',
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    position: 'relative',
    width: 'auto',
    transition: '.3s all ease-in-out',
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    minHeight: '2rem',
    backgroundColor: isDark
      ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
      : MEETINGFLOW_COLORS.white,
    '.icon': {
      fontSize: FontSizes.large,
      lineHeight: '2rem',
      display: 'inline-block',
      color: isDark ? NeutralColors.gray130 : NeutralColors.gray80,
      flexBasis: '3rem',
    },
    '.contact': {
      flexBasis: '5rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      alignSelf: 'center',
      fontSize: FontSizes.small,
    },
    '.time': {
      flexBasis: '5rem',
      fontSize: FontSizes.small,
      color: NeutralColors.gray80,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      alignSelf: 'center',
    },
    '.content': {
      fontSize: FontSizes.small,
      flexBasis: '100%',
      color: isDark ? NeutralColors.gray100 : NeutralColors.gray160,
      alignSelf: 'center',
    },
  });

  return (
    <li className={activityClass}>
      <FontIcon
        className="icon"
        iconName={ACTIVITY_TYPE_ICONS[activity?.type]}
      />
      {contact && onContactClick ? (
        <Link
          onClick={
            onContactClick
              ? () => {
                  onContactClick(contact.id);
                  appInsights.trackEvent({
                    name: 'PLAN_TIMELINE_CLICKED_CONTACT',
                    properties: {
                      sub: user?.sub,
                      name: user?.name,
                      email: user?.email,
                      meetingPlanId: meetingPlan.id,
                      contactId: contact.id,
                    },
                  });
                }
              : undefined
          }
          key={contact.id}
          className="contact"
        >
          {userDisplayName}
        </Link>
      ) : (
        <span className="contact">{userDisplayName}</span>
      )}{' '}
      <span className="content">{children}</span>
      <StyledDateTime
        dateTime={activity.createdAt}
        displayComponents={['time']}
        className="time"
      />
    </li>
  );
};

export const CreatePlanActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      <span>created this {PLAN_NAME}.</span>
    </BasePlanActivity>
  );
};

export const ApplyTemplateActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  const appInsights = useAppInsightsContext();
  const { user } = useAuth0();
  const templateName = activity.additionalDetails.name;
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      <span>
        applied {templateName ? 'the template' : ''}{' '}
        <Link
          onClick={() => {
            appInsights.trackEvent({
              name: 'PLAN_TIMELINE_CLICKED_TEMPLATE',
              properties: {
                sub: user?.sub,
                name: user?.name,
                email: user?.email,
                meetingPlanId: meetingPlan.id,
                templateId: activity.additionalDetails.templateId,
              },
            });
            window.open(
              `../library/templates/${activity.additionalDetails.templateId}`,
              '_blank',
            );
          }}
        >
          {templateName || 'a template'}
        </Link>
        .
      </span>
    </BasePlanActivity>
  );
};

export const ViewPlanActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      viewed this Meetingflow.
    </BasePlanActivity>
  );
};

export const MentionActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      mentioned{' '}
      {activity.additionalDetails.name
        ? `${activity.additionalDetails.name} (${activity.additionalDetails.email})`
        : activity.additionalDetails.email}
      .
    </BasePlanActivity>
  );
};

export const SharePlanActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  const appInsights = useAppInsightsContext();
  const { user } = useAuth0();
  const shareEmails = activity.additionalDetails.shareEmails as string[];
  const getContactForEmailAddress = (email: string) => {
    return meetingPlan.attendees.find((a) => a.email === email);
  };

  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      notified{' '}
      {shareEmails.length
        ? shareEmails.map((e, idx) => {
            const thisContact = getContactForEmailAddress(e);
            return (
              <span key={e}>
                {thisContact && onContactClick ? (
                  <Link
                    onClick={() => {
                      appInsights.trackEvent({
                        name: 'PLAN_TIMELINE_CLICKED_CONTACT',
                        properties: {
                          sub: user?.sub,
                          name: user?.name,
                          email: user?.email,
                          meetingPlanId: meetingPlan.id,
                          contactId: contact?.id,
                        },
                      });
                      onContactClick(thisContact.id);
                    }}
                  >
                    {thisContact.name}
                  </Link>
                ) : (
                  e
                )}
                {idx === shareEmails.length - 1 ? '' : ', '}
              </span>
            );
          })
        : 'someone.'}
    </BasePlanActivity>
  );
};

export const EmailSummaryActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      emailed a summary of this meeting.
    </BasePlanActivity>
  );
};

export const SlackSummaryActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  const channelUrl = activity.additionalDetails.channelUrl;
  const channel = activity.additionalDetails.channel;
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      sent a{' '}
      {channelUrl && channel ? (
        <span>
          summary of this meeting to{' '}
          <Link onClick={() => window.open(`${channelUrl}`, '_blank')}>
            #{channel}
          </Link>
          .
        </span>
      ) : (
        <span>summary of this meeting to Slack.</span>
      )}
    </BasePlanActivity>
  );
};

export const TeamsSummaryActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  const channelUrl = activity.additionalDetails.channelUrl;
  const channel = activity.additionalDetails.channel;
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      sent a{' '}
      {channelUrl && channel ? (
        <span>
          summary of this meeting to{' '}
          <Link onClick={() => window.open(`${channelUrl}`, '_blank')}>
            #{channel}
          </Link>
          .
        </span>
      ) : (
        <span>summary of this meeting to Microsoft Teams.</span>
      )}
    </BasePlanActivity>
  );
};

export const CreatePrepMeetingActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  const appInsights = useAppInsightsContext();
  const { user } = useAuth0();
  const attendees = activity.additionalDetails.attendees as string[];
  const getContactForEmailAddress = (email: string) => {
    return meetingPlan.attendees.find((a) => a.email === email);
  };
  const created = activity.type.toString() === 'CREATE_PREP_MEETING';
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      <span>{created ? 'created' : 'updated'} a prep meeting with </span>
      {attendees.map((e, idx) => {
        const thisContact = getContactForEmailAddress(e);
        return (
          <span key={e}>
            {thisContact && onContactClick ? (
              <Link
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'PLAN_TIMELINE_CLICKED_CONTACT',
                    properties: {
                      sub: user?.sub,
                      name: user?.name,
                      email: user?.email,
                      meetingPlanId: meetingPlan.id,
                      contactId: contact?.id,
                    },
                  });
                  onContactClick(thisContact.id);
                }}
              >
                {thisContact.name}
              </Link>
            ) : (
              e
            )}
            {idx === attendees.length - 1 ? '' : ', '}
          </span>
        );
      })}
    </BasePlanActivity>
  );
};

export const UpdatePrepMeetingActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  const appInsights = useAppInsightsContext();
  const { user } = useAuth0();
  const attendees = activity.additionalDetails.attendees as string[];
  const getContactForEmailAddress = (email: string) => {
    return meetingPlan.attendees.find((a) => a.email === email);
  };
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      updated a prep meeting with{' '}
      {attendees.map((e, idx) => {
        const thisContact = getContactForEmailAddress(e);
        return (
          <span key={e}>
            {thisContact && onContactClick ? (
              <Link
                onClick={() => {
                  appInsights.trackEvent({
                    name: 'PLAN_TIMELINE_CLICKED_CONTACT',
                    properties: {
                      sub: user?.sub,
                      name: user?.name,
                      email: user?.email,
                      meetingPlanId: meetingPlan.id,
                      contactId: contact?.id,
                    },
                  });
                  onContactClick(thisContact.id);
                }}
              >
                {thisContact.name}
              </Link>
            ) : (
              e
            )}
            {idx === attendees.length - 1 ? '' : ', '}
          </span>
        );
      })}
    </BasePlanActivity>
  );
};

export const CRMObjectActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  const appInsights = useAppInsightsContext();
  const { user } = useAuth0();
  const CRMApp = activity.additionalDetails.app as string;
  const CRMObjectType = activity.additionalDetails.objectType as string;
  const CRMObjectId = activity.additionalDetails.objectId as string;
  const CRMObjectName = activity.additionalDetails.name as string;
  const CRMInstanceId = activity.additionalDetails.instanceId as string;
  const CRM_ACTIVITY_TYPE_LABELS: { [key: string]: string } = {
    DISASSOCIATE_EXTERNAL_OBJECT: `unpinned`,
    ASSOCIATE_CRM_OBJECT: `pinned`,
    DISASSOCIATE_CRM_OBJECT: `unpinned`,
    UPDATE_CRM_OBJECT: `updated`,
  };
  const CRMActivityTypeLabel: string = CRM_ACTIVITY_TYPE_LABELS[activity.type];

  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      {CRMApp === 'SALESFORCE' && CRMObjectType === 'ACCOUNT' ? (
        CRMObjectName ? (
          <span>
            {CRMActivityTypeLabel} the account{' '}
            <Link
              onClick={() => {
                appInsights.trackEvent({
                  name: 'PLAN_TIMELINE_CLICKED_SF_ACCOUNT',
                  properties: {
                    sub: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    meetingPlanId: meetingPlan.id,
                    instanceId: CRMInstanceId,
                    objectId: CRMObjectId,
                  },
                });
                window.open(`${CRMInstanceId}/${CRMObjectId}`, '_blank');
              }}
              as="span"
            >
              {CRMObjectName}
            </Link>
            .
          </span>
        ) : (
          <span>{CRMActivityTypeLabel} a Saleforce account.</span>
        )
      ) : null}

      {CRMApp === 'SALESFORCE' && CRMObjectType === 'DEAL' ? (
        CRMObjectName ? (
          <span>
            {CRMActivityTypeLabel} the opportunity{' '}
            <Link
              onClick={() => {
                appInsights.trackEvent({
                  name: 'PLAN_TIMELINE_CLICKED_SF_DEAL',
                  properties: {
                    sub: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    meetingPlanId: meetingPlan.id,
                    instanceId: CRMInstanceId,
                    objectId: CRMObjectId,
                  },
                });
                window.open(`${CRMInstanceId}/${CRMObjectId}`, '_blank');
              }}
              as="span"
            >
              {CRMObjectName}
            </Link>
            .
          </span>
        ) : (
          <span>{CRMActivityTypeLabel} an opportunity.</span>
        )
      ) : null}

      {CRMApp === 'SALESFORCE' && CRMObjectType === 'CONTACT' ? (
        CRMObjectName ? (
          <span>
            {CRMActivityTypeLabel} the contact{' '}
            <Link
              onClick={() => {
                appInsights.trackEvent({
                  name: 'PLAN_TIMELINE_CLICKED_SF_CONTACT',
                  properties: {
                    sub: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    meetingPlanId: meetingPlan.id,
                    instanceId: CRMInstanceId,
                    objectId: CRMObjectId,
                  },
                });
                window.open(`${CRMInstanceId}/${CRMObjectId}`, '_blank');
              }}
              as="span"
            >
              {CRMObjectName}
            </Link>
            .
          </span>
        ) : (
          <span>{CRMActivityTypeLabel} a contact.</span>
        )
      ) : null}

      {CRMApp === 'SALESFORCE' && CRMObjectType === 'LEAD' ? (
        CRMObjectName ? (
          <span>
            {CRMActivityTypeLabel} the lead{' '}
            <Link
              onClick={() => {
                appInsights.trackEvent({
                  name: 'PLAN_TIMELINE_CLICKED_SF_LEAD',
                  properties: {
                    sub: user?.sub,
                    name: user?.name,
                    email: user?.email,
                    meetingPlanId: meetingPlan.id,
                    instanceId: CRMInstanceId,
                    objectId: CRMObjectId,
                  },
                });
                window.open(`${CRMInstanceId}/${CRMObjectId}`, '_blank');
              }}
              as="span"
            >
              {CRMObjectName}
            </Link>
            .
          </span>
        ) : (
          <span>{CRMActivityTypeLabel} a lead.</span>
        )
      ) : null}

      {CRMApp === 'HUBSPOT' && CRMObjectType === 'ACCOUNT' ? (
        CRMObjectName ? (
          <span>
            {CRMActivityTypeLabel} the account {CRMObjectName}.
          </span>
        ) : (
          <span>{CRMActivityTypeLabel} a HubSpot account.</span>
        )
      ) : null}

      {CRMApp === 'HUBSPOT' && CRMObjectType === 'DEAL' ? (
        CRMObjectName ? (
          <span>
            {CRMActivityTypeLabel} the opportunity {CRMObjectName}.
          </span>
        ) : (
          <span>{CRMActivityTypeLabel} a HubSpot deal.</span>
        )
      ) : null}
    </BasePlanActivity>
  );
};

export const LogMeetingToCRMActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  const CRMApp: string = activity.additionalDetails.app as string;
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      <span>logged this meeting to {titleCase(CRMApp)}.</span>
    </BasePlanActivity>
  );
};

export const GenerateDossierActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      generated a pre-meeting dossier.
    </BasePlanActivity>
  );
};

export const ScheduleCallRecordingActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      scheduled the call to be recorded.
    </BasePlanActivity>
  );
};
export const CancelScheduledCallRecordingActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      cancelled the scheduled call recording.
    </BasePlanActivity>
  );
};

export const StartCallRecordingActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      started recording the call.
    </BasePlanActivity>
  );
};

export const StopCallRecordingActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      stopped recording the call.
    </BasePlanActivity>
  );
};

export const DeleteCallRecordingActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      deleted the call recording.
    </BasePlanActivity>
  );
};

export const ViewRecordingShareActivity = ({
  meetingPlan,
  organizationSlug,
  activity,
  contact,
  onContactClick,
}: MeetingPlanUserActivityProps) => {
  return (
    <BasePlanActivity
      meetingPlan={meetingPlan}
      organizationSlug={organizationSlug}
      activity={activity}
      contact={contact}
      onContactClick={onContactClick}
    >
      viewed the call recording.
    </BasePlanActivity>
  );
};

const ACTIVITY_TYPE_COMPONENTS: Record<
  UserActivityType,
  React.FC<MeetingPlanUserActivityProps>
> = {
  CREATE_PLAN: CreatePlanActivity,
  APPLY_TEMPLATE: ApplyTemplateActivity,
  VIEW_PLAN: ViewPlanActivity,
  SHARE_PLAN: SharePlanActivity,
  EMAIL_SUMMARY: EmailSummaryActivity,
  SLACK_SUMMARY: SlackSummaryActivity,
  TEAMS_SUMMARY: TeamsSummaryActivity,
  MENTION: MentionActivity,
  CREATE_PREP_MEETING: CreatePrepMeetingActivity,
  UPDATE_PREP_MEETING: CreatePrepMeetingActivity,
  DISASSOCIATE_EXTERNAL_OBJECT: CRMObjectActivity,
  ASSOCIATE_CRM_OBJECT: CRMObjectActivity,
  DISASSOCIATE_CRM_OBJECT: CRMObjectActivity,
  UPDATE_CRM_OBJECT: CRMObjectActivity,
  LOG_MEETING_TO_CRM: LogMeetingToCRMActivity,
  GENERATE_DOSSIER: GenerateDossierActivity,
  CREATE_TASK: () => null,
  COMPLETE_TASK: () => null,
  ASSIGN_TASK: () => null,
  SCHEDULE_CALL_RECORDING: ScheduleCallRecordingActivity,
  CANCEL_SCHEDULED_CALL_RECORDING: CancelScheduledCallRecordingActivity,
  START_CALL_RECORDING: StartCallRecordingActivity,
  STOP_CALL_RECORDING: StopCallRecordingActivity,
  DELETE_CALL_RECORDING: DeleteCallRecordingActivity,
  VIEW_RECORDING_SHARE: ViewRecordingShareActivity,
  CREATE_DEAL_ROOM: () => null,
  UPDATE_DEAL_ROOM: () => null,
  VIEW_DEAL_ROOM: () => null,
  DELETE_DEAL_ROOM: () => null,
  COMPLETE_BUYER_SURVEY: () => null,
  UPLOAD_ARTIFACT: () => null,
  UPDATE_ARTIFACT: () => null,
  VIEW_ARTIFACT: () => null,
  SHARE_ARTIFACT: () => null,
  UPVOTE_ARTIFACT: () => null,
  DOWNVOTE_ARTIFACT: () => null,
  DELETE_ARTIFACT: () => null,
  ASK_ARTIFACT: () => null,
  SCHEDULE_MEETING: () => null,
  RESCHEDULE_MEETING: () => null,
  UPDATE_MEETING: () => null,
  CANCEL_MEETING: () => null,
  MEETING: () => null,
  ADD_MUTUAL_ACTION_ITEM: () => null,
  UPDATE_MUTUAL_ACTION_ITEM: () => null,
  COMPLETE_MUTUAL_ACTION_ITEM: () => null,
  DELETE_MUTUAL_ACTION_ITEM: () => null,
  ADD_DEAL_ROOM_CONTACT: () => null,
  UPDATE_DEAL_ROOM_CONTACT_ROLE: () => null,
  REMOVE_DEAL_ROOM_CONTACT: () => null,
  ADD_DEAL_ROOM_COMMENT: () => null,
  UPDATE_DEAL_ROOM_COMMENT: () => null,
  DELETE_DEAL_ROOM_COMMENT: () => null,
};

export const MeetingPlanUserActivityList = ({
  meetingPlan,
  organizationSlug,
  onContactClick,
  maxItems,
}: MeetingPlanUserActivityListProps) => {
  const { isDark } = useLightOrDarkMode();
  const userActivity = meetingPlan.userActivity;
  const activityFiltered = userActivity?.filter(
    (a) => !HIDE_ACTIVITY_TYPES.includes(a.type.toString()),
  );

  const activityFilteredAndSliced = orderBy(activityFiltered, ['createdAt'])
    ?.reverse()
    .slice(0, maxItems);

  const activityByDay = activityFilteredAndSliced?.reduce(
    (groups: { [key: string]: UserActivity[] }, activity: UserActivity) => {
      const date = activity.createdAt.split('T')[0];
      if (!HIDE_ACTIVITY_TYPES.includes(activity?.type.toString())) {
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(activity);
      }
      return groups;
    },
    {},
  );

  const planActivityTimelineClass = mergeStyles({
    margin: '0 0 .5rem 0',
    padding: 0,

    li: {
      margin: '0 0 .25rem 0',
      padding: 0,
      fontSize: FontSizes.small,
      listStyleType: 'none',
    },

    '.ms-Icon': {
      height: '1rem',
      width: '1rem',
      textAlign: 'center',
      position: 'relative',
      top: '1px',
    },
  });

  const getContactForActivity = (activity: UserActivity) => {
    return meetingPlan.attendees.find((a) => a.email === activity?.user?.email);
  };

  return (
    <>
      {activityByDay &&
        Object.keys(activityByDay).map((d) => {
          return (
            <React.Fragment key={d}>
              <Text
                block
                style={{
                  fontSize: FontSizes.small,
                  marginBottom: '.5rem',
                  color: isDark ? NeutralColors.gray60 : NeutralColors.gray120,
                  fontWeight: FontWeights.semibold,
                }}
              >
                <StyledDateTime
                  style={{
                    color: MEETINGFLOW_COLORS.teal,
                  }}
                  dateTime={activityByDay[d][0].createdAt}
                  displayComponents={['date']}
                />
              </Text>
              <ul className={planActivityTimelineClass}>
                {activityByDay[d]?.map((activity) => {
                  const contact = getContactForActivity(activity);
                  const Component:
                    | React.FC<MeetingPlanUserActivityProps>
                    | undefined = ACTIVITY_TYPE_COMPONENTS[activity.type];

                  if (!Component) {
                    return null;
                  }

                  return (
                    <Component
                      key={activity.createdAt}
                      meetingPlan={meetingPlan}
                      organizationSlug={organizationSlug}
                      activity={activity}
                      contact={contact}
                      onContactClick={onContactClick}
                    />
                  );
                })}
              </ul>
            </React.Fragment>
          );
        })}
    </>
  );
};
