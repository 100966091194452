import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, mergeStyleSets, Spinner, Text } from '@fluentui/react';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Slate } from 'slate-react';
import { CustomEditable } from '../../../Components/Collab/CustomEditable';
import { getCoreEditor } from '../../../Components/Collab/Helpers/EditorHelpers';
import { SplitViewLayout } from '../../../Components/Layouts/SplitViewLayout';
import { useLinkDialog } from '../../../Hooks/Modals/useLinkDialog';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';

type DealRoomArtifactCommentsPanelProps = {
  organizationSlug: string;
  dealRoomId: number;
  artifactId: number;
};

export const DealRoomArtifactCommentsPanel = ({
  organizationSlug,
  dealRoomId,
  artifactId,
}: DealRoomArtifactCommentsPanelProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
    useLinkDialog();

  const [currentThreadId, setCurrentThreadId] = useState<number | undefined>(
    undefined,
  );

  const commentEditor = useMemo(() => {
    return getCoreEditor(
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
    );
  }, [createLinkDialogDeferred, getAccessTokenSilently, organizationSlug]);

  const {
    data: artifactComments,
    isLoading: artifactCommentsLoading,
    isFetched: artifactCommentsFetched,
  } = useQuery(
    ['artifactComments', organizationSlug, dealRoomId, artifactId],
    async () => {
      const token = await getAccessTokenSilently();
      return await DealRoomsApiClient.listArtifactComments(
        organizationSlug,
        dealRoomId,
        artifactId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!dealRoomId && !!artifactId,
    },
  );

  const styles = mergeStyleSets({
    header: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      margin: '0.5rem',
    },
  });

  return (
    <SplitViewLayout
      fullWidthFooter
      layoutName="ArtifactCommentsPane"
      fullWidthHeader
      header={
        <div className={styles.header}>
          <Text variant="xLarge">Comments</Text>
        </div>
      }
      footer={
        <div>
          <Slate editor={commentEditor} initialValue={[]} onChange={() => {}}>
            <CustomEditable
              organizationSlug={organizationSlug}
              name=""
              onKeyDown={undefined}
            />
          </Slate>
          <IconButton
            iconProps={{ iconName: 'Send' }}
            onClick={() => {}}
            title="Send"
          />
        </div>
      }
    >
      {artifactCommentsLoading && !artifactCommentsFetched ? (
        <Spinner />
      ) : (
        artifactComments?.data?.map((comment) => {
          return (
            <div key={comment.id}>
              <Slate
                editor={getCoreEditor(
                  getAccessTokenSilently,
                  organizationSlug,
                  createLinkDialogDeferred,
                )}
                initialValue={comment.comment}
                onChange={() => {}}
              >
                <CustomEditable
                  organizationSlug={organizationSlug}
                  name=""
                  readonly
                  onKeyDown={undefined}
                />
              </Slate>
            </div>
          );
        })
      )}
    </SplitViewLayout>
  );
};
