import { useAuth0 } from '@auth0/auth0-react';
import {
  FontIcon,
  FontSizes,
  FontWeights,
  IconButton,
  NeutralColors,
  Spinner,
  SpinnerSize,
  TextField,
  mergeStyles,
} from '@fluentui/react';
import { CheckMarkIcon } from '@fluentui/react-icons-mdl2';
import {
  GPTChatBoxMessage,
  ResponseRating,
} from '@meetingflow/common/Api/data-contracts';
import {
  guessNameFromEmail,
  titleCase,
} from '@meetingflow/common/StringHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useChatState } from '../../Hooks/useChatState';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useScrollToBottom } from '../../Hooks/useScrollToBottom';
import { OrganizationsApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { ChatMessage } from '../Common/Chat/ChatMessage';
import { GPTChatBoxAction } from './GPTChatBoxAction';
import { AutoScrollView } from '../Common/AutoScrollView';

export type GPTChatBoxProps = {
  organizationSlug: string;
  meetingPlanId: string;
  title?: string;
  initialMessages?: GPTChatBoxMessage[];
  defaultActions?: GPTChatBoxAction[];
  onClose?: (lastMessage?: string) => void;
  showChatInput?: boolean;
  chatInputPlaceholder?: string;
  maxHeightRem?: number;
  isInSidePanel?: boolean;
  excludeAgenda?: boolean;
  excludeNotes?: boolean;
  excludeTasks?: boolean;
  excludeTranscript?: boolean;
  excludeSummary?: boolean;
  excludeDossier?: boolean;
};

export const GPTChatBox = ({
  organizationSlug,
  meetingPlanId,
  title,
  initialMessages,
  defaultActions,
  showChatInput,
  onClose,
  chatInputPlaceholder,
  maxHeightRem,
  isInSidePanel,
  excludeAgenda,
  excludeDossier,
  excludeNotes,
  excludeSummary,
  excludeTasks,
  excludeTranscript,
}: GPTChatBoxProps) => {
  const { user } = useAuth0();

  const additionalProps = useMemo(
    () => ({
      excludeAgenda,
      excludeDossier,
      excludeNotes,
      excludeSummary,
      excludeTasks,
      excludeTranscript,
    }),
    [
      excludeAgenda,
      excludeDossier,
      excludeNotes,
      excludeSummary,
      excludeTasks,
      excludeTranscript,
    ],
  );

  const {
    isGenerating,
    thoughts,
    currentOutput,
    actions,
    messages,
    addUserMessage,
    addMessage,
    setMessages,
    retryLastMessage,
    abort,
  } = useChatState({
    url: `/api/organization/${organizationSlug}/plan/${meetingPlanId}/assistant/query2`,
    defaultActions,
    initialMessages,
    additionalProps,
  });

  const [userInput, setUserInput] = useState<string>('');

  const { getAccessTokenSilently } = useAuth0();
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();

  const userName = useMemo(
    () =>
      (
        user!.name ||
        guessNameFromEmail(user!.name) ||
        titleCase(user!.email!.split('@')[0])
      )?.split(/\b/g)?.[0],
    [user],
  );

  const postResponseFeedbackMutation = useMutation(
    async ({
      id,
      rating,
      feedback,
    }: {
      id: number;
      rating: ResponseRating;
      feedback?: string | null;
    }) => {
      const token = await getAccessTokenSilently();
      return OrganizationsApiClient.postAssistantResponseFeedback(
        organizationSlug,
        id,
        { rating, feedback },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      onMutate: async (variables) => {
        const oldMessages = [...messages];

        setMessages((current) =>
          current.map((message) =>
            message.id === variables.id
              ? {
                  ...message,
                  rating: variables.rating,
                  feedback: variables.feedback,
                }
              : message,
          ),
        );

        return { oldMessages };
      },
      onError: (_error, _variables, context) => {
        if (context?.oldMessages) {
          setMessages(context.oldMessages);
        }
      },
    },
  );

  const gptOutputBoxClass = mergeStyles({
    height: '100%',
    maxHeight: isInSidePanel ? undefined : `${maxHeightRem}rem`,

    '.header': {
      display: isInSidePanel ? 'none' : 'grid',
      boxSizing: 'border-box',
      lineHeight: '2rem',
      // fontFamily: 'Kalam',
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.smallPlus,
      gridTemplateColumns: 'auto 1fr auto',
      gridTemplateAreas: `
      'title _ close-button'
      `,
      width: '100%',
      height: '2rem',
      backgroundColor: isDark
        ? NeutralColors.gray210
        : MEETINGFLOW_COLORS.purpleGrey,
      zIndex: 55,
      border: `1px solid ${
        isDark ? NeutralColors.gray200 : MEETINGFLOW_COLORS.white
      }`,
      borderTopLeftRadius: '.25rem',
      borderTopRightRadius: '.25rem',
      borderBottom: 'none',
      padding: '0 .5rem',
      color: MEETINGFLOW_COLORS.purpleMedium,

      '.header-title': {
        gridArea: 'title',
      },
      '.header-close-button': {
        gridArea: 'close-button',
        transition: '.3s ease-in-out all',
        backgroundColor: 'transparent !important',
        color: MEETINGFLOW_COLORS.purpleSecondary,
        ':hover': {
          color: MEETINGFLOW_COLORS.tealMedium,
        },
        zIndex: 56,
      },
    },

    '.content': {
      height: 'auto',
      boxSizing: 'border-box',
      maxWidth: '100%',
      maxHeight: isInSidePanel ? '100%' : `calc(100%)`,
      overflowY: 'auto',
      paddingRight: '1rem',
      '.messages': {
        '.current-output': {
          float: 'left',
          '.message-content': {
            marginBottom: isGenerating ? '0' : '1rem',
          },
        },
        '.thoughts': {
          marginTop: '1rem',
          padding: '.5rem .5rem',
          width: 'calc(100% - 1rem)',
          backgroundColor: `${
            isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white
          } !important`,
          fontSize: FontSizes.small,
          color: isDark ? NeutralColors.gray50 : NeutralColors.gray160,
          fontStyle: 'italic',
          fontFamily: 'Georgia, serif',
          userSelect: 'none',

          '.thought': {
            margin: '.25rem 0',
            display: 'flex',
            justifyContent: 'left',

            '.thought-icon': {
              marginRight: '0.25rem',
            },

            '.thought-text': {},
          },
        },
      },
    },

    '.footer': {
      clear: 'both',
      padding: '0 0 0 0',
      '.actions': {
        opacity: isGenerating ? 0 : 1,
        pointerEvents: isGenerating ? 'none' : undefined,
        margin: isInSidePanel ? '0' : '.25rem 0',
        height: '1.5rem',
        padding: '0 .5rem 1rem .5rem',
        transition: '.3s ease-in-out all',
        display: 'flex',
        overflowX: 'auto',
        overflowY: 'hidden',
        '.action': {
          backgroundColor: MEETINGFLOW_COLORS.tealDark,
          color: MEETINGFLOW_COLORS.white,
          FontWeights: FontWeights.semibold,
          display: 'inline-block',
          padding: '.25rem .75rem .25rem .75rem',
          fontSize: FontSizes.small,
          cursor: 'pointer',
          borderRadius: '1rem',
          marginRight: '.25rem',
          transition: '.3s ease-in-out all',
          height: '1rem',
          lineHeight: '.85rem',
          whiteSpace: 'nowrap',

          i: {
            margin: '0 .25rem 0 .25rem',
            position: 'relative',
            top: '1px',
          },

          ':hover': {
            backgroundColor: MEETINGFLOW_COLORS.teal,
          },
        },
        '.action-action': {
          backgroundColor: isDark
            ? NeutralColors.gray140
            : NeutralColors.gray100,
          color: NeutralColors.white,
        },
      },
    },

    '.user-input-wrapper': {
      flexDirection: 'row',
      position: 'sticky',
      bottom: '0',
      zIndex: 50000,
      clear: 'both',
      margin: '2rem 0 0 0',
      paddingBottom: '2.25rem',
      backgroundColor: isDark
        ? NeutralColors.gray210
        : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
    },

    '.user-input': {
      padding: '0 .25rem 0 0',
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: isDark
        ? MEETINGFLOW_COLORS.orangeDark
        : MEETINGFLOW_COLORS.orangeLight,

      ':before': {
        content: '""',
        width: '0px',
        height: '0px',
        position: 'absolute',
        borderRight: `12px solid ${
          isDark
            ? MEETINGFLOW_COLORS.orangeDark
            : MEETINGFLOW_COLORS.orangeLight
        }`,
        borderLeft: '6px solid transparent',
        borderTop: `6px solid ${
          isDark
            ? MEETINGFLOW_COLORS.orangeDark
            : MEETINGFLOW_COLORS.orangeLight
        }`,
        borderBottom: '10px solid transparent',
        bottom: '20px',
        right: 0,
      },

      borderRadius: '.25rem',
      borderBottomRightRadius: '0',
      '.user-input-box': {
        margin: '.25rem',
        flexBasis: '100%',
        cursor: isGenerating ? 'not-allowed !important' : 'text',
        borderRadius: '.25rem',

        textarea: {
          height: 'auto !important',
        },
      },
      '.user-input-send': {
        flexBasis: '32px',
        position: 'relative',
        cursor: isGenerating ? 'not-allowed' : undefined,
        transition: '.3s ease-in-out all',
        color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
        backgroundColor: 'transparent',
        margin: 'auto 0',

        i: {
          color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
        },

        ':hover': {
          color: isDark
            ? MEETINGFLOW_COLORS.white
            : MEETINGFLOW_COLORS.purpleDarker,

          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.purpleDark
            : MEETINGFLOW_COLORS.orange,
        },
      },
    },
  });

  return (
    <div className={classNames(gptOutputBoxClass, 'gpt-chat-box')}>
      <div className="header">
        <span className="header-title">{title ?? 'Meetingflow AI'}</span>
        <IconButton
          className="header-close-button"
          iconProps={{
            iconName: 'ChromeClose',
          }}
          onClick={() => {
            const lastMessage = messages.findLast(
              (message) => message.role === 'assistant',
            );
            onClose?.(lastMessage?.content);
          }}
        />
      </div>
      <AutoScrollView className="content" autoScroll>
        <div className="messages">
          {messages.map((message, idx) => (
            <ChatMessage
              key={message.id || idx || message.content}
              id={message.id}
              userName={userName}
              role={message.role}
              displayText={message.displayText}
              content={message.content}
              rating={message.rating}
              allowCopy={!isGenerating}
              allowFeedback
              display={message.display}
              error={message.error}
              onUpvote={() => {
                if (!message.id) {
                  return;
                }
                postResponseFeedbackMutation.mutate({
                  id: message.id as number,
                  rating: 'GOOD_RESPONSE',
                });
                appInsights.trackEvent({
                  name: 'CHAT_RATING_GOOD_RESPONSE',
                  properties: {
                    organizationSlug,
                    meetingPlanId,
                    id: message.id,
                  },
                });
              }}
              onDownvote={() => {
                if (!message.id) {
                  return;
                }
                postResponseFeedbackMutation.mutate({
                  id: message.id as number,
                  rating: 'BAD_RESPONSE',
                });
                appInsights.trackEvent({
                  name: 'CHAT_RATING_BAD_RESPONSE',
                  properties: {
                    organizationSlug,
                    meetingPlanId,
                    id: message.id,
                  },
                });
              }}
              onRetry={retryLastMessage}
            />
          ))}

          {currentOutput ? (
            <ChatMessage
              role="assistant"
              className="current-output"
              content={currentOutput}
            />
          ) : null}

          <div className="footer">
            {actions?.length && !isGenerating && !thoughts.length ? (
              <div className="actions">
                {actions.map((action) => {
                  return (
                    <div
                      key={action.key}
                      className={classNames(`action action-${action.type}`)}
                      onClick={async () => {
                        const lastMessage = messages.findLast(
                          (message) => message.role === 'assistant',
                        );
                        switch (action.type) {
                          case 'action': {
                            const message = action.onClick(
                              lastMessage?.content,
                            );
                            addMessage(message);
                            break;
                          }
                          case 'async-action': {
                            action
                              .onClick(lastMessage?.content)
                              .then((message) => addMessage(message));
                            break;
                          }
                          case 'finish': {
                            action.onClick(lastMessage?.content);
                            break;
                          }
                          default: {
                          }
                        }
                        appInsights.trackEvent({
                          name: 'CHAT_CLICK_ACTION',
                          properties: {
                            organizationSlug,
                            meetingPlanId,
                            actionType: action.type,
                          },
                        });
                      }}
                    >
                      {action.iconName ? (
                        <FontIcon iconName={action.iconName} />
                      ) : null}
                      {action.label}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>

          {thoughts.length ? (
            <div className="thoughts">
              {thoughts.map((thought, idx) => (
                <div key={thought} className="thought">
                  {idx === thoughts.length - 1 ? (
                    <Spinner
                      className="thought-icon"
                      size={SpinnerSize.xSmall}
                    />
                  ) : (
                    <CheckMarkIcon className="thought-icon" />
                  )}
                  <div className="thought-text">{thought}</div>
                </div>
              ))}
            </div>
          ) : null}

          {showChatInput ? (
            <div className="user-input-wrapper">
              <div className="user-input">
                <TextField
                  className="user-input-box"
                  multiline
                  borderless={isGenerating}
                  autoAdjustHeight
                  disabled={isGenerating}
                  value={userInput}
                  placeholder={chatInputPlaceholder}
                  onChange={(_, newValue) => setUserInput(newValue || '')}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter' && userInput && !event.shiftKey) {
                      event.preventDefault();
                      event.stopPropagation();
                      setUserInput('');
                      addUserMessage(userInput, userName);
                      appInsights.trackEvent({
                        name: 'CHAT_SEND_MESSAGE',
                        properties: {
                          organizationSlug,
                          meetingPlanId,
                          sendMethod: 'enterKey',
                        },
                      });
                    }
                  }}
                  styles={{
                    root: {
                      opacity: isGenerating ? 0.5 : 1,
                      cursor: isGenerating ? 'not-allowed' : 'text',
                      border: 'none !important',
                      outline: 'none !important',

                      textarea: {
                        outline: 'none !important',
                        border: 'none !important',
                      },
                    },
                    fieldGroup: {
                      border: 'none !important',
                      outline: 'none !important',
                      borderRadius: '.25rem',
                      backgroundColor: isDark
                        ? NeutralColors.gray220
                        : MEETINGFLOW_COLORS.white,

                      ':after': {
                        outline: 'none !important',
                        border: 'none !important',
                        borderRadius: '.25rem',
                      },
                    },
                    field: {
                      border: 'none !important',
                      outline: 'none !important',
                      borderRadius: '.25rem',
                      fontSize: FontSizes.small,
                      cursor: isGenerating ? 'not-allowed' : 'text',
                      color: isDark
                        ? NeutralColors.white
                        : MEETINGFLOW_COLORS.black,

                      '::placeholder': {
                        color: isDark
                          ? NeutralColors.gray100
                          : MEETINGFLOW_COLORS.purpleMedium,
                      },
                    },
                    wrapper: {
                      border: 'none !important',
                      outline: 'none !important',
                    },
                  }}
                />
                <IconButton
                  className="user-input-send"
                  iconProps={{ iconName: 'Send' }}
                  disabled={!userInput || isGenerating}
                  onClick={() => {
                    if (userInput) {
                      setUserInput('');
                      addUserMessage(userInput, userName);
                      appInsights.trackEvent({
                        name: 'CHAT_SEND_MESSAGE',
                        properties: {
                          organizationSlug,
                          meetingPlanId,
                          sendMethod: 'sendButton',
                        },
                      });
                    }
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </AutoScrollView>
    </div>
  );
};
