import { useAuth0 } from '@auth0/auth0-react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import {
  getTheme,
  Icon,
  IconButton,
  mergeStyleSets,
  Pivot,
  PivotItem,
  Spinner,
  Text,
} from '@fluentui/react';
import { hasOwnOptionalStringProperty } from '@meetingflow/common/ObjectHelpers';
import saveAs from 'file-saver';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { artifactTypeToIconName } from '../../Helpers/IconHelpers';
import { useShareDealRoomArtifactDialog } from '../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import { useDealRoomArtifactId } from '../../Hooks/useDealRoomArtifactId';
import { useDealRoomId } from '../../Hooks/useDealRoomId';
import { useOrganization } from '../../Hooks/useOrganization';
import { OrganizationDealRoomArtifactQuery } from '../../QueryNames';
import { DealRoomsApiClient } from '../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { ImageWithFallback } from '../Common/ImageWithFallback';
import { SplitViewLayout } from '../Layouts/SplitViewLayout';
import { ArtifactIconPreview } from './Artifacts/ArtifactIconPreview';
import { DealRoomArtifactCommentsPanel } from './Artifacts/DealRoomArtifactCommentsPanel';
import { OfficeArtifactPreview } from './Artifacts/OfficeArtifactPreview';
import { DealRoomArtifactAssistantPanel } from './Assistant/DealRoomArtifactAssistantPanel';
import { DealRoomAsyncCommandBarButton } from './Components/DealRoomButton';
import { DealRoomUpDownVote } from './Components/DealRoomUpDownVote';

import '../../Styles/DealRoom/core.scss';

export const DealRoomArtifactDetailView = () => {
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const { slug: organizationSlug } = useOrganization();

  const [sidePanelTab, setSidePanelTab] = useState<
    'ask-ai' | 'comment' | 'leave-message'
  >('ask-ai');

  const theme = getTheme();

  const dealRoomId = useDealRoomId();
  const artifactId = useDealRoomArtifactId();

  useEffect(() => {
    if (!dealRoomId && !artifactId) {
      navigate(`/organization/${organizationSlug}/library/dealrooms`);
    } else if (!artifactId) {
      navigate(`/organization/${organizationSlug}/dealroom/${dealRoomId}`);
    } else if (!dealRoomId) {
      navigate(`/organization/${organizationSlug}/library/dealrooms`);
    }
  }, [artifactId, dealRoomId, navigate, organizationSlug]);

  const {
    data: artifactResponse,
    isLoading: artifactLoading,
    isError: artifactLoadingError,
    error: artifactError,
    refetch: refetchArtifact,
  } = useQuery(
    OrganizationDealRoomArtifactQuery(
      organizationSlug!,
      dealRoomId!,
      artifactId!,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.getArtifact(
        organizationSlug!,
        dealRoomId!,
        artifactId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!dealRoomId && !!artifactId,
    },
  );

  const artifact = useMemo(() => artifactResponse?.data, [artifactResponse]);

  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: organizationSlug!,
    artifactName: artifact?.name,
  });

  useEffect(() => {
    getAccessTokenSilently()
      .then((token) => {
        DealRoomsApiClient.viewArtifact(
          organizationSlug!,
          dealRoomId!,
          artifactId!,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      })
      .then(() => refetchArtifact());
  }, [
    artifactId,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    refetchArtifact,
  ]);

  const styles = mergeStyleSets({
    viewContainer: {
      padding: '2rem',
      width: 'calc(100% - 4rem)',
      height: 'calc(100vh - 9.75rem)',
      backgroundColor: DEALROOMS_COLORS.neutralLighter,
    },
    splitViewRootLayout: {
      columnGap: '1rem',
    },
    pivotContainer: {
      marginTop: '1rem',
      padding: '0 1rem',
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      '[role=tablist]': {
        whiteSpace: 'initial',
      },
      '.ms-Pivot-text': {
        fontSize: '15px',
        fontWeight: '400',
        color: DEALROOMS_COLORS.neutralDarker,
      },
      '.ms-Pivot-link.is-selected::before': {
        backgroundColor: DEALROOMS_COLORS.neutralDark,
        borderRadius: '999px',
      },
      '.ms-Pivot-link.is-selected .ms-Pivot-text': {
        fontWeight: '500',
        color: DEALROOMS_COLORS.darkerGray,
      },
    },
    sidebarRootLayout: {
      borderRadius: '4px',
      backgroundColor: DEALROOMS_COLORS.white,
      width: 'calc(100% - 2px) !important',
      height: 'calc(100% - 2px) !important',
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      boxShadow: '0 2px 2px 0 #00000005',
    },
    header: [
      theme.fonts.xLarge,
      {
        display: 'grid',
        gridTemplateColumns: 'auto max-content 1fr auto',
        gridTemplateRows: 'auto auto',
        gridTemplateAreas: `
          "back title none actions"
          "back description description description"
        `,
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '1.5rem',
        paddingBottom: '1.5rem',
        '.back': {
          gridArea: 'back',
          color: DEALROOMS_COLORS.themeSecondary,
          width: '1.5rem',
          height: '1.5rem',
          i: {
            fontSize: '1rem',
          },
        },

        '.title': {
          gridArea: 'title',
          fontSize: '40px',
          lineHeight: '48px',
          fontWeight: '400',
          color: DEALROOMS_COLORS.themePrimary,
        },

        '.description': {
          gridArea: 'description',
        },

        '.close': {
          gridArea: 'close',
        },
      },
    ],
    content: [
      theme.fonts.medium,
      {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        backgroundColor: DEALROOMS_COLORS.white,
        borderRadius: '4px',
        border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
        boxShadow: '0 2px 2px 0 #00000005',
        flex: 'auto 1',

        '.content-title': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          gap: '0.5rem',
          padding: '1.25rem 1.5rem',
          '.icon': {
            marginRight: '6px',
            fontSize: '18px',
            color: DEALROOMS_COLORS.neutralDark,
          },
          borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,

          '.title': {
            display: 'flex',
            flexDirection: 'row',
            gap: '0.25rem',
            alignItems: 'center',
            justifyContent: 'flex-start',
            lineHeight: '40px',
            color: DEALROOMS_COLORS.themePrimary,
            flex: 1,
            minWidth: 'max-content',
            fontWeight: '400',
          },

          '.actions': {
            justifySelf: 'end',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
            gap: '0.25rem',
            '.action-button': {},
          },
        },
        '.preview-container': {
          padding: '1.5rem',
          width: 'calc(100% - 3rem)',
          height: 'calc(100% - 3rem)',
          background: DEALROOMS_COLORS.neutralGray,
          overflow: 'auto',

          '.audio-artifact-preview': {
            width: '100%',
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },

          '.image-artifact-preview': {
            height: 'auto',
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },

          '.video-artifact-preview': {
            width: '100%',
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },

          '.document-artifact-preview': {
            width: '100%',
            height: '100%',
            backgroundColor: DEALROOMS_COLORS.white,
            color: DEALROOMS_COLORS.black,
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },

          '.link-artifact-preview': {
            width: '100%',
            height: '100%',
            maxWidth: 'calc(100% - 2rem - 2px)',
            maxHeight: 'calc(100% - 2rem - 2px)',
            padding: '1rem',
            border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
            borderRadius: '4px',
          },
        },
      },
    ],
    chat: {
      flex: '0 0 300px',
      borderLeft: '1px solid #ccc',
      padding: '20px',
    },
    '.title': {
      fontSize: '40px',
    },
  });

  const sidePanelContent = useMemo(() => {
    if (!organizationSlug || !dealRoomId || !artifactId) {
      return null;
    }

    switch (sidePanelTab) {
      case 'ask-ai': {
        return (
          <DealRoomArtifactAssistantPanel
            organizationSlug={organizationSlug!}
            dealRoomId={dealRoomId}
            artifactId={artifactId}
          />
        );
      }
      case 'comment': {
        return (
          <DealRoomArtifactCommentsPanel
            organizationSlug={organizationSlug!}
            dealRoomId={dealRoomId}
            artifactId={artifactId}
          />
        );
      }
      case 'leave-message': {
        return <div>Leave Message</div>;
      }
    }
  }, [artifactId, dealRoomId, organizationSlug, sidePanelTab]);

  const { iconName, preview } = useMemo(() => {
    if (!organizationSlug || !dealRoomId || !artifactId || !artifact) {
      return {
        iconName: undefined,
        preview: null,
      };
    }
    const iconName = artifactTypeToIconName(
      artifact.type,
      hasOwnOptionalStringProperty(artifact, 'mimeType')
        ? artifact.mimeType
        : undefined,
    );

    switch (artifact.type) {
      case 'AUDIO': {
        return {
          iconName,
          preview: (
            <audio controls className="audio-artifact-preview">
              <source src={artifact.fileUrl} />
            </audio>
          ),
        };
      }
      case 'IMAGE': {
        return {
          iconName,
          preview: (
            <ImageWithFallback
              className="image-artifact-preview"
              src={artifact.fileUrl}
              alt={artifact.label || artifact.name}
              fallbackSrc={artifact.thumbnailUrl}
              fallback={<ArtifactIconPreview type="IMAGE" />}
              objectFit="contain"
            />
          ),
        };
      }
      case 'VIDEO': {
        return {
          iconName,
          preview: (
            <video
              controls
              className="video-artifact-preview"
              poster={artifact.thumbnailUrl || undefined}
            >
              <source src={artifact.fileUrl} />
            </video>
          ),
        };
      }
      case 'DOCUMENT': {
        switch (artifact.mimeType) {
          case 'application/vnd.oasis.opendocument.text':
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
            return {
              iconName,
              preview: (
                <OfficeArtifactPreview
                  className="document-artifact-preview"
                  organizationSlug={organizationSlug!}
                  dealRoomId={dealRoomId}
                  fileName={artifact.fileName}
                  label={artifact.label}
                  name={artifact.name}
                  fileUrl={artifact.fileUrl}
                  thumbnailUrl={artifact.thumbnailUrl}
                />
              ),
            };
          }
          default: {
            return {
              iconName,
              preview: (
                <DocViewer
                  className="document-artifact-preview"
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    {
                      uri: artifact.fileUrl,
                    },
                  ]}
                  config={{
                    header: {
                      disableHeader: true,
                    },
                    pdfVerticalScrollByDefault: true,
                  }}
                />
              ),
            };
          }
        }
      }
      case 'LINK': {
        return {
          iconName,
          preview: (
            <iframe
              className="link-artifact-preview"
              src={artifact.url}
              title={artifact.label || artifact.name}
            />
          ),
        };
      }
      case 'MEETINGFLOW':
      case 'FILE':
      default: {
        return {
          iconName,
          preview: null,
        };
      }
    }
  }, [artifact, artifactId, dealRoomId, organizationSlug]);

  const handleOnUpVote = useCallback(async () => {
    try {
      if (
        !artifact ||
        typeof organizationSlug !== 'string' ||
        typeof dealRoomId !== 'number' ||
        typeof artifactId !== 'number' ||
        !getAccessTokenSilently ||
        !refetchArtifact
      ) {
        return;
      }

      const token = await getAccessTokenSilently();
      if (artifact.upvoted) {
        await DealRoomsApiClient.removeArtifactUpvote(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } else {
        await DealRoomsApiClient.upvoteArtifact(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }
      await refetchArtifact();
    } catch (err: unknown) {}
  }, [
    artifact,
    organizationSlug,
    dealRoomId,
    artifactId,
    getAccessTokenSilently,
    refetchArtifact,
  ]);

  const handleOnDownVote = useCallback(async () => {
    try {
      if (
        !artifact ||
        typeof organizationSlug !== 'string' ||
        typeof dealRoomId !== 'number' ||
        typeof artifactId !== 'number' ||
        !getAccessTokenSilently ||
        !refetchArtifact
      ) {
        return;
      }

      const token = await getAccessTokenSilently();
      if (artifact.downvoted) {
        await DealRoomsApiClient.removeArtifactDownvote(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      } else {
        await DealRoomsApiClient.downvoteArtifact(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }
      await refetchArtifact();
    } catch (err: unknown) {}
  }, [
    artifact,
    organizationSlug,
    dealRoomId,
    artifactId,
    getAccessTokenSilently,
    refetchArtifact,
  ]);

  const handleOnDownloadArtifact = useCallback(async () => {
    try {
      if (artifact && 'fileUrl' in artifact) {
        saveAs(artifact.fileUrl, artifact.name);
      }
    } catch (err: unknown) {}
  }, [artifact]);

  const handleOnShare = useCallback(async () => {
    try {
      if (
        !createConfirmShareDeferred ||
        typeof organizationSlug !== 'string' ||
        !organizationSlug ||
        typeof dealRoomId !== 'number' ||
        typeof artifactId !== 'number' ||
        !getAccessTokenSilently
      ) {
        return;
      }

      const confirmShare = await createConfirmShareDeferred().promise;

      if (!confirmShare) {
        return;
      }

      const token = await getAccessTokenSilently();
      await toast.promise(
        DealRoomsApiClient.shareArtifact(
          organizationSlug,
          dealRoomId,
          artifactId,
          {
            subject: confirmShare.subject,
            message: confirmShare.message,
            contacts: confirmShare.contacts,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        ),
        {
          loading: 'Sharing Deal Room Artifact',
          success: (_response) => {
            return 'Successfully shared Deal Room Artifact';
          },
          error: (err: unknown) => {
            return 'Something went wrong sharing Deal Room Artifact, please try again later';
          },
        },
      );
    } catch (err: unknown) {}
  }, [
    organizationSlug,
    dealRoomId,
    artifactId,
    createConfirmShareDeferred,
    getAccessTokenSilently,
  ]);

  if (artifactLoading) {
    return <Spinner />;
  }

  if (!organizationSlug || !dealRoomId || !artifactId || !artifact) {
    return null;
  }

  return (
    <div className={styles.viewContainer}>
      <SplitViewLayout
        rootClassName={styles.splitViewRootLayout}
        layoutName="DealRoomArtifactDetailView"
        fullWidthHeader
        header={
          <div className={styles.header}>
            <IconButton
              className="back"
              iconProps={{ iconName: 'Back' }}
              onClick={() => {
                navigate(
                  `/organization/${organizationSlug}/dealroom/${dealRoomId}?buyerView=true`,
                );
              }}
            />

            <Text variant="superLarge" className="title">
              {artifact.label || artifact.name}
            </Text>
          </div>
        }
        sidebar={
          <SplitViewLayout
            rootClassName={styles.sidebarRootLayout}
            layoutName="DealRoomArtifactDetailViewSidePanel"
            header={
              <Pivot
                className={styles.pivotContainer}
                selectedKey={sidePanelTab}
                onLinkClick={(i) =>
                  setSidePanelTab(
                    (i?.props?.itemKey as
                      | 'ask-ai'
                      | 'comment'
                      | 'leave-message'
                      | undefined) || 'ask-ai',
                  )
                }
              >
                <PivotItem itemKey="ask-ai" headerText="Ask a question" />
                <PivotItem itemKey="comment" headerText="Comment" />
                <PivotItem
                  itemKey="leave-message"
                  headerText="Leave a message"
                />
              </Pivot>
            }
          >
            {sidePanelContent}
          </SplitViewLayout>
        }
        contentClassName={styles.content}
        sidebarWidthPercent={35}
      >
        <>
          <div className="content-title">
            <Icon className="icon" iconName={iconName} />
            <Text className="title" variant="xLargePlus">
              {artifact.name}
            </Text>
            <div className="actions">
              {'fileName' in artifact ? (
                <DealRoomAsyncCommandBarButton
                  customClasses="action-button"
                  name="Download artifact"
                  iconProps={{ iconName: 'Download' }}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await handleOnDownloadArtifact();
                  }}
                  text="Download"
                  buttonStyleType="COMMAND_BAR"
                />
              ) : undefined}
              <DealRoomAsyncCommandBarButton
                customClasses="action-button"
                name="Share"
                iconProps={{ iconName: 'People' }}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await handleOnShare();
                }}
                text="Share"
                buttonStyleType="COMMAND_BAR"
              />
              <DealRoomUpDownVote
                upvoted={artifact.upvoted}
                downvoted={artifact.downvoted}
                onUpvote={handleOnUpVote}
                onDownvote={handleOnDownVote}
              />
            </div>
          </div>
          <Suspense fallback={<Spinner />}>
            <div className="preview-container">{preview}</div>
          </Suspense>
        </>
      </SplitViewLayout>
      {confirmShareDialog}
    </div>
  );
};
