import { Image, mergeStyles, Text, useTheme } from '@fluentui/react';
import classNames from 'classnames';
import React from 'react';

type DealRoomRadioButtonProps = {
  radioKey: string | number;
  checked: boolean;
  imgSrc?: string;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const DealRoomRadioButton = ({
  radioKey,
  checked,
  imgSrc,
  label,
  onChange,
}: DealRoomRadioButtonProps) => {
  const theme = useTheme();

  const dealRoomRadioButtonStyle = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    gap: '0.4em',
    '.radio-button': {
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      border: '2px solid black',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },

    '.radio-button:checked': {
      accentColor: theme.palette.themePrimary,
    },
  });

  return (
    <label
      key={radioKey}
      title={label}
      content={label}
      className={classNames(dealRoomRadioButtonStyle)}
    >
      <input
        className="radio-button"
        type="radio"
        value={radioKey}
        checked={checked}
        onChange={(e) => {
          onChange(e);
        }}
      />
      <Text className="radio-label">{label ? label : null}</Text>
      {imgSrc ? <Image src={imgSrc} width={'1em'} height={'1em'} /> : null}
    </label>
  );
};

export default DealRoomRadioButton;
