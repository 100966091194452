import { TableRowItem } from './DealRoomArtifactsTable';
import { mergeStyles } from '@fluentui/react';
import { useShareDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DealRoomAsyncCommandBarButton,
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { useCallback } from 'react';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import { useUserProfile } from '../../../Hooks/useProfile';

type ArtifactsTableActionsProps = {
  itemData: TableRowItem;
  organizationSlug: string;
  dealRoomId: number;
  refreshDealRoom: () => Promise<unknown>;
  openDetailsView: (id: DealRoomArtifact['id']) => unknown;
  onDelete: ((id: DealRoomArtifact['id']) => Promise<unknown>) | undefined;
};

export const ArtifactsTableActions = ({
  itemData,
  organizationSlug,
  dealRoomId,
  refreshDealRoom,
  openDetailsView,
  onDelete,
}: ArtifactsTableActionsProps) => {
  const { userId } = useUserProfile();
  const { getAccessTokenSilently } = useAuth0();

  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: organizationSlug || '',
    artifactName: itemData?.name,
  });

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Deal Room Artifact?`,
    subText:
      'Deleting this Deal Room Artifact will delete it for all users and cannot be undone. Are you sure you want to delete this Deal Room Artifact?',
    primaryAction: 'CANCEL',
  });

  const handleClickShare = useCallback(
    async (e: { preventDefault: () => void; stopPropagation: () => void }) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        const confirmShare = await createConfirmShareDeferred().promise;
        if (!confirmShare) {
          return;
        }

        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.shareArtifact(
            organizationSlug!,
            dealRoomId,
            itemData?.artifactId,
            {
              subject: confirmShare.subject,
              message: confirmShare.message,
              contacts: confirmShare.contacts,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Sharing Deal Room Artifact',
            success: (_response) => {
              return 'Successfully shared Deal Room Artifact';
            },
            error: (err: unknown) => {
              return 'Something went wrong sharing Deal Room Artifact, please try again later';
            },
          },
        );

        await refreshDealRoom();
      } catch (err: unknown) {}
    },
    [
      refreshDealRoom,
      organizationSlug,
      dealRoomId,
      itemData?.artifactId,
      createConfirmShareDeferred,
      getAccessTokenSilently,
    ],
  );

  const handleClickDelete = useCallback(
    async (e: { preventDefault: () => void; stopPropagation: () => void }) => {
      e.preventDefault();
      e.stopPropagation();

      try {
        if (userId !== itemData?.creatorId || !onDelete) {
          return;
        }

        const confirmDelete = await createConfirmDeleteDeferred().promise;

        if (!confirmDelete) {
          return;
        }

        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.deleteArtifact(
            organizationSlug!,
            dealRoomId,
            itemData?.artifactId,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Deleting Deal Room Artifact',
            success: (_response) => {
              onDelete(itemData?.artifactId);

              return 'Successfully deleted Deal Room Artifact';
            },
            error: (err: unknown) => {
              return 'Something went wrong deleting Deal Room Artifact, please try again later';
            },
          },
        );

        await refreshDealRoom();
      } catch (err: unknown) {}
    },
    [
      userId,
      refreshDealRoom,
      organizationSlug,
      dealRoomId,
      itemData?.artifactId,
      itemData?.creatorId,
      onDelete,
      createConfirmDeleteDeferred,
      getAccessTokenSilently,
    ],
  );

  const styles = mergeStyles({
    display: 'flex',
    alignItems: 'center',
    gap: '.25rem',
  });

  return (
    <div className={styles}>
      <DealRoomCommandBarButton
        customClasses="action-button"
        iconProps={{ iconName: 'OpenFolderHorizontal' }}
        onClick={() => openDetailsView(itemData.artifactId)}
        buttonStyleType="COMMAND_BAR"
        text="View"
      />
      <DealRoomAsyncCommandBarButton
        customClasses="action-button"
        iconProps={{ iconName: 'Share' }}
        onClick={handleClickShare}
        buttonStyleType="COMMAND_BAR"
        text="Share"
      />
      <DealRoomAsyncCommandBarButton
        customClasses="action-button"
        iconProps={{ iconName: 'Delete' }}
        onClick={handleClickDelete}
        buttonStyleType="COMMAND_BAR"
        text="Delete"
      />
      {confirmShareDialog}
      {confirmDeleteDialog}
    </div>
  );
};
