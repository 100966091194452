import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import * as Y from 'yjs';
import { Descendant } from 'slate';
import {
  FontSizes,
  Icon,
  mergeStyles,
  NeutralColors,
  PrimaryButton,
  Spinner,
} from '@fluentui/react';
import { YjsEditor } from '@slate-yjs/core';
import EditorFrame from '../../Collab/EditorFrame';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { getYjsEditor } from '../../Collab/Helpers/EditorHelpers';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useLinkDialog } from '../../../Hooks/Modals/useLinkDialog';
import { useCollabProvider } from '../../../Hooks/useCollabProvider';
import { Contact } from '@meetingflow/common/Api/data-contracts';

export type ContactNoteEditorProps = {
  color: string;
  organizationSlug: string;
  contact: Pick<Contact, 'id' | 'name' | 'email'>;
  backgroundColor?: string;
  readOnly?: boolean;
  onToggleEdit?: (editing?: boolean) => void;
  alwaysEditing?: boolean;
  fullWidth?: boolean;
};
export const ContactNoteEditor = ({
  color,
  organizationSlug,
  contact,
  backgroundColor,
  readOnly = false,
  onToggleEdit,
  alwaysEditing,
  fullWidth = false,
}: ContactNoteEditorProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { name, email, picture } = user!;
  const { isGuest } = useOrganization(organizationSlug);

  const { isDark } = useLightOrDarkMode();

  const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
    useLinkDialog();

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, contact.email]);

  const notesYArray = useMemo(() => {
    return ydoc.get('notes', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  const [editing, setEditing] = useState(alwaysEditing);

  const { provider } = useCollabProvider({
    providerName: 'CONTACT',
    documentName: `Contact__${organizationSlug}__${contact.email}`,
    color,
    email: email!,
    name,
    ydoc,
    picture,
  });

  const editor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      notesYArray,
      provider,
      {
        data: {
          alphaColor: isDark
            ? color.slice(0, -2) + '0.5)'
            : color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesYArray, provider]);

  useEffect(() => {
    if (!editor) {
      return;
    }
    YjsEditor.connect(editor);
    return () => {
      YjsEditor.disconnect(editor);
    };
  }, [editor]);

  if (isGuest) {
    return null;
  }

  if (!editor) {
    return <Spinner />;
  }

  const editorWrapperClass = mergeStyles({
    overflow: 'visible',
    position: 'relative',
    padding: '0',
    width: '100%',
    height: 'auto',
    lineHeight: !editing ? '1rem' : undefined,
    border: `1px solid transparent`,
    borderRadius: '.25rem',
    transition: '.3s ease-in-out all',
    backgroundColor: backgroundColor || 'transparent',

    'p[data-slate-node="element"]:first-of-type': {
      marginTop: '0 !important',
    },

    'span[data-slate-placeholder="true"]': {
      top: editing ? undefined : '0 !important',
    },

    ':hover': {
      backgroundColor: !editing
        ? isDark
          ? 'transparent'
          : 'white'
        : undefined,
      border: !editing
        ? isDark
          ? `1px solid transparent`
          : `1px solid ${MEETINGFLOW_COLORS.purpleLighter}`
        : undefined,

      '[data-slate-editor="true"]': {
        backgroundColor: 'transparent !important',
      },
    },
  });

  return readOnly ? (
    notesYArray.toString() ? (
      <div
        style={{
          paddingTop: '.25rem',
          position: 'relative',
        }}
      >
        {notesYArray.toString()}
      </div>
    ) : null
  ) : (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setEditing(true);
          onToggleEdit?.(true);
        }}
        className={editorWrapperClass}
      >
        <EditorFrame
          placeholder={`Add a note about ${contact.name || contact.email}...`}
          organizationSlug={organizationSlug}
          contactId={contact.id}
          editor={editor}
          readonly={!editing}
          name="ContactPanelNotes"
          additionalEventProps={{
            organizationSlug,
            contactName: contact.name,
            contactEmail: contact.email,
          }}
          allowTags
          allowMentions
          showPlaceholderHint
          showEditableIcon={!editing}
          showAutoSaveIndicator={false}
          wrapperBackgroundColor={
            !editing ? 'transparent' : isDark ? NeutralColors.gray200 : 'white'
          }
          backgroundColor={
            !editing ? 'transparent' : isDark ? NeutralColors.gray200 : 'white'
          }
          hideMentionHint
          editorMaxHeight={'150px'}
        />
      </div>
      {editing && !alwaysEditing ? (
        <div style={{ textAlign: 'right', marginTop: '.5rem' }}>
          <PrimaryButton
            styles={{
              root: {
                marginBottom: '.25rem',
                height: '1.5rem',
                fontSize: FontSizes.small,
              },
            }}
            text="Done Editing"
            onClick={(e) => {
              onToggleEdit?.(false);
              e.preventDefault();
              e.stopPropagation();
              setEditing(false);
            }}
          />
        </div>
      ) : null}
      {linkDialog}
    </>
  );
};
