import { useAuth0 } from '@auth0/auth0-react';
import { DefaultButton, mergeStyles, TextField } from '@fluentui/react';
import { Application, DealRoom } from '@meetingflow/common/Api/data-contracts';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DealRoomRadioPreference } from '../Components/DealRoomRadioPreference';
import DealRoomCard from '../Components/DealRoomCard';
import { DealRoomAsyncPrimaryButton } from '../Components/DealRoomButton';
import { BaseModal } from '../../MeetingPlans/Dialogs/BaseModal';
import { PickValues } from '@meetingflow/common/ObjectHelpers';
import DealRoomRadioButton from '../Components/DealRoomRadioButton';
import Teams from '../../../Static/Images/ms-teams.png';
import Zoom from '../../../Static/Images/zoom.png';
import Webex from '../../../Static/Images/webex.png';

type PreferredModeOfCommunication =
  | 'CHAT'
  | 'EMAIL'
  | 'PHONE'
  | 'SMS'
  | 'VIDEO'
  | 'WHATSAPP'
  | 'OTHER';

type BuyerPreferenceDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  selfServe: number | null;
  teamDecision: number | null;
  priceSensitive: number | null;
  preferredConferencingPlatform: Application | null;
  preferredConferencingPlatformOther: string | null;
  prefferedModeOfCommunication: PreferredModeOfCommunication | null;
  preferredCommunicationPlatform: Application | null;
  preferredCommunicationPlatformOther: string | null;
  onProfileUpdated?: () => Promise<unknown>;
  onSkip: () => void;
};

export const BuyerPreferenceDialog = ({
  organizationSlug,
  dealRoomId,
  selfServe,
  teamDecision,
  priceSensitive,
  preferredConferencingPlatform,
  preferredConferencingPlatformOther,
  prefferedModeOfCommunication,
  preferredCommunicationPlatform,
  preferredCommunicationPlatformOther,
  onProfileUpdated,
  onSkip,
}: BuyerPreferenceDialogProps) => {
  const formValues = [
    {
      key: 'selfServe',
      icon: 'Robot',
      label: 'Assistance',
      leftLabel: 'Self-serve only',
      rightLabel: 'Talk to someone every step of the way',
    },
    {
      key: 'teamDecision',
      icon: 'Teamwork',
      label: 'Decision Maker',
      leftLabel: "I'm the decision maker",
      rightLabel: "It's a team choice",
    },
    {
      key: 'priceSensitive',
      icon: 'Money',
      label: 'Looking for',
      leftLabel: 'Low Prices',
      rightLabel: 'Features and Performance',
    },
  ];
  const { getAccessTokenSilently } = useAuth0();

  const [userPhone, setUserPhone] = useState<string | null>(null);

  const [profileState, setProfileState] = useState<
    Pick<
      DealRoom,
      | 'selfServe'
      | 'teamDecision'
      | 'priceSensitive'
      | 'preferredCommunicationPlatform'
    > & {
      preferredConferencingPlatform: Application | 'OTHER' | null;
      preferredConferencingPlatformOther: string | null;
      prefferedModeOfCommunication: PreferredModeOfCommunication | null;
      preferredCommunicationPlatformOther: string | null;
    }
  >({
    selfServe,
    teamDecision,
    priceSensitive,
    preferredConferencingPlatform: preferredCommunicationPlatformOther
      ? 'OTHER'
      : preferredConferencingPlatform,
    preferredConferencingPlatformOther,
    prefferedModeOfCommunication,
    preferredCommunicationPlatform,
    preferredCommunicationPlatformOther,
  });

  const onSubmit = async () => {
    if (
      !profileState.selfServe ||
      !profileState.teamDecision ||
      !profileState.priceSensitive ||
      !profileState.preferredConferencingPlatform ||
      !profileState.preferredConferencingPlatformOther ||
      !profileState.prefferedModeOfCommunication ||
      !profileState.preferredCommunicationPlatformOther
    ) {
      return;
    }

    const token = await getAccessTokenSilently();

    const result = await DealRoomsApiClient.updateDealRoom(
      organizationSlug,
      dealRoomId,
      PickValues(profileState, [
        'selfServe',
        'teamDecision',
        'priceSensitive',
        'preferredConferencingPlatform',
        'preferredConferencingPlatformOther',
        'prefferedModeOfCommunication',
        'preferredCommunicationPlatformOther',
      ]),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (result.status === 200 && onProfileUpdated) {
      await onProfileUpdated();
    } else {
      toast.error('Failed to update profile', { duration: 5000 });
    }
  };

  const contentStyle = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    padding: '0rem 1.25rem 1.25rem 1.25rem',

    '.header': {
      textWrap: 'wrap',
    },

    '.form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',

      '.radio-list': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        padding: '12px 0px',
      },

      '.other-input': {
        padding: '0px 24px 12px 24px',
      },
    },

    '.footer': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
  });

  return (
    <BaseModal
      title="Tell us about your preferences"
      subtitle="Our mission is to make your experience as simple and enjoyable as
       possible. No more pesky salespeople at every turn! You are in the
       driver's seat!"
      isOpen
      headerPadding="16px 24px 4px"
      headerFontColor="black"
      headerBackgroundColor="transparent"
      subtitleFontColor="#6F7288"
      modalWidth="570px"
    >
      <div className={contentStyle}>
        <div className="form">
          {formValues.map(({ key, icon, label, leftLabel, rightLabel }) => (
            <DealRoomRadioPreference
              key={key}
              icon={icon}
              label={label}
              value={
                profileState[key as keyof typeof profileState] as number | null
              }
              min={1}
              max={5}
              leftLabel={leftLabel}
              rightLabel={rightLabel}
              onChange={(value) =>
                setProfileState((prevState) => ({
                  ...prevState,
                  [key]: value,
                }))
              }
            />
          ))}

          <DealRoomCard icon="Video" title="My preferred conferencing tool">
            <div className="radio-list">
              {(
                [
                  {
                    key: 'TEAMS' as Application | 'OTHER',
                    title: 'Microsoft Teams',
                    imgSrc: Teams,
                  },
                  {
                    key: 'WEBEX' as Application | 'OTHER',
                    title: 'Webex',
                    imgSrc: Webex,
                  },
                  {
                    key: 'ZOOM' as Application | 'OTHER',
                    title: 'Zoom',
                    imgSrc: Zoom,
                  },
                  { key: 'OTHER' as Application | 'OTHER', title: 'Other' },
                ] satisfies {
                  key: Application | 'OTHER';
                  title: string;
                  imgSrc?: string;
                }[]
              ).map(({ key, title, imgSrc }) => (
                <div key={key}>
                  <DealRoomRadioButton
                    radioKey={key}
                    label={title}
                    imgSrc={imgSrc}
                    checked={profileState.preferredConferencingPlatform == key}
                    onChange={(e) =>
                      setProfileState((prevState) => ({
                        ...prevState,
                        preferredConferencingPlatform: key || null,
                      }))
                    }
                  />
                </div>
              ))}
            </div>
            {profileState.preferredConferencingPlatform === 'OTHER' ? (
              <TextField
                className="other-input"
                value={profileState.preferredConferencingPlatformOther || ''}
                placeholder="Enter your preferred conferencing platform"
                onChange={(_, newValue) =>
                  setProfileState((prevState) => ({
                    ...prevState,
                    preferredConferencingPlatformOther: newValue || null,
                  }))
                }
              />
            ) : null}
          </DealRoomCard>

          <DealRoomCard
            icon="Comment"
            title="My preferred mode of communication"
          >
            <div className="radio-list">
              {(
                [
                  {
                    key: 'CHAT',
                    title: 'Chat',
                  },
                  {
                    key: 'SMS',
                    title: 'Text',
                  },
                  {
                    key: 'PHONE',
                    title: 'Phone',
                  },
                  {
                    key: 'EMAIL',
                    title: 'Email',
                  },
                  {
                    key: 'OTHER',
                    title: 'Other',
                  },
                ] satisfies {
                  key: PreferredModeOfCommunication;
                  title: string;
                }[]
              ).map(({ key, title }) => (
                <div key={key}>
                  <DealRoomRadioButton
                    radioKey={key}
                    label={title}
                    checked={profileState.prefferedModeOfCommunication == key}
                    onChange={(e) =>
                      setProfileState((prevState) => ({
                        ...prevState,
                        prefferedModeOfCommunication: key,
                      }))
                    }
                  />
                </div>
              ))}
            </div>
            {['PHONE', 'SMS'].includes(
              profileState.prefferedModeOfCommunication || '',
            ) ? (
              <TextField
                className="other-input"
                inputMode="tel"
                placeholder="Enter your phone number"
                value={userPhone || ''}
                onChange={(_, newValue) => setUserPhone(newValue || null)}
              />
            ) : null}
            {profileState.prefferedModeOfCommunication === 'OTHER' ? (
              <TextField
                className="other-input"
                value={profileState.preferredCommunicationPlatformOther || ''}
                placeholder="Enter your preferred mode of communication"
                onChange={(_, newValue) =>
                  setProfileState((prevState) => ({
                    ...prevState,
                    preferredCommunicationPlatformOther: newValue || null,
                  }))
                }
              />
            ) : null}
          </DealRoomCard>
        </div>

        <div className="footer">
          <DefaultButton onClick={onSkip}>I'll do this later</DefaultButton>

          <DealRoomAsyncPrimaryButton
            onClick={onSubmit}
            disabled={
              !profileState.selfServe ||
              !profileState.teamDecision ||
              !profileState.priceSensitive ||
              !profileState.preferredConferencingPlatform ||
              !profileState.preferredConferencingPlatformOther ||
              !profileState.prefferedModeOfCommunication ||
              !profileState.preferredCommunicationPlatform
            }
            buttonStyleType='PRIMARY'
          >
            I'm ready
          </DealRoomAsyncPrimaryButton>
        </div>
      </div>
    </BaseModal>
  );
};
