import {
  FontIcon,
  FontSizes,
  FontWeights,
  Link,
  mergeStyleSets,
  NeutralColors,
} from '@fluentui/react';
import {
  GPTChatMessage,
  ResponseRating,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks, {
  Options as RehypeExternalLinksOptions,
} from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
import { useMemo } from 'react';
import toast from 'react-hot-toast';

type ChatMessageProps = {
  className?: string;
  id?: number;
  userName?: string;
  role: GPTChatMessage['role'];
  displayText?: string;
  content: string;
  rating?: ResponseRating;
  allowCopy?: boolean;
  allowFeedback?: boolean;
  display?: boolean;
  error?: boolean;
  onUpvote?: () => void | Promise<unknown>;
  onDownvote?: () => void | Promise<unknown>;
  onRetry?: () => void | Promise<unknown>;
};

export const ChatMessage = ({
  className,
  id,
  userName,
  role,
  displayText,
  content,
  rating,
  allowCopy,
  allowFeedback,
  display,
  error,
  onUpvote,
  onDownvote,
  onRetry,
}: ChatMessageProps) => {
  const { isDark } = useLightOrDarkMode();

  const label = useMemo(
    () => (role === 'user' ? userName || 'You' : 'Meetingflow AI'),
    [role, userName],
  );

  const styles = mergeStyleSets({
    message: {
      transition: '.3s ease-in-out all',
      clear: 'both',
      display: 'inline-block',
      margin: '0 0 .5rem 0',
      position: 'relative',
      boxSizing: 'border-box',
      maxWidth: '100%',

      '.message-label': {
        fontWeight: FontWeights.semibold,
        marginBottom: '.25rem',
        fontSize: FontSizes.small,
        textAlign: 'left',
      },
      '.message-content': {
        backgroundColor: isDark
          ? MEETINGFLOW_COLORS.tealDark
          : MEETINGFLOW_COLORS.tealVeryLight,
        animationName: 'fadeInAnimation',
        animationDuration: '.3s',
        transitionTimingFunction: 'linear',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        transition: '.5s ease-in-out all',
        padding: '.5rem',
        borderRadius: '.25rem',
        position: 'relative',
        boxSizing: 'border-box',
        maxWidth: '100%',

        '*': {
          color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
          boxSizing: 'border-box',
        },

        '&.generating': {
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.tealMedium
            : MEETINGFLOW_COLORS.teal,
          padding: '.5rem 1rem .5rem .5rem',

          '*': {
            color: `${MEETINGFLOW_COLORS.white} !important`,
          },

          a: {
            textDecoration: 'underline !important',
          },

          blockquote: {
            maxWidth: '100%',
            boxSizing: 'border-box',
            color: isDark
              ? `${NeutralColors.gray40} !important`
              : `${MEETINGFLOW_COLORS.black} !important`,

            '*': {
              color: `${
                isDark ? NeutralColors.gray40 : MEETINGFLOW_COLORS.black
              } !important`,
            },
          },
        },

        h1: {
          display: 'none',
        },

        'h2, h3, h4, h5, h6': {
          margin: '.5rem 0 0 0',
          lineHeight: '1rem',
          fontWeight: FontWeights.semibold,
          color: isDark ? NeutralColors.white : MEETINGFLOW_COLORS.black,
        },

        h2: {
          display: 'block',
          fontSize: FontSizes.small,
          marginTop: '2rem',
          textTransform: 'uppercase',
          textAlign: 'center',
          color: isDark
            ? MEETINGFLOW_COLORS.tealVeryLight
            : MEETINGFLOW_COLORS.tealDark,

          ':first-child': {
            marginTop: '.5rem',
          },
        },

        h3: {
          marginLeft: '0',
          fontSize: FontSizes.medium,
          paddingBottom: '.25rem',
          marginBottom: '.5rem',
          marginTop: '1.5rem',
        },

        h4: {
          fontSize: FontSizes.small,
        },

        h5: {
          marginLeft: '1rem',
          fontSize: FontSizes.small,
          paddingBottom: '.25rem',
          marginBottom: '.5rem',
        },

        h6: {
          fontSize: FontSizes.small,
          fontWeight: FontWeights.regular,
          margin: '0 0 0 1rem',
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : NeutralColors.white,
          borderLeft: `3px solid ${
            isDark ? MEETINGFLOW_COLORS.tealLight : MEETINGFLOW_COLORS.teal
          }`,
          padding: '.25rem .5rem',
          borderRadius: '.25rem',
        },

        strong: {
          fontWeight: FontWeights.semibold,
          color: isDark ? NeutralColors.white : NeutralColors.black,
        },

        blockquote: {
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : NeutralColors.white,
          borderLeft: `3px solid ${
            isDark ? MEETINGFLOW_COLORS.tealLight : MEETINGFLOW_COLORS.teal
          }`,
          margin: '.5rem 0 .5rem 1rem',
          padding: '.5rem',
          position: 'relative',
          borderRadius: '.25rem',
          fontFamily: 'Georgia, serif',
          fontStyle: 'italic',
          fontSize: FontSizes.small,
          lineHeight: '1.25rem',

          '*': {
            fontSize: FontSizes.small,
            color: isDark ? NeutralColors.gray40 : MEETINGFLOW_COLORS.black,
            lineHeight: '1.25rem',
          },

          'ol:first-child, ul:first-child': {
            padding: `0 0 0 .75rem`,
          },

          'ol:only-child, ul:only-child': {
            marginTop: 0,
            marginBottom: 0,
          },
        },

        'span, p, ol, ul': {
          fontSize: '12px',
          lineHeight: '1rem',
          margin: '0 0 .5rem 0',
          color: isDark ? NeutralColors.gray30 : NeutralColors.gray200,

          ':only-child, :last-child': {
            margin: 0,
          },
        },

        'ol, ul': {
          padding: `0 0 0 1.5rem`,
          marginBottom: '.75rem',
          marginTop: '.25rem',
        },

        'ol ol, ul ul': {
          padding: `0 0 0 .5rem`,
        },

        code: {
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : NeutralColors.white,
          borderLeft: `3px solid ${
            isDark ? MEETINGFLOW_COLORS.tealLight : MEETINGFLOW_COLORS.teal
          }`,
          display: 'block',
          whiteSpace: 'wrap',
          margin: '.5rem 0',
          padding: '.5rem',
          position: 'relative',
          borderRadius: '.25rem',
          fontFamily: 'Georgia, serif',
          fontStyle: 'italic',
          fontSize: FontSizes.small,
          lineHeight: '1.25rem',
        },

        'h6 + pre': {
          marginLeft: '1rem',
          marginTop: 0,
        },

        'h6 + ul': {
          backgroundColor: isDark
            ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
            : NeutralColors.white,
          borderLeft: `3px solid ${
            isDark ? MEETINGFLOW_COLORS.tealLight : MEETINGFLOW_COLORS.teal
          }`,
          display: 'block',
          whiteSpace: 'wrap',
          margin: '0 0 .5rem 1rem',
          padding: '.5rem .5rem .5rem 2rem',
          position: 'relative',
          borderRadius: '.25rem',
          fontFamily: 'Georgia, serif',
          fontStyle: 'italic',
          fontSize: FontSizes.small,
          lineHeight: '1.25rem',
        },

        'h6 + blockquote': {
          marginTop: 0,
        },

        li: {
          marginLeft: 0,
          lineHeight: '1.25rem',
        },

        img: {
          margin: '0',
        },

        br: {
          margin: '0 0 .5rem 0 !important',
        },

        hr: {
          display: 'none',
          border: 0,
          margin: '.5rem 0 ',
          height: `1px`,
          backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0))`,
        },

        'hr:last-child': {
          display: 'none',
        },

        a: {
          verticalAlign: 'text-bottom',
          display: 'inline-block',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 'calc(100% - 1rem)',
          color: isDark
            ? MEETINGFLOW_COLORS.tealSuperLight
            : MEETINGFLOW_COLORS.purpleDark,
          lineHeight: '1rem',
          transition: '.3s ease-in-out all',

          ':hover': {
            textDecoration: 'underline',
            color: isDark
              ? MEETINGFLOW_COLORS.white
              : MEETINGFLOW_COLORS.purpleDark,
          },
        },
      },
    },
    ['user-message']: {
      textAlign: 'right',
      float: 'right',

      'ul, ol': {
        textAlign: 'right',
        direction: 'rtl',
        padding: '0 1.5rem 0 0 !important',

        li: {
          textAlign: 'right',
        },
      },

      ':before': {
        content: '""',
        width: '0px',
        height: '0px',
        position: 'absolute',
        borderRight: `12px solid ${
          isDark
            ? MEETINGFLOW_COLORS.orangeDark
            : MEETINGFLOW_COLORS.orangeLight
        }`,
        borderLeft: '6px solid transparent',
        borderTop: `6px solid ${
          isDark
            ? MEETINGFLOW_COLORS.orangeDark
            : MEETINGFLOW_COLORS.orangeLight
        }`,
        borderBottom: '10px solid transparent',
        bottom: '-12px',
        right: 0,
      },

      '.message-label': {
        marginLeft: '2.25rem',
      },

      '.message-content': {
        marginLeft: '2.25rem',
        marginRight: '0',
        backgroundColor: `${
          isDark
            ? MEETINGFLOW_COLORS.orangeDark
            : MEETINGFLOW_COLORS.orangeLight
        } !important`,
      },
    },
    ['assistant-message']: {
      float: 'left',

      ':before': {
        content: '""',
        width: '0px',
        height: '0px',
        position: 'absolute',
        borderLeft: `12px solid ${
          isDark
            ? MEETINGFLOW_COLORS.tealDark
            : MEETINGFLOW_COLORS.tealVeryLight
        }`,
        borderRight: '6px solid transparent',
        borderTop: `6px solid ${
          isDark
            ? MEETINGFLOW_COLORS.tealDark
            : MEETINGFLOW_COLORS.tealVeryLight
        }`,
        borderBottom: '10px solid transparent',
        bottom: '-12px',
        left: 0,
      },

      '.message-content': {
        marginRight: '2.25rem',
      },

      '.message-error-retry': {},
    },
    ['system-message']: {
      float: 'left',

      ':before': {
        content: '""',
        width: '0px',
        height: '0px',
        position: 'absolute',
        borderLeft: `12px solid ${
          isDark
            ? MEETINGFLOW_COLORS.tealDark
            : MEETINGFLOW_COLORS.tealVeryLight
        }`,
        borderRight: '6px solid transparent',
        borderTop: `6px solid ${
          isDark
            ? MEETINGFLOW_COLORS.tealDark
            : MEETINGFLOW_COLORS.tealVeryLight
        }`,
        borderBottom: '10px solid transparent',
        bottom: '-12px',
        left: 0,
      },

      '.message-content': {
        marginRight: '2.25rem',
      },

      '.message-error-retry': {},
    },
    error: {},
    copyButton: {
      width: '1rem',
      height: '1rem',
      position: 'absolute',
      top: '1.25rem',
      right: '.85rem',
      zIndex: '500',
      color: isDark ? NeutralColors.gray130 : MEETINGFLOW_COLORS.purpleLight,
      transition: '.3s ease-in-out all',
      cursor: 'pointer',
      fontSize: '10px',
      ':hover': {
        color: `${MEETINGFLOW_COLORS.tealMedium} !important`,
      },
    },
    feedback: {
      position: 'absolute',
      right: '0rem',
      bottom: '.25rem',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      '.feedback-rating-button': {
        margin: '0 0 0 .25rem',
        transition: '.3s ease-in-out all',
        fontSize: '12px',
        cursor: 'pointer',
        color: `${
          isDark ? NeutralColors.gray130 : MEETINGFLOW_COLORS.purpleLight
        } !important`,
        ':hover': {
          color: `${MEETINGFLOW_COLORS.tealMedium} !important`,
        },
      },
    },
  });

  if (!display) {
    return null;
  }

  return (
    <div
      className={classNames(
        styles.message,
        styles[`${role}-message`],
        {
          [styles.error]: error,
        },
        className,
      )}
    >
      <div className="message-label">{label}:</div>
      {allowCopy && role !== 'user' && !error && navigator.clipboard ? (
        <FontIcon
          iconName={'Copy'}
          onClick={() => {
            navigator.clipboard.writeText(content);
            toast.success(`Copied message to the clipboard.`);
          }}
          className={classNames(styles.copyButton, 'copy-button')}
          title={`Copy message to clipboard`}
        />
      ) : null}
      {allowFeedback && role === 'assistant' && id && !error ? (
        <div className={styles.feedback}>
          <FontIcon
            iconName={rating === 'GOOD_RESPONSE' ? 'LikeSolid' : 'Like'}
            className="feedback-rating-button"
            title="Was this message helpful? Thumbs Up"
            onClick={onUpvote}
          />
          <FontIcon
            iconName={rating === 'BAD_RESPONSE' ? 'DislikeSolid' : 'Dislike'}
            className="feedback-rating-button"
            title="Was this message helpful? Thumbs Down"
            onClick={onDownvote}
          />
        </div>
      ) : null}
      <div className="message-content">
        <ReactMarkdown
          rehypePlugins={[
            [
              rehypeExternalLinks,
              {
                target: '_blank',
                rel: ['noopener', 'nofollow', 'noreferrer'],
              } satisfies RehypeExternalLinksOptions,
            ],
          ]}
          remarkPlugins={[remarkGfm]}
        >
          {displayText || content}
        </ReactMarkdown>
        {error ? (
          <div className="message-error-retry">
            <Link onClick={onRetry}>Try again</Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};
