import { Company, Resource } from '@meetingflow/common/Api/data-contracts';

import randomColor from 'randomcolor';
import { useEffect, useMemo } from 'react';

import { CompanyResources } from './CompanyResources';
import { useSyncedStore } from '@syncedstore/react';
import syncedStore, { Y } from '@syncedstore/core';
import { useCollabProvider } from '../../../Hooks/useCollabProvider';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useDeferredPromise } from '../../../Hooks/useDeferredPromise';
import { isString } from 'lodash';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

interface CompanyResourcesDocProps {
  organizationSlug: string;
  company: Company;
}

const CompanyResourcesDoc = ({
  organizationSlug,
  company,
}: CompanyResourcesDocProps) => {
  const { user } = useAuth0();
  const { email } = user!;
  const companyId = company.id;
  const appInsights = useAppInsightsContext();

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, companyId]);

  const syncStore = useMemo(() => {
    return syncedStore(
      {
        resources: [] as Resource[],
      },
      ydoc,
    );
  }, [ydoc]);

  const store = useSyncedStore(syncStore);

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const {
    createDeferred: createProviderReconnectDeferred,
    deferred: providerReconnectDeferred,
    resolve: resolveProviderReconnect,
  } = useDeferredPromise<boolean>();

  const { hasConnected: collabHasConnected, isConnected: collabIsConnected } =
    useCollabProvider({
      providerName: 'COMPANY',
      documentName: `Company__${organizationSlug}__${companyId}`,
      ydoc,
      color,
      email: email!,
    });

  useEffect(() => {
    if (
      !providerReconnectDeferred?.isPending &&
      collabHasConnected &&
      !collabIsConnected
    ) {
      toast.promise(createProviderReconnectDeferred().promise, {
        loading:
          'Connection to the collaboration server was lost, attempting to reconnect',
        success: 'Successfully reconnected to the collaboration server',
        error: 'Unable to reconnect to the collaboration server',
      });
    } else if (!!providerReconnectDeferred?.isPending && collabIsConnected) {
      resolveProviderReconnect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    providerReconnectDeferred?.isPending,
    collabHasConnected,
    collabIsConnected,
  ]);

  return (
    <CompanyResources
      key={`company-resources-${companyId}`}
      showAdd
      organizationSlug={organizationSlug}
      companyId={company.id}
      resources={store.resources}
      onAdd={(item) => {
        store.resources.push(item);
        appInsights.trackEvent({
          name: 'ADD_RESOURCE_TO_COMPANY',
          properties: {
            companyId,
          },
        });
      }}
      onUpdate={(idOrIdx, props) => {
        if (isString(idOrIdx)) {
          const index = store.resources.findIndex((r) => r.id === idOrIdx);
          if (index !== -1) {
            Object.assign(store.resources[index], props);
          }
        } else {
          Object.assign(store.resources[idOrIdx], props);
        }
      }}
      onDelete={(idOrIdx) => {
        if (isString(idOrIdx)) {
          const index = store.resources.findIndex((r) => r.id === idOrIdx);
          if (index !== -1) {
            store.resources.splice(index, 1);
          }
        } else {
          store.resources.splice(idOrIdx, 1);
        }
      }}
    />
  );
};

export default CompanyResourcesDoc;
