import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { BuyerPreferenceDialog } from '../../../Components/DealRoom/Dialogs/BuyerPreferenceDialog';
import { useDealRoom } from '../../useDealRoom';

type UseDealRoomBuyerProfileDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  onProfileUpdated?: () => Promise<unknown>;
};

export const useDealRoomBuyerProfileDialog = ({
  organizationSlug,
  dealRoomId,
}: UseDealRoomBuyerProfileDialogProps) => {
  const [buyerProfileSkippedAt, setBuyerProfileSkippedAt] = useState<
    string | undefined
  >(undefined);
  // useLocalStorageState<string | undefined>(
  //   `${organizationSlug}-${dealRoomId}-bpskippedat`,
  //   undefined,
  // );

  const skipProfile = useMemo(() => {
    if (!buyerProfileSkippedAt) {
      return undefined;
    }
    try {
      const skippedAt = DateTime.fromISO(buyerProfileSkippedAt);

      return (
        skippedAt.isValid && skippedAt >= DateTime.now().minus({ days: 3 })
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [buyerProfileSkippedAt]);

  const {
    dealRoom,
    isLoading: dealRoomLoading,
    isFetched: dealRoomFetched,
    refetchAll: refetchDealRoom,
  } = useDealRoom(organizationSlug, dealRoomId);

  if (!dealRoom) {
    return null;
  }

  if (dealRoom.selfServe && dealRoom.teamDecision && dealRoom.priceSensitive) {
    return null;
  }

  if (skipProfile) {
    return null;
  }

  return (
    <BuyerPreferenceDialog
      organizationSlug={organizationSlug}
      dealRoomId={dealRoomId}
      selfServe={dealRoom.selfServe}
      teamDecision={dealRoom.teamDecision}
      priceSensitive={dealRoom.priceSensitive}
      preferredConferencingPlatform={null}
      preferredCommunicationPlatformOther={null}
      preferredCommunicationPlatform={null}
      preferredConferencingPlatformOther={null}
      prefferedModeOfCommunication={null}
      onProfileUpdated={refetchDealRoom}
      onSkip={() => setBuyerProfileSkippedAt(DateTime.now().toISO())}
    />
  );
};
