import { DetailsList, mergeStyles, Icon } from '@fluentui/react';
import { DateTime } from 'luxon';
import {
  DealRoomArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import { artifactTypeToIconName } from '../../../Helpers/IconHelpers';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { ArtifactsTableActions } from './ArtifactsTableActions';
import { useMemo } from 'react';

type DealRoomArtifactsTableProps = {
  artifacts: DetailedDealRoom['artifacts'];
  onClick: (id: DealRoomArtifact['id']) => unknown;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
  organizationSlug: string;
  dealRoomId: number;
  refreshDealRoom: () => Promise<unknown>;
  showActionButtons?: boolean;
};

export type TableRowItem = {
  key: DealRoomArtifact['id'];
  name: DealRoomArtifact['name'];
  sharedOn: DealRoomArtifact['createdAt'];
  artifactId: DealRoomArtifact['id'];
  type: DealRoomArtifact['type'];
  creatorId: number | undefined;
  mimeType: string | null | undefined;
};

const getFormattedRows = (
  artifacts: DetailedDealRoom['artifacts'],
): TableRowItem[] =>
  artifacts.map((artifact) => ({
    key: artifact.id,
    name: artifact.name,
    sharedOn: DateTime.fromISO(artifact.createdAt).toFormat('M/d/yy'),
    artifactId: artifact.id,
    type: artifact.type,
    mimeType: 'mimeType' in artifact ? artifact.mimeType : undefined,
    creatorId: artifact.creator?.id || undefined,
  }));

export const DealRoomArtifactsTable = ({
  artifacts,
  onClick,
  organizationSlug,
  dealRoomId,
  refreshDealRoom,
  onDelete,
  showActionButtons = true,
}: DealRoomArtifactsTableProps) => {
  const mainContainerStyles = mergeStyles({
    width: 'calc(100% - 22px)',
    padding: '10px',
    backgroundColor: DEALROOMS_COLORS.white,
    borderRadius: '4px',
    border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
    boxShadow: '0px 2px 2px 0px #00000005',
  });

  const tableStyles = mergeStyles({
    width: '100%',

    '.ms-DetailsHeader': {
      height: 'auto',
      lineHeight: '16px !important',
      paddingTop: '10px',
      paddingBottom: '8px',
    },

    '.ms-DetailsHeader-cell': {
      fontSize: '13px !important',
      lineHeight: '16px !important',
      fontWeight: '400 !important',
      minHeight: '16px',
      height: '16px',
      backgroundColor: 'transparent',
      color: DEALROOMS_COLORS.themeSecondary,

      '.ms-DetailsHeader-cellName': {
        fontSize: '13px !important',
        lineHeight: '16px !important',
        fontWeight: '400 !important',
      },

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.themeSecondary,
      },

      ':active': {
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.themeSecondary,
      },

      ':selected': {
        backgroundColor: DEALROOMS_COLORS.white,
        color: DEALROOMS_COLORS.themeSecondary,
      },
    },

    '.ms-DetailsRow': {
      backgroundColor: 'transparent',
      borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight} !important`,

      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLight,
        borderRadius: '4px',
      },
    },

    '.ms-DetailsRow-cell': {
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      color: DEALROOMS_COLORS.themePrimary,
      padding: '12px 0 12px 12px',
      fontSize: '15px',
      lineHeight: '24px',
    },

    '.artifact-name': {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',

      i: {
        color: DEALROOMS_COLORS.neutralDark,
        fontSize: '15px',
      },
    },
  });

  const getColumnsDef = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        fieldName: 'name',
        minWidth: 200,
        maxWidth: 274,
        onRender: (data: TableRowItem) => {
          const iconName = artifactTypeToIconName(
            data.type,
            data.mimeType || undefined,
          );
          return (
            <div className="artifact-name">
              <Icon iconName={iconName} />
              <span>{data.name}</span>
            </div>
          );
        },
      },
      {
        key: 'sharedOn',
        name: 'Shared On',
        fieldName: 'sharedOn',
        minWidth: 100,
      },
      ...(showActionButtons
        ? [
            {
              key: 'actions',
              name: 'Actions',
              fieldName: 'actions',
              minWidth: 300,
              onRender: (data: TableRowItem) => (
                <ArtifactsTableActions
                  itemData={data}
                  organizationSlug={organizationSlug}
                  dealRoomId={dealRoomId}
                  refreshDealRoom={refreshDealRoom}
                  openDetailsView={onClick}
                  onDelete={onDelete ? onDelete : undefined}
                />
              ),
            },
          ]
        : []),
    ],
    [
      organizationSlug,
      dealRoomId,
      onClick,
      onDelete,
      refreshDealRoom,
      showActionButtons,
    ],
  );

  return (
    <div className={mainContainerStyles}>
      <DetailsList
        items={getFormattedRows(artifacts)}
        columns={getColumnsDef}
        className={tableStyles}
        checkboxVisibility={2}
        onItemInvoked={(data: TableRowItem) => onClick(data?.artifactId)}
      />
    </div>
  );
};
