import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox, Spinner, Text, mergeStyles } from '@fluentui/react';
import { FontSizes, FontWeights, NeutralColors } from '@fluentui/theme';
import {
  Contact,
  DetailedMeetingflow,
  PatchTaskPayload,
  Resource,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { hasContent } from '@meetingflow/common/SlateHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { YjsEditor } from '@slate-yjs/core';
import syncedStore, { Y } from '@syncedstore/core';
import { useSyncedStore } from '@syncedstore/react';
import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Descendant } from 'slate';
import { Editable, Slate } from 'slate-react';
import { Card } from '../../../Components/Card';
import { getYjsEditor } from '../../../Components/Collab/Helpers/EditorHelpers';
import { RenderElement } from '../../../Components/Collab/RenderElement';
import { RenderLeaf } from '../../../Components/Collab/RenderLeaf';
import { GPTChatBoxContext } from '../../../Components/GPT/GPTChatBoxContext';
import { isAxiosErrorResponse } from '../../../Helpers/AxiosHelpers';
import { sendWSMessage } from '../../../Helpers/WSHelpers';
import { useLinkDialog } from '../../../Hooks/Modals/useLinkDialog';
import { useCollabProvider } from '../../../Hooks/useCollabProvider';
import { useCollapsibleHeadings } from '../../../Hooks/useCollapsibleHeadings';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../Hooks/useOrganization';
import { MeetingPlanQuery, MeetingPlanTasksQuery } from '../../../QueryNames';
import {
  MeetingflowsApiClient,
  TasksApiClient,
} from '../../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import { MeetingPlanPanelContext } from '../../../types/MeetingPlanPanelContext';
import { CallRecordingPlayer } from '../CallRecordingPlayer';
import { MeetingPlanAttendees } from '../MeetingPlanAttendees';
import { MeetingflowCard } from '../MeetingflowCard';
import { BaseSidePanel } from './BaseSidePanel';

export interface PlansSidePanelProps {
  meetingPlan:
    | Pick<
        DetailedMeetingflow,
        'id' | 'callRecording' | 'startTime' | 'endTime'
      >
    | undefined;
  panelMeetingPlanId: string;
  organizationSlug: string;
  onBack?: () => void;
  onClose: () => void;
  onContactClick?: (c: Contact['id']) => void;
  onCompanyClick?: (id: number) => void;
  color: string;
  pushPanelContext: (context: MeetingPlanPanelContext) => void;
  setChatboxContext: (context: GPTChatBoxContext | undefined) => unknown;
}
export const PlanSidePanel = ({
  meetingPlan,
  panelMeetingPlanId,
  organizationSlug,
  onBack,
  onClose,
  onContactClick,
  onCompanyClick,
  color,
  pushPanelContext,
  setChatboxContext,
}: PlansSidePanelProps) => {
  const appInsights = useAppInsightsContext();
  const { user, getAccessTokenSilently } = useAuth0();
  const { name, email, picture } = user!;
  const { isDark } = useLightOrDarkMode();
  const { internalDomains, hasEntitlement } = useOrganization();
  const [objectives, setObjectives] = useState<Descendant[]>([]);
  const [notes, setNotes] = useState<Descendant[]>([]);

  const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
    useLinkDialog();

  const client = useQueryClient();

  const {
    data: panelMeetingPlanData,
    // isLoading: panelMeetingPlanIsLoading,
    isFetched: panelMeetingPlanFetched,
    refetch: refetchPanelMeetingPlan,
  } = useQuery(
    MeetingPlanQuery(organizationSlug, panelMeetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.getMeetingflow(
        organizationSlug,
        panelMeetingPlanId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );

  const panelMeetingPlan = panelMeetingPlanData?.data;
  const isAssociatedWithEvent = panelMeetingPlan?.externalId;

  const {
    data: tasksData,
    isFetched: tasksFetched,
    refetch: refetchTasks,
  } = useQuery(
    MeetingPlanTasksQuery(organizationSlug, panelMeetingPlanId),
    async () => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.listTasks(
        {
          organizationSlug,
          meetingPlanId: panelMeetingPlanId,
          sortBy: 'createdAt',
          sortOrder: 'asc',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const { mutate: patchTask } = useMutation({
    mutationFn: async (update: { id: Task['id'] } & PatchTaskPayload) => {
      const token = await getAccessTokenSilently();
      return TasksApiClient.patchTask(
        organizationSlug,
        panelMeetingPlanId,
        update.id,
        {
          text: update.text,
          completed: update.completed,
          dueDate: update.dueDate,
          assigneeIdOrEmail: update.assigneeIdOrEmail,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    onMutate: async (update) => {
      await client.cancelQueries({
        queryKey: MeetingPlanTasksQuery(organizationSlug, panelMeetingPlanId),
      });

      const previous = client.getQueryData(
        MeetingPlanTasksQuery(organizationSlug, panelMeetingPlanId),
      ) as AxiosResponse<Task[]>;

      if (previous.data) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, panelMeetingPlanId),
          {
            ...previous,
            data: previous.data?.map((t) =>
              t.id !== update.id
                ? t
                : ({
                    ...t,
                    text: update.text ?? t.text,
                    completed: update.completed ?? t.completed,
                    dueDate: update.dueDate ?? t.dueDate,
                  } satisfies Task),
            ),
          },
        );
      }

      return {
        previous,
      };
    },
    onError: (err, update, ctx) => {
      toast.error(`Something went wrong updating Task`);

      appInsights.trackException({
        exception: err instanceof Error ? err : new Error(`${err}`),
        properties: {
          organizationSlug,
          status: isAxiosErrorResponse(err) ? err.response?.status : undefined,
          statusText: isAxiosErrorResponse(err)
            ? err.response?.statusText
            : undefined,
        },
      });

      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, panelMeetingPlanId),
          ctx.previous,
        );
      }
    },
    onSuccess: (response, update, ctx) => {
      sendWSMessage(provider?.ws, WS_MESSAGE.REFRESH_ACTION_ITEMS);
      appInsights.trackEvent({
        name: 'TOGGLE_ACTION_ITEM_COMPLETED',
        properties: {
          organizationSlug,
          meetingPlanId: panelMeetingPlanId,
          id: update.id,
          completed: update.completed,
          context: 'RELATED_PLAN_SIDEBAR',
        },
      });
      if (ctx?.previous) {
        client.setQueryData(
          MeetingPlanTasksQuery(organizationSlug, panelMeetingPlanId),
          {
            ...ctx.previous,
            data: ctx.previous.data?.map((t) =>
              t.id !== update.id ? t : response.data,
            ),
          },
        );
      }
    },
    onSettled: () => {
      client.invalidateQueries(
        MeetingPlanTasksQuery(organizationSlug, panelMeetingPlanId),
      );
    },
  });

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, panelMeetingPlanId]);

  const objectivesYArray = useMemo(() => {
    return ydoc.get('objectives', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  const notesYArray = useMemo(() => {
    return ydoc.get('notes', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  const syncStore = useMemo(() => {
    return syncedStore(
      {
        resources: [] as Resource[],
      },
      ydoc,
    );
  }, [ydoc]);

  const store = useSyncedStore(syncStore);

  const onWSMessage = useCallback(
    (messageType: number) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_ACTION_ITEMS: {
          refetchTasks();
          return true;
        }
        case WS_MESSAGE.HEARTBEAT:
        case WS_MESSAGE.RECREATE:
        case WS_MESSAGE.REFRESH_PLAN:
        case WS_MESSAGE.SAVE: {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    [refetchTasks],
  );

  const { provider, isConnected, isSynced } = useCollabProvider({
    providerName: 'RELATED MEETINGPLAN',
    documentName: `MeetingPlan__${organizationSlug}__${panelMeetingPlanId}`,
    ydoc,
    connect: true,
    color,
    email: user!.email!,
    name: user!.name,
    picture: user!.picture,
    onMessage: onWSMessage,
  });

  const objectivesEditor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      objectivesYArray,
      provider,
      {
        cursorStateField: 'objectivesCursorState',
        cursorDataField: 'objectivesCursorData',
        data: {
          alphaColor: isDark
            ? color.slice(0, -2) + '0.5)'
            : color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
      panelMeetingPlanId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectivesYArray, provider]);

  const notesEditor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      notesYArray,
      provider,
      {
        cursorStateField: 'notesCursorState',
        cursorDataField: 'notesCursorData',
        data: {
          alphaColor: isDark
            ? color.slice(0, -2) + '0.5)'
            : color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug,
      createLinkDialogDeferred,
      panelMeetingPlanId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesYArray, provider]);

  useEffect(() => {
    if (!objectivesEditor || !notesEditor) {
      return;
    }

    if (
      isSynced &&
      (!YjsEditor.connected(objectivesEditor) ||
        !YjsEditor.connected(notesEditor))
    ) {
      try {
        YjsEditor.connect(objectivesEditor);
      } catch {}
      try {
        YjsEditor.connect(notesEditor);
      } catch {}
    } else if (
      YjsEditor.connected(objectivesEditor) ||
      YjsEditor.connected(notesEditor)
    ) {
      try {
        YjsEditor.disconnect(objectivesEditor);
      } catch {}
      try {
        YjsEditor.disconnect(notesEditor);
      } catch {}
    }
  }, [isSynced, notesEditor, objectivesEditor]);

  const hasObjectives =
    meetingPlan && objectivesEditor && hasContent(objectivesEditor.children);
  const hasNotes =
    meetingPlan && notesEditor && hasContent(notesEditor.children);

  const {
    collapsedHeadings: collapsedObjectivesHeadings,
    setCollapsedHeadings: setCollapsedObjectivesHeadings,
    uncollapseHeading: uncollapseObjectivesHeadings,
    hiddenElements: hiddenObjectivesElements,
  } = useCollapsibleHeadings({
    editor: objectivesEditor,
  });

  const renderObjectivesElement = useMemo(
    () =>
      RenderElement({
        editor: objectivesEditor,
        collapsedHeadings: collapsedObjectivesHeadings,
        hiddenElements: hiddenObjectivesElements,
        setCollapsedHeadings: setCollapsedObjectivesHeadings,
        uncollapseHeading: uncollapseObjectivesHeadings,
      }),
    [
      collapsedObjectivesHeadings,
      hiddenObjectivesElements,
      objectivesEditor,
      setCollapsedObjectivesHeadings,
      uncollapseObjectivesHeadings,
    ],
  );

  const {
    collapsedHeadings: collapsedNotesHeadings,
    setCollapsedHeadings: setCollapsedNotesHeadings,
    uncollapseHeading: uncollapseNotesHeadings,
    hiddenElements: hiddenNotesElements,
  } = useCollapsibleHeadings({
    editor: notesEditor,
  });

  const renderNotesElement = useMemo(
    () =>
      RenderElement({
        editor: notesEditor,
        collapsedHeadings: collapsedNotesHeadings,
        hiddenElements: hiddenNotesElements,
        setCollapsedHeadings: setCollapsedNotesHeadings,
        uncollapseHeading: uncollapseNotesHeadings,
      }),
    [
      collapsedNotesHeadings,
      hiddenNotesElements,
      notesEditor,
      setCollapsedNotesHeadings,
      uncollapseNotesHeadings,
    ],
  );

  const renderLeaf = useCallback(RenderLeaf, []);

  const taskClass = useMemo(
    () =>
      mergeStyles({
        display: 'grid',
        width: '100%',
        minHeight: '1.5rem',
        alignItems: 'top',
        padding: '0.1rem',
        gridTemplateRows: 'auto auto',
        gridTemplateColumns: '1rem 1fr 1fr',
        gridTemplateAreas: `
    'checkbox text text'
    'checkbox assignee duedate '
    `,
        gridColumnGap: '0.3rem',
        gridRowGap: '0.1rem',
        '.completed-checkbox': {
          gridArea: 'checkbox',
          justifyContent: 'center',
        },
        '.complete': {
          textDecoration: 'line-through',
        },
        '.task-text': {
          gridArea: 'text',
          fontSize: `${FontSizes.small} !important`,
        },
        '.task-assignee': {
          gridArea: 'assignee',
          marginRight: '0.2rem',
        },
        '.task-assignee-text': {
          fontWeight: 'bold',
          fontSize: `${FontSizes.small} !important`,
        },
        '.assignee-me': {
          color: MEETINGFLOW_COLORS.teal,
        },
        '.task-duedate': {
          gridArea: 'duedate',
          fontSize: `${FontSizes.small} !important`,
        },
        '.no-due-date': {
          fontStyle: 'italic',
        },
      }),
    [],
  );

  const editableClass = mergeStyles({
    padding: '.5rem 1.5rem 3rem 1.5rem',
    width: '100%',
    height: 'auto',
    minHeight: 0,
    boxSizing: 'border-box',
    borderRadius: '.25rem',
    flexDirection: 'column',
    overflowWrap: 'break-word',
    overflow: 'auto',
    border: 'none !important',
    outline: '1px solid transparent !important',
    animationName: 'fadeInAnimation',
    animationDuration: '.3s',
    transitionTimingFunction: 'linear',
    animationIterationCount: '1',
    animationFillMode: 'forwards',
    transition: '.3s ease-in-out all',
    position: 'relative',

    '[data-slate-placeholder="true"]': {
      width: 'auto !important',
    },

    '[data-slate-node="element"][href]': {
      color: isDark
        ? MEETINGFLOW_COLORS.teal
        : MEETINGFLOW_COLORS.purpleSecondary,
    },

    'p[data-slate-node="element"]:only-child': {
      margin: 0,
    },

    'span[data-slate-placeholder="true"]': {
      position: 'relative',
      marginTop: '.5rem',
      left: '.5rem',
    },
    '[data-meetingflow="header-collapsible"]': {
      position: 'relative',
    },

    '[data-meetingflow="header-collapse-arrow"]': {
      fontSize: FontSizes.small,
      position: 'absolute',
      left: '-1rem',
      top: '.25rem',
      cursor: 'pointer',
    },

    h2: {
      fontSize: FontSizes.xLarge,
      margin: '0 0 .5rem 0',
      lineHeight: '1.5rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
    },

    h3: {
      fontSize: FontSizes.large,
      margin: '0 0 .5rem 0',
      lineHeight: '1.25rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray150,
    },

    h4: {
      fontSize: FontSizes.mediumPlus,
      margin: '0 0 .5rem 0',
      lineHeight: '1.25rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    h5: {
      fontSize: FontSizes.medium,
      margin: '0 0 .5rem 0',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    h6: {
      fontSize: FontSizes.small,
      margin: '0 0 .5rem 0',
      lineHeight: '1rem',
      fontWeight: FontWeights.semibold,
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray120,
    },

    strong: {
      fontWeight: FontWeights.semibold,
      color: isDark ? 'white' : NeutralColors.black,
    },

    blockquote: {
      backgroundColor: isDark ? NeutralColors.gray200 : NeutralColors.gray30,
      marginLeft: '1rem',
      marginRight: '1rem',
      marginTop: 0,
      marginBottom: '.5rem',
      padding: '.5rem',
      position: 'relative',
      borderRadius: '.5rem',
      fontFamily: 'Georgia, serif',
      fontStyle: 'italic',
      'p:only-child': {
        marginBottom: 0,
      },
    },

    'p, ol, ul': {
      fontSize: '13px',
      lineHeight: '1.2rem',
      margin: '0 0 .5rem 0',
      color: isDark ? NeutralColors.gray30 : NeutralColors.gray200,

      span: {
        height: '1.2rem',
      },
    },

    'ol, ul': {
      padding: `0 0 0 1.25rem`,
    },

    'ol ol, ul ul': {
      padding: `0 0 0 1.25rem`,
      marginBottom: 0,
    },

    li: {
      marginLeft: 0,
    },

    img: {
      margin: '0',
    },

    '> div': {
      margin: '0 0 .5rem 0 !important',
    },

    br: {
      margin: '0 0 .5rem 0 !important',
    },

    'span[data-slate-length="0"]': {
      height: `0 !important`,
    },

    a: {
      display: 'inline-block',
    },

    '*[data-slate-inline="true"]': {
      display: 'inline-block',
    },
  });

  const panelContentClass = mergeStyles({});

  const headerClass = mergeStyles({
    padding: '.25rem',
    marginBottom: '.5rem',
    boxSizing: 'border-box',
    position: 'sticky',
    top: 0,
    backgroundColor: isDark
      ? 'transparent'
      : MEETINGFLOW_COLORS.purpleUltraSuperLightish,
    zIndex: '500',
    height: '56px',
  });

  const meetingflowContentClass = mergeStyles({
    position: 'relative',

    backgroundColor: isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white,
    boxSizing: 'border-box',
    containerType: 'inline-size',
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 11.75rem)',
    paddingBottom: '.5rem',
  });

  const noDataMessageClass = mergeStyles({
    fontSize: FontSizes.small,
    padding: '0 1.35rem',
  });

  const beforeEnd = panelMeetingPlan
    ? DateTime.fromISO(panelMeetingPlan.endTime).diffNow().milliseconds > 0
    : true;

  const afterEnd = panelMeetingPlan
    ? DateTime.fromISO(panelMeetingPlan.endTime).diffNow().milliseconds < 0
    : false;

  return panelMeetingPlan ? (
    <BaseSidePanel
      title="Related Meetingflow"
      onBack={onBack}
      onClose={onClose}
      // contentPadding="0"
      providesOwnScrolling
    >
      <div className={panelContentClass}>
        <div className={headerClass}>
          <MeetingflowCard
            meetingflowId={panelMeetingPlan.id}
            meetingflowObj={panelMeetingPlan}
            refetchMeetingflow={refetchPanelMeetingPlan}
            organizationSlug={organizationSlug}
            large
            alwaysShowConferenceJoinButton={true}
            showCallRecordingButton
          />
        </div>
        <div className={meetingflowContentClass}>
          {panelMeetingPlan?.textSummary ? (
            <Card
              id="meetingflow-summary"
              style={{
                marginBottom: '0',
                scrollMarginTop: '0',
                overflow: 'initial',
              }}
              smallHeader
              title={<span className="meeting-plan-step-title">Summary</span>}
              allowCollapsing
              titleSize={FontSizes.large}
              titleColor={MEETINGFLOW_COLORS.teal}
              backgroundColor="transparent"
              showBorder={false}
              showShadow={false}
              color={isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white}
              contentContainerPadding=".25rem"
            >
              <Text
                id="meetingflow-summary-edit-button"
                block
                styles={{
                  root: {
                    borderRadius: '.25rem',
                    fontSize: FontSizes.medium,
                    lineHeight: '1.5',
                    whiteSpace: 'pre-line',
                    transition: '.3s ease-in-out all',
                    cursor: 'pointer',
                    outline: '1px solid transparent !important',
                  },
                }}
              >
                {panelMeetingPlan.textSummary}
              </Text>
            </Card>
          ) : null}

          {hasEntitlement('CALL_RECORDING') &&
          (beforeEnd ||
            (afterEnd &&
              panelMeetingPlan?.callRecording?.recordingFileName)) ? (
            <Card
              id="meetingflow-call-recording"
              style={{
                position: 'relative',
                marginBottom: '0',
                scrollMarginTop: '0rem',
                overflow: 'initial',
              }}
              smallHeader
              title={
                <span className="meeting-plan-step-title">Call Recording</span>
              }
              allowCollapsing
              titleSize={FontSizes.large}
              titleColor={MEETINGFLOW_COLORS.teal}
              backgroundColor="transparent"
              showBorder={false}
              color={isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white}
              showShadow={false}
              contentContainerPadding="1rem"
            >
              <CallRecordingPlayer
                organizationSlug={organizationSlug}
                meetingPlanId={panelMeetingPlanId}
                showBotStatus={!afterEnd}
              />
            </Card>
          ) : null}

          <Card
            id="meetingflow-attendees"
            style={{
              position: 'relative',
              marginBottom: '0',
              scrollMarginTop: '0',
              overflow: 'initial',
            }}
            smallHeader
            title={<span className="meeting-plan-step-title">Attendees</span>}
            allowCollapsing
            defaultCollapsed
            titleSize={FontSizes.large}
            titleColor={MEETINGFLOW_COLORS.teal}
            backgroundColor="transparent"
            showBorder={false}
            color={isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white}
            showShadow={false}
            contentContainerPadding="1rem"
          >
            {meetingPlan ? (
              <>
                <div
                  id="meetingflow-about-attendees"
                  className="meeting-plan-section"
                >
                  <MeetingPlanAttendees
                    showEmailDomains
                    showAvatars={false}
                    panelVisible={false}
                    organizationSlug={organizationSlug}
                    meetingPlan={panelMeetingPlan}
                    internalDomains={internalDomains}
                    onContactClick={onContactClick}
                    noPivot
                    onCompanyClick={onCompanyClick}
                    allowEditAttendees={!isAssociatedWithEvent}
                    allowEditingContactNotes={true}
                    rowGap={'.25rem'}
                    showContactSecondaryText={false}
                    showAttendeeCount
                    pushPanelContext={pushPanelContext}
                    companyCardBackgroundColor={'transparent'}
                    contactCardBackgroundColor={'transparent'}
                    setChatboxContext={setChatboxContext}
                    refetchMeetingPlan={refetchPanelMeetingPlan}
                  />
                </div>
              </>
            ) : null}
          </Card>

          {hasObjectives ? (
            <Card
              id="meetingflow-plan"
              style={{
                marginBottom: '0',
                scrollMarginTop: '0',
                overflow: 'initial',
              }}
              smallHeader
              title={<span className="meeting-plan-step-title">Plan</span>}
              backgroundColor="transparent"
              showBorder={false}
              showShadow={false}
              allowCollapsing
              defaultCollapsed={
                !isAssociatedWithEvent && !panelMeetingPlan?.sampleType
              }
              titleSize={FontSizes.large}
              titleColor={MEETINGFLOW_COLORS.teal}
              noBottomPadding
              color={isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white}
              contentContainerPadding="1rem"
            >
              <div className="meeting-plan-section">
                {!hasObjectives ? (
                  <div className={noDataMessageClass}>
                    No plan created for this Meetingflow.
                  </div>
                ) : null}
                {objectivesEditor ? (
                  <Slate
                    editor={objectivesEditor}
                    initialValue={objectives}
                    onChange={setObjectives}
                  >
                    <Editable
                      readOnly={true}
                      renderElement={renderObjectivesElement}
                      renderLeaf={renderLeaf}
                      className={editableClass}
                    />
                  </Slate>
                ) : null}
              </div>
            </Card>
          ) : null}

          <Card
            id="meetingflow-notes"
            style={{
              marginBottom: '0',
              scrollMarginTop: '0',
              overflow: 'initial',
            }}
            smallHeader
            title={<span className="meeting-plan-step-title">Notes</span>}
            backgroundColor="transparent"
            showBorder={false}
            showShadow={false}
            allowCollapsing
            defaultCollapsed={
              !isAssociatedWithEvent && !panelMeetingPlan?.sampleType
            }
            titleSize={FontSizes.large}
            titleColor={MEETINGFLOW_COLORS.teal}
            noBottomPadding
            color={isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white}
            contentContainerPadding="0"
          >
            <div className="meeting-notes-section">
              {!hasNotes ? (
                <div className={noDataMessageClass}>
                  No notes created for this Meetingflow.
                </div>
              ) : null}
              {notesEditor ? (
                <Slate
                  editor={notesEditor}
                  initialValue={notes}
                  onChange={setObjectives}
                >
                  <Editable
                    readOnly={true}
                    renderElement={renderNotesElement}
                    renderLeaf={renderLeaf}
                    className={editableClass}
                  />
                </Slate>
              ) : null}
            </div>
          </Card>

          <Card
            id="meetingflow-follow-up"
            style={{
              marginBottom: '0',
              scrollMarginTop: '0',
              overflow: 'initial',
            }}
            smallHeader
            title={
              <span className="meeting-plan-step-title">Action Items</span>
            }
            backgroundColor="transparent"
            showBorder={false}
            showShadow={false}
            allowCollapsing
            defaultCollapsed={
              !isAssociatedWithEvent && !panelMeetingPlan?.sampleType
            }
            titleSize={FontSizes.large}
            titleColor={MEETINGFLOW_COLORS.teal}
            noBottomPadding
            color={isDark ? NeutralColors.gray220 : MEETINGFLOW_COLORS.white}
            contentContainerPadding="1rem"
          >
            <div style={{ paddingBottom: '3rem' }}>
              {tasksFetched && !tasksData?.data?.length ? (
                <div className={noDataMessageClass}>
                  No action items created for this Meetingflow
                </div>
              ) : null}
              {tasksFetched && isConnected && isSynced ? (
                tasksData?.data?.map((item) => {
                  return (
                    <div key={item.id} className={taskClass}>
                      <Checkbox
                        className="completed-checkbox"
                        checked={item.completed}
                        onChange={(_e, checked) => {
                          patchTask({ id: item.id, completed: !!checked });
                        }}
                        styles={{
                          root: {
                            padding: '0',
                            fontSize: FontSizes.small,
                          },
                          checkbox: {
                            height: '.75rem',
                            width: '.75rem',
                            position: 'relative',
                            top: '.25rem',
                          },
                          checkmark: {
                            color: 'white',
                            fontSize: `${FontSizes.small} !important`,
                          },
                          text: {
                            fontSize: `${FontSizes.small} !important`,
                            lineHeight: '1rem',
                          },
                          label: {
                            fontSize: `${FontSizes.small} !important`,
                          },
                        }}
                      />
                      <div
                        className={classNames(
                          'task-text',
                          item.completed ? 'complete' : undefined,
                        )}
                      >
                        <span>{item.text}</span>
                      </div>
                      <div className={classNames('task-assignee')}>
                        <Text
                          variant="small"
                          className={classNames(
                            'task-assignee-text',
                            item.assignee?.email === email?.toLowerCase()
                              ? 'assignee-me'
                              : undefined,
                          )}
                        >
                          {item.assignee?.name ??
                            item.assignee?.email ??
                            'Unassigned'}
                        </Text>
                      </div>
                      <div
                        className={classNames(
                          'task-duedate',
                          !item.dueDate && 'no-due-date',
                        )}
                      >
                        <Text variant="small">
                          {item.dueDate
                            ? humanizeDateTime(
                                DateTime.fromISO(item.dueDate),
                                {},
                              )
                            : 'No due date set'}
                        </Text>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Spinner />
              )}
            </div>
          </Card>
        </div>
      </div>
    </BaseSidePanel>
  ) : (
    <div style={{ width: '100%', padding: '4rem 0', textAlign: 'center' }}>
      <Spinner />
    </div>
  );
};
