import {
  DatePicker,
  IconButton,
  IDatePicker,
  mergeStyleSets,
  Text,
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { DateTime } from 'luxon';
import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import {
  humanizeDateTime,
  toDateOrUndefined,
  toDateTimeString,
} from '@meetingflow/common/DateHelpers';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DealRoomCommandBarButton } from './DealRoomButton';

type DealRoomLabelledDateValueProps = {
  className?: string;
  label: string;
  value: Date | DateTime | string | null;
  canEdit?: boolean;
  onChange?: (newValue: string | null) => Promise<unknown>;
  viewsNumber?: number;
};

export const DealRoomLabelledDateValue = ({
  className,
  label,
  value,
  canEdit,
  onChange,
  viewsNumber,
}: DealRoomLabelledDateValueProps) => {
  const datePickerRef = useRef<IDatePicker>(null);

  const [editing, { setTrue: setEditing, setFalse: setNotEditing }] =
    useBoolean(false);
  const [dateValue, setDateValue] = useState<Date | undefined>(
    toDateOrUndefined(value),
  );

  useEffect(() => {
    if (editing) {
      const dateValue = toDateOrUndefined(value, {
        keepLocalTime: true,
      });
      setDateValue(dateValue);
      datePickerRef.current?.focus();
    }
  }, [editing, value]);

  const styles = mergeStyleSets({
    root: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: DEALROOMS_COLORS.white,
      border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,
      borderRadius: '8px',
      padding: '1rem',
      gap: '0.5rem',
      width: '178px',

      '.label': {
        fontSize: '.9375rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        textAlign: 'left',
        color: DEALROOMS_COLORS.themeSecondary,
      },

      '.text-value': {
        fontSize: '2rem',
        fontWeight: '400',
        lineHeight: '2.5rem',
        textAlign: 'left',
        color: DEALROOMS_COLORS.themePrimary,
        minWidth: 'max-content',
      },

      '.edit-container': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '.edit': {
          borderRadius: '4px',
          i: {
            color: DEALROOMS_COLORS.themeSecondary,
          },
        },

        '.save': {
          border: 'none',
          backgroundColor: 'transparent',

          ':hover': {
            backgroundColor: DEALROOMS_COLORS.neutralLighter,
          },

          i: {
            color: DEALROOMS_COLORS.themePrimary,
          },
        },

        '.views': {
          marginRight: 'auto',
          fontSize: '0.8125rem',
          fontWeight: '400',
          lineHeight: '2rem',
          color: DEALROOMS_COLORS.themeSecondary,
        },
      },
    },

    datePicker: {
      minWidth: '10rem',
      maxWidth: 300,
      margin: '0.09375rem 0',
      height: 'auto',

      '.ms-TextField-fieldGroup': {
        borderColor: DEALROOMS_COLORS.themeSecondary,
        border: 'none',
        backgroundColor: 'transparent',

        '[role="combobox"]': {
          color: DEALROOMS_COLORS.darkerGray,
          backgroundColor: DEALROOMS_COLORS.inputLightGray,
          borderRadius: '4px',
          borderWidth: '0 0 2px 0',
          borderStyle: 'solid',
          borderColor: DEALROOMS_COLORS.themeSecondary,
        },

        i: {
          color: DEALROOMS_COLORS.darkerGray,
        },

        ':after': {
          border: 'none',
          outline: `1px solid ${DEALROOMS_COLORS.themeSecondary}`,
          outlineOffset: '1px',
        },
      },
    },
  });

  return (
    <div className={classNames(styles.root, className)}>
      <Text variant="xSmall" block className="label">
        {label}
      </Text>
      {editing ? (
        <DatePicker
          componentRef={datePickerRef}
          className={styles.datePicker}
          // allowTextInput
          ariaLabel="Select a date"
          value={dateValue}
          onSelectDate={(date) => {
            setDateValue(date ?? undefined);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              e.preventDefault();
              e.stopPropagation();
              setNotEditing();
            }
          }}
        />
      ) : (
        <Text variant="large" block className="text-value">
          {value === null
            ? 'N/A'
            : `${humanizeDateTime(value, {
                displayComponents: ['date'],
                keepLocalTime: true,
                dateFormatOptions: {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                },
              })}`}
        </Text>
      )}
      <div className="edit-container">
        <div className="views">
          {typeof viewsNumber === 'number' ? viewsNumber : 'N/A'} views
        </div>
        {canEdit &&
          (editing ? (
            <DealRoomCommandBarButton
              text="Save"
              customClasses="save"
              buttonStyleType="COMMAND_BAR"
              iconProps={{ iconName: 'Accept' }}
              onClick={() => {
                const newValue = toDateTimeString(dateValue, {
                  keepLocalTime: true,
                });

                if (newValue !== value) {
                  onChange?.(newValue);
                }

                setNotEditing();
              }}
            />
          ) : (
            <IconButton
              iconProps={{ iconName: 'Edit' }}
              className="edit"
              onClick={() => setEditing()}
            />
          ))}
      </div>
    </div>
  );
};
