import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { OrganizationDealRoomQuery } from '../QueryNames';
import { DealRoomsApiClient } from '../Services/NetworkCommon';

export const useDealRoom = (orgSlug?: string, dealRoomId?: number) => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const client = useQueryClient();

  const {
    data: dealRoomData,
    isLoading: dealRoomLoading,
    isFetched: dealRoomIsFetched,
    isError: dealRoomIsError,
    error: dealRoomError,
    refetch: refetchDealRoom,
  } = useQuery(
    OrganizationDealRoomQuery(orgSlug, dealRoomId),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.getDealRoom(orgSlug!, dealRoomId!, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      enabled: !!orgSlug && !!dealRoomId && !isNaN(dealRoomId),
    },
  );

  const refetch = useCallback(async () => {
    await refetchDealRoom();
  }, [refetchDealRoom]);

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationDealRoomQuery(orgSlug, dealRoomId),
    );
  }, [client, dealRoomId, orgSlug]);

  return {
    dealRoom: dealRoomData?.data,
    isLoading: dealRoomLoading,
    isFetched: dealRoomIsFetched,
    isError: dealRoomIsError,
    error: dealRoomError,
    refetch,
    refetchAll,
  };
};
