import {
  IconButton,
  mergeStyleSets,
  Text,
  TextField,
  useTheme,
} from '@fluentui/react';
import { GPTChatBoxMessage } from '@meetingflow/common/Api/data-contracts';
import {
  guessNameFromEmail,
  titleCase,
} from '@meetingflow/common/StringHelpers';
import { useMemo, useState } from 'react';
import { AutoScrollView } from '../../../Components/Common/AutoScrollView';
import { ChatMessage } from '../../../Components/Common/Chat/ChatMessage';
import { SplitViewLayout } from '../../../Components/Layouts/SplitViewLayout';
import { useChatState } from '../../../Hooks/useChatState';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import { useUserProfile } from '../../../Hooks/useProfile';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

const INITIAL_MESSAGES = [
  {
    role: 'assistant',
    display: true,
    content: 'What would you like to know?',
  },
] satisfies GPTChatBoxMessage[];

type DealRoomArtifactAssistantPanelProps = {
  organizationSlug: string;
  dealRoomId: number;
  artifactId: number;
};

export const DealRoomArtifactAssistantPanel = ({
  organizationSlug,
  dealRoomId,
  artifactId,
}: DealRoomArtifactAssistantPanelProps) => {
  const { user } = useUserProfile();

  const theme = useTheme();

  const { isDark } = useLightOrDarkMode();

  const [userInput, setUserInput] = useState<string>('');

  const {
    isGenerating,
    currentOutput,
    messages,
    addUserMessage,
    retryLastMessage,
  } = useChatState({
    url: `/api/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${artifactId}/ask`,
    initialMessages: INITIAL_MESSAGES,
  });

  const userName = useMemo(
    () =>
      (
        user!.name ||
        guessNameFromEmail(user!.name) ||
        titleCase(user!.email!.split('@')[0])
      )?.split(/\b/g)?.[0],
    [user],
  );

  const styles = mergeStyleSets({
    splitViewRootLayout: {
      height: 'calc(100% - 2px)',
      '.content': {
        padding: '0 1.5rem 1.5rem',
        borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLighter}`,
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      margin: '0.5rem',
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.25rem',
      margin: '1.5rem',
      border: `1px solid ${DEALROOMS_COLORS.neutralDark}`,
      boxShadow: '0 4px 4px 0 #0000000A',
      borderRadius: '.25rem',
      '.input-box': {
        margin: '.125rem 0',
        flexBasis: '100%',
        cursor: isGenerating ? 'not-allowed !important' : 'text',
        borderRadius: '.25rem',
        textarea: {
          maxHeight: '50px !important',
          padding: '.8rem 0 0 1rem !important',
        },
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '.ms-TextField-wrapper': {
          width: '100%',
        },
        '.ms-TextField-fieldGroup': {
          minHeight: 'auto',
          flex: '1',
          display: 'flex',
          alignItems: 'center',
        },
      },
      '.send-button': {
        flexBasis: '32px',
        position: 'relative',
        cursor: isGenerating || !userInput ? 'not-allowed' : undefined,
        transition: '.3s ease-in-out all',
        backgroundColor: 'transparent',
        margin: 'auto 4px auto 0',
        borderRadius: '.25rem',

        i: {
          fontSize: '20px',
          padding: '10px',
          transform: 'rotate(-90deg)',
          color:
            !userInput || isGenerating
              ? DEALROOMS_COLORS.lightGray
              : DEALROOMS_COLORS.themePrimary,
        },

        ':hover': {
          backgroundColor:
            !userInput || isGenerating
              ? 'transparent'
              : DEALROOMS_COLORS.lightGray,
        },
      },
    },
    '.chat-messages-container': {
      padding: '1.5rme',
    },
  });

  return (
    <SplitViewLayout
      rootClassName={styles.splitViewRootLayout}
      layoutName="DealRoomArtifactAssistantPanel"
      header={
        <div className={styles.header}>
          <Text variant="xLarge">AI Chat</Text>
        </div>
      }
      footer={
        <div className={styles.footer}>
          <TextField
            className="input-box"
            multiline
            borderless={isGenerating}
            autoAdjustHeight
            disabled={isGenerating}
            value={userInput}
            resizable={false}
            placeholder={`Ask anything`}
            onChange={(_, newValue) => setUserInput(newValue || '')}
            onKeyDown={(event) => {
              if (event.key === 'Enter' && userInput && !event.shiftKey) {
                event.preventDefault();
                event.stopPropagation();
                setUserInput('');
                addUserMessage(userInput, userName);
              }
            }}
            styles={{
              root: {
                opacity: isGenerating ? 0.5 : 1,
                cursor: isGenerating ? 'not-allowed' : 'text',
                border: 'none !important',
                outline: 'none !important',
                textarea: {
                  outline: 'none !important',
                  border: 'none !important',
                },
              },
              fieldGroup: {
                border: 'none !important',
                outline: 'none !important',
                borderRadius: '.25rem',
                ':after': {
                  outline: 'none !important',
                  border: 'none !important',
                  borderRadius: '.25rem',
                },
              },
              field: {
                border: 'none !important',
                outline: 'none !important',
                borderRadius: '.25rem',
                fontSize: '15px',
                lineHeight: '24px',
                cursor: isGenerating ? 'not-allowed' : 'text',
                color: DEALROOMS_COLORS.themePrimary,

                '::placeholder': {
                  color: DEALROOMS_COLORS.themeSecondary,
                },
              },
              wrapper: {
                border: 'none !important',
                outline: 'none !important',
              },
            }}
          />
          <IconButton
            className="send-button"
            iconProps={{ iconName: 'Send' }}
            disabled={!userInput || isGenerating}
            onClick={() => {
              if (userInput) {
                setUserInput('');
                addUserMessage(userInput, userName);
              }
            }}
          />
        </div>
      }
    >
      <AutoScrollView autoScroll={isGenerating}>
        {messages.map((message, idx) => (
          <ChatMessage
            key={message.id || idx}
            id={message.id}
            userName={userName}
            role={message.role}
            displayText={message.displayText}
            content={message.content}
            rating={message.rating}
            allowCopy={!isGenerating}
            display={message.display}
            error={message.error}
            onRetry={retryLastMessage}
          />
        ))}
        {currentOutput ? (
          <ChatMessage
            className="current-output"
            role="assistant"
            content={currentOutput}
          />
        ) : null}
      </AutoScrollView>
    </SplitViewLayout>
  );
};
