import { mergeStyleSets, Spinner, Text, useTheme } from '@fluentui/react';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useDealRoomBuyerProfileDialog } from '../../Hooks/Modals/DealRoom/useDealRoomBuyerProfileDialog';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { useNavigate } from '../../Hooks/useNavigate';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import { DEALROOMS_COLORS } from '../../Themes/Themes';
import { SplitViewLayout } from '../Layouts/SplitViewLayout';
import ResourceNotFound from '../ResourceNotFound';
import { FooterInfoCard } from './Components/FooterInfoCard';
import { RightSidePanelCTAs } from './Components/RightSidePanelCTAs';
import { DealRoomBuyersActionItemsTab } from './Tabs/DealRoomBuyersActionItemsTab';
import { DealRoomBuyersArtifactsTab } from './Tabs/DealRoomBuyersArtifactsTab';
import { DealRoomBuyersOverviewTab } from './Tabs/DealRoomBuyersOverviewTab';
import { DealRoomBuyersTimelineTab } from './Tabs/DealRoomBuyersTimelineTab';

export type DealRoomBuyersViewProps = {
  organizationSlug: string;
  dealRoomId: number;
};

export const DealRoomBuyersView = ({
  organizationSlug,
  dealRoomId,
}: DealRoomBuyersViewProps) => {
  const navigate = useNavigate();

  const { user } = useUserProfile();

  const { role } = useOrganization();

  const {
    dealRoom,
    isLoading,
    isFetched,
    refetch: refetchDealRoom,
  } = useDealRoom(organizationSlug, dealRoomId);

  const theme = useTheme();

  const buyerProfileDialog = useDealRoomBuyerProfileDialog({
    organizationSlug,
    dealRoomId,
  });

  const location = useLocation();

  const activeTab = useMemo(() => {
    if (
      matchPath(
        `/organization/:organizationSlug/dealroom/:dealRoomId/artifacts`,
        location.pathname,
      )
    ) {
      return 'artifacts';
    }

    if (
      matchPath(
        `/organization/:organizationSlug/dealroom/:dealRoomId/journey`,
        location.pathname,
      )
    ) {
      return 'journey';
    }

    if (
      matchPath(
        `/organization/:organizationSlug/dealroom/:dealRoomId/mutual-action-plan`,
        location.pathname,
      )
    ) {
      return 'mutual-action-plan';
    }

    if (
      matchPath(
        `/organization/:organizationSlug/dealroom/:dealRoomId/settings`,
        location.pathname,
      )
    ) {
      return 'settings';
    }

    return 'overview';
  }, [location.pathname]);

  const styles = mergeStyleSets({
    header: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gridTemplateRows: 'auto auto',
      gridTemplateAreas: `
        "title actions"
        "subtitle actions"
      `,

      marginBottom: '1rem',

      '.title': {
        gridArea: 'title',
      },

      '.section-title': {
        fontWeight: 400,
        fontSize: '2.5rem',
        lineHeight: '3rem',
        color: DEALROOMS_COLORS.themePrimary,
      },

      '.subtitle': {
        gridArea: 'subtitle',
      },

      '.actions': {
        gridArea: 'actions',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '1rem',
      },
    },

    content: {
      '& [role="tabpanel"]': {
        margin: '0.5rem',
      },
    },

    mainContent: {
      width: 'calc(100% - 100px)',
      height: '100%',
      overflowX: 'auto',
      padding: '32px',
    },

    mainWrapper: {
      display: 'flex',
      width: '100%',
      minHeight: '100%',
    },
  });

  const content = useMemo(() => {
    if (!dealRoom) {
      return null;
    }

    switch (activeTab) {
      case 'overview':
        return (
          <DealRoomBuyersOverviewTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
            refreshDealRoom={refetchDealRoom}
          />
        );
      case 'artifacts':
        return (
          <DealRoomBuyersArtifactsTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
            refreshDealRoom={refetchDealRoom}
          />
        );
      case 'journey':
        return (
          <DealRoomBuyersTimelineTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            dealRoom={dealRoom!}
          />
        );
      case 'mutual-action-plan':
        return (
          <DealRoomBuyersActionItemsTab
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            actionItems={dealRoom.mutualActionItems}
            activity={dealRoom.activity.filter(
              (a) => a.type === 'VIEW_ARTIFACT',
            )}
            refetchDealRoom={refetchDealRoom}
          />
        );
      default:
        return null;
    }
  }, [activeTab, dealRoom, dealRoomId, organizationSlug, refetchDealRoom]);

  const activeTabTitle = useMemo(() => {
    switch (activeTab) {
      case 'overview':
        return `Hello ${user?.firstName || user?.name?.split(/\\s/)?.[0]}!`;
      case 'artifacts':
        return 'Artifacts';
      case 'journey':
        return 'Journey';
      case 'mutual-action-plan':
        return 'Mutual progress plan';
    }
  }, [activeTab, user]);

  if (isLoading && !isFetched) {
    return <Spinner />;
  }

  if (!dealRoom) {
    return <ResourceNotFound resourceType="Deal Room" />;
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.mainContent}>
        <SplitViewLayout
          layoutName="DealRoomBuyersViewInner"
          header={
            <div className={styles.header}>
              <Text className="section-title" variant="xxLargePlus" block>
                {activeTabTitle}
              </Text>
            </div>
          }
          minSidebarWidth={20}
          sidebarWidthPercent={20}
          maxSidebarWidth={20}
          gap="24px"
          fullWidthHeader
          sidebar={<RightSidePanelCTAs />}
          footer={<FooterInfoCard />}
        >
          <div className={styles.content}>{content}</div>
        </SplitViewLayout>
      </div>
      {buyerProfileDialog}
    </div>
  );
};

export default DealRoomBuyersView;
