import { mergeStyles } from '@fluentui/react';
import {
  DealRoom,
  DealRoomArtifact,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { DealRoomArtifacts } from '../Artifacts/DealRoomArtifacts';
import { useSearchParams } from 'react-router-dom';
import { PricingAndFinancials } from '../Components/PricingAndFinancials/PricingAndFinancials';

type DealRoomBuyersOverviewTabProps = {
  className?: string;
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: DetailedDealRoom;
  refreshDealRoom: () => Promise<unknown>;
};

export const DealRoomBuyersOverviewTab = ({
  className,
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: DealRoomBuyersOverviewTabProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const styles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',

    '.artifacts': {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'auto',
      gap: '1rem',

      '.artifact': {
        minWidth: '18rem',
      },
    },

    '.pricing-and-timeline': {
      display: 'grid',
      gridTemplateColumns: '1fr max-content',
      gridTemplateAreas: `
        "pricing timeline"
      `,
      gap: '1rem',
      flexDirection: 'row',

      '.pricing': {
        gridArea: 'pricing',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },

      '.timeline': {
        gridArea: 'timeline',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      },
    },

    '.relevant-artifacts': {},
  });

  return (
    <>
      <div className={classNames(className, styles)}>
        <DealRoomArtifacts
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          artifacts={dealRoom.artifacts}
          activity={dealRoom.activity}
          showAdd={true}
          refreshDealRoom={refreshDealRoom}
          onClick={(id: DealRoomArtifact['id']) => {
            const artifact = dealRoom.artifacts.find((a) => a.id === id);

            if (!artifact) {
              return;
            }

            switch (artifact.type) {
              case 'MEETINGFLOW': {
                navigate(
                  `/organization/${organizationSlug}/plan/${artifact.meetingflowId}`,
                );
                break;
              }
              default: {
                navigate(
                  `/organization/${organizationSlug}/dealroom/${dealRoomId}/artifact/${id}${
                    !!searchParams.get('buyerView') ? '?buyerView=true' : ''
                  }`,
                );
              }
            }
          }}
          onAdd={(_) => refreshDealRoom()}
          onUpdate={() => refreshDealRoom()}
          onDelete={(_) => refreshDealRoom()}
        />
        <PricingAndFinancials
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
          dealRoom={dealRoom}
          refreshDealRoom={refreshDealRoom}
        />
        <div className="relevant-artifacts"></div>
      </div>
    </>
  );
};
