import {
  Checkbox,
  ContextualMenuItemType,
  Facepile,
  Icon,
  IconButton,
  IContextualMenuProps,
  mergeStyleSets,
  OverflowSet,
  PersonaSize,
  Text,
  useTheme,
} from '@fluentui/react';
import { DealRoomArtifact, User } from '@meetingflow/common/Api/data-contracts';
import {
  humanizeDateTime,
  toDateTimeOrUndefined,
} from '@meetingflow/common/DateHelpers';
import classNames from 'classnames';
import { useMemo } from 'react';

type DealRoomActionItemProps = {
  className?: string;
  organizationSlug: string;
  dealRoomId: number;
  id: number;
  createdAt: string;
  text: string;
  completedAt?: string | null;
  dueDate?: string | null;
  assignee?: Pick<User, 'id' | 'name' | 'email' | 'avatarUrl'> | null;
  creator?: Pick<User, 'id' | 'name' | 'email' | 'avatarUrl'> | null;
  artifacts?: (Pick<DealRoomArtifact, 'id' | 'type' | 'label' | 'name'> & {
    mimeType?: string | null;
  })[];
  viewers: Pick<User, 'name' | 'email' | 'avatarUrl'>[];
  onCheckChanged?: () => unknown | Promise<unknown>;
  onArtifactClick?: (artifactId: number) => unknown | Promise<unknown>;
};

export const DealRoomActionItem = ({
  className,
  organizationSlug,
  dealRoomId,
  id,
  createdAt,
  text,
  completedAt,
  dueDate,
  assignee,
  creator,
  artifacts,
  viewers,
  onCheckChanged,
  onArtifactClick,
}: DealRoomActionItemProps) => {
  const theme = useTheme();

  const firstArtifact = useMemo(() => artifacts?.at(0), [artifacts]);
  const remainingArtifacts = useMemo(
    () => artifacts?.slice(1) ?? [],
    [artifacts],
  );

  const dueDateDateTime = useMemo(
    () => toDateTimeOrUndefined(dueDate),
    [dueDate],
  );

  const primaryViewers = useMemo(() => viewers.slice(0, 4), [viewers]);
  const overflowViewers = useMemo(() => viewers.slice(4), [viewers]);

  const completed = !!completedAt;
  const overdue =
    (!completed &&
      dueDateDateTime &&
      dueDateDateTime?.diffNow('days')?.days < 0) ||
    false;
  const nearDue =
    (!completed &&
      dueDateDateTime &&
      !overdue &&
      dueDateDateTime?.diffNow('days')?.days <= 3) ||
    false;

  const styles = mergeStyleSets({
    root: {
      display: 'grid',
      gridTemplateColumns: 'auto auto auto 1fr auto auto',
      gridTemplateRows: 'max-content max-content',
      gridTemplateAreas: `
        "checkbox text      text    space due-date context-menu"
        "none0    artifacts viewers space none1    none2"
      `,
      gap: '0.5rem',

      '.checkbox': {
        gridArea: 'checkbox',
      },

      '.text': {
        gridArea: 'text',
        textDecoration: !!completedAt ? 'line-through' : 'none',
      },

      '.artifacts': {
        gridArea: 'artifacts',

        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',

        '.artifact': {
          display: 'flex',
          flexDirection: 'row',
          gap: '0.25rem',
        },

        '.artifact-overflow': {},
      },

      '.viewers': {
        gridArea: 'viewers',
      },

      '.due-date': {
        gridArea: 'due-date',
        padding: '0 0.25rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',

        '> div': {
          padding: '0.1rem 0.5rem',
          borderRadius: '0.25rem',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        },

        '& .near-due': {
          backgroundColor: theme.palette.yellowLight,

          '> span': {
            color: theme.palette.black,
          },
        },

        '& .overdue': {
          backgroundColor: theme.palette.red,

          '> span': {
            color: theme.palette.white,
          },
        },
      },

      '.context-menu': {
        gridArea: 'context-menu',
      },
    },
    completed: {
      backgroundColor: theme.palette.neutralLighter,
    },
  });

  const menuProps: IContextualMenuProps = useMemo(
    () => ({
      shouldFocusOnMount: true,
      items: [
        {
          key: 'edit',
          text: 'Edit',
          iconProps: { iconName: 'Edit' },
          onClick: () => {},
        },
        {
          key: 'viewed-by',
          text: 'Viewed by',
          iconProps: { iconName: 'People' },
          onClick: () => {},
        },
        {
          key: 'request-document',
          text: 'Request',
          iconProps: { iconName: 'DocumentReply' },
          onClick: () => {},
        },
        { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
        {
          key: 'delete',
          text: 'Delete',
          iconProps: { iconName: 'Delete' },
          onClick: () => {},
        },
      ],
    }),
    [],
  );

  return (
    <div
      className={classNames(styles.root, className, {
        [styles.completed]: !!completed,
      })}
    >
      <Checkbox
        className="checkbox"
        checked={!!completedAt}
        onChange={onCheckChanged}
      />
      <Text className="text">{text}</Text>
      {!!artifacts?.length ? (
        <OverflowSet
          className="artifacts"
          items={[{ key: firstArtifact!.id.toString(), ...firstArtifact }]}
          overflowItems={remainingArtifacts.map((a) => ({
            key: a.id.toString(),
            ...a,
          }))}
          onRenderItem={(item) => (
            <div className="artifact" key={item.id}>
              <Icon iconName="Attach" />
              <Text variant="xSmall" onClick={() => onArtifactClick?.(item.id)}>
                {item.label || item.name}
              </Text>
            </div>
          )}
          onRenderOverflowButton={() => (
            <div className="artifact-overflow">
              <Icon iconName="More" />
              <Text variant="xSmall">{`+${remainingArtifacts.length}`}</Text>
            </div>
          )}
        />
      ) : null}
      {primaryViewers.length > 0 ? (
        <div className="viewers">
          <Facepile
            personaSize={PersonaSize.size24}
            personas={primaryViewers.map((viewer) => ({
              imageUrl: viewer.avatarUrl || undefined,
              text: viewer.name || viewer.email,
              title: viewer.name || viewer.email,
            }))}
            overflowPersonas={overflowViewers.map((viewer) => ({
              imageUrl: viewer.avatarUrl || undefined,
              text: viewer.name || viewer.email,
              title: viewer.name || viewer.email,
            }))}
          />
        </div>
      ) : null}
      {dueDateDateTime ? (
        <div className={classNames('due-date')}>
          <div className={classNames({ overdue, 'near-due': nearDue })}>
            <Text variant="xSmall">
              {humanizeDateTime(dueDate, { displayComponents: ['date'] })}
            </Text>
          </div>
        </div>
      ) : null}
      <IconButton
        className="context-menu"
        iconProps={{ iconName: 'More' }}
        menuProps={menuProps}
        menuIconProps={{ style: { display: 'none' } }}
      />
    </div>
  );
};
