import { useAuth0 } from '@auth0/auth0-react';
import {
  DefaultButton,
  Facepile,
  FontSizes,
  IColumn,
  IPersona,
  mergeStyles,
  NeutralColors,
  SelectionMode,
  Spinner,
  Text,
  Toggle,
} from '@fluentui/react';
import { DealRoom } from '@meetingflow/common/Api/data-contracts';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { EMPTY_ARRAY } from '../../Constants';
import {
  collectToRecord,
  deleteValue,
  setValue,
} from '../../Helpers/SearchParamHelpers';
import { useNewDealRoomDialog } from '../../Hooks/Modals/DealRoom/useNewDealRoomDialog';
import { useConfirmationDialog } from '../../Hooks/Modals/useConfirmationDialog';
import useBreakpoints from '../../Hooks/useBreakpoints';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import { useTitle } from '../../Hooks/useTitle';
import { OrganizationDealRoomsQuery } from '../../QueryNames';
import { DealRoomsApiClient } from '../../Services/NetworkCommon';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { AsyncDefaultButton, AsyncIconButton } from '../HOC/AsyncButton';
import { ContactCard } from '../MeetingPlans/MeetingPlanAttendees';
import { StyledDetailsList } from '../StyledDetailsList';

const limit = 50;

export const DealRoomList = () => {
  useTitle('Deal Rooms - Library');

  const {
    slug,
    isLoading,
    isAdmin,
    canCreatePlans,
    internalDomains,
    hasEntitlement,
  } = useOrganization();

  const { userId } = useUserProfile();
  const navigate = useNavigate();

  const appInsights = useAppInsightsContext();

  const breakpoints = useBreakpoints();

  const { getAccessTokenSilently } = useAuth0();

  const [searchParams, setSearchParams] = useSearchParams();

  const { isDark } = useLightOrDarkMode();

  const [searchFilters, filterString] = useMemo(() => {
    searchParams.sort();
    return [collectToRecord(searchParams), searchParams.toString()];
  }, [searchParams]);

  const [debouncedFilterString] = useDebounce(filterString, 500);

  const {
    createDeferred: createNewDealRoomDeferred,
    dialog: newDealRoomDialog,
  } = useNewDealRoomDialog({
    organizationSlug: slug!,
  });

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Deal Room?`,
    subText:
      'Deleting this Deal Room will delete it for all users and cannot be undone. Are you sure you want to delete this Deal Room?',
    primaryAction: 'CANCEL',
  });

  const parentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { ref, inView } = useInView({ root: parentRef.current });

  useEffect(() => {
    if (!slug) {
      navigate(`/`);
      return;
    } else if (!isLoading && !hasEntitlement('DEAL_ROOM')) {
      navigate(`/organization/${slug}/library`);
      return;
    }
  }, [slug, isLoading, hasEntitlement, navigate]);

  const {
    data: dealRoomsData,
    isLoading: dealRoomsLoading,
    isFetched: dealRoomsFetched,
    isFetching: dealRoomsFetching,
    isRefetching: dealRoomsRefetching,
    isFetchingPreviousPage,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    OrganizationDealRoomsQuery(slug!, debouncedFilterString, limit),
    async ({ pageParam, queryKey, meta }) => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRooms(
        {
          ...searchFilters,
          organizationSlug: slug!,
          limit,
          skip: pageParam || 0,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      getPreviousPageParam: (previousPage) => {
        if (!previousPage?.config?.params?.skip) {
          return undefined;
        }
        return Math.max((previousPage?.config?.params?.skip ?? 0) - limit, 0);
      },
      getNextPageParam: (previousPage) => {
        if (!previousPage?.data?.length || previousPage.data.length < limit) {
          return undefined;
        }

        return (previousPage?.config?.params?.skip ?? 0) + limit;
      },
    },
  );

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const onRenderName = useCallback(
    (dealRoom: DealRoom) => {
      return (
        <div
          onClick={() => {
            navigate(`/organization/${slug}/dealroom/${dealRoom.id}?buyerView=true`);
          }}
        >
          <Text variant="medium" block>
            {dealRoom.name}
          </Text>
          {dealRoom.description && (
            <Text variant="small" block>
              {dealRoom.description}
            </Text>
          )}
        </div>
      );
    },
    [navigate, slug],
  );

  const onRenderContacts = useCallback(
    (dealRoom: DealRoom) => {
      if (!dealRoom.contacts?.length) {
        return <></>;
      }

      const personas = dealRoom.contacts
        .filter((c) => !internalDomains.includes(c.emailDomain))
        .map((contact) => {
          return {
            imageUrl: contact.avatarUrl || undefined,
            personaName: contact.name || contact.email,
            data: contact,
          } satisfies IPersona;
        });

      return <Facepile personas={personas} />;
    },
    [internalDomains],
  );

  const onRenderContact = useCallback(
    (dealRoom: DealRoom) =>
      slug && dealRoom.owner ? (
        <ContactCard
          contact={dealRoom.owner}
          organizationSlug={slug}
          domain={dealRoom.owner.email.split('@')[0]}
          hideBottomBorder
          hideSocialIcons
          showOnlyAvatar={!breakpoints?.lg}
        />
      ) : (
        <></>
      ),
    [slug, breakpoints],
  );

  const onRenderDelete = useCallback(
    (dealRoom: DealRoom) => {
      const canDelete = isAdmin || dealRoom.owner?.id === userId;
      return (
        <AsyncIconButton
          name="Delete"
          iconProps={{ iconName: 'Delete' }}
          disabled={!canDelete}
          onClick={async () => {
            try {
              const confirmDelete = await createConfirmDeleteDeferred().promise;

              if (!confirmDelete) {
                return;
              }

              const token = await getAccessTokenSilently();
              await toast.promise(
                DealRoomsApiClient.deleteDealRoom(slug!, dealRoom.id, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
                {
                  loading: 'Deleting Deal Room',
                  success: (_response) => {
                    refetch();

                    appInsights.trackEvent({
                      name: 'DELETE_DEALROOM',
                      properties: {
                        organizationSlug: slug,
                        dealRoomId: dealRoom.id,
                      },
                    });

                    return 'Successfully deleted Deal Room';
                  },
                  error: (err: unknown) => {
                    return 'Something went wrong deleting Deal Room, please try again later';
                  },
                },
              );
            } catch (err: unknown) {}
          }}
          text="Delete"
        />
      );
    },
    [
      appInsights,
      createConfirmDeleteDeferred,
      getAccessTokenSilently,
      isAdmin,
      slug,
      refetch,
      userId,
    ],
  );

  const dealRoomColumns: IColumn[] = useMemo(
    () =>
      [
        {
          key: 'name',
          name: 'Name',
          minWidth: breakpoints.lg ? 300 : 150,
          fieldName: 'name',
          onRender: onRenderName,
        },
        {
          key: 'contacts',
          name: 'Contacts',
          minWidth: breakpoints.lg ? 500 : 300,
          fieldName: 'contacts',
          onRender: onRenderContacts,
        },
        {
          key: 'owner',
          name: 'Owner',
          minWidth: breakpoints?.lg ? 250 : 32,
          maxWidth: breakpoints?.lg ? 250 : 32,
          fieldName: 'owner',
          className: breakpoints?.md ? 'creator' : 'hidden',
          headerClassName: breakpoints?.md ? 'creator' : 'hidden',
          onRender: onRenderContact,
        },
        {
          key: 'delete',
          name: 'Delete',
          minWidth: 80,
          maxWidth: 80,
          fieldName: 'delete',
          className: 'delete',
          onRender: onRenderDelete,
        },
      ].filter(Truthy),
    [
      breakpoints.lg,
      breakpoints?.md,
      onRenderName,
      onRenderContact,
      onRenderContacts,
      onRenderDelete,
    ],
  );

  const tableControlsClass = mergeStyles({
    display: 'flex',
    flexDirection: 'row',
    columnGap: '.25rem',
    margin: '1rem .25rem .5rem .25rem',
    overflowX: 'auto',
    overflowY: 'hidden',
  });

  const clearButtonStyles = useMemo(
    () => ({
      root: {
        height: '1.5rem',
        backgroundColor: MEETINGFLOW_COLORS.purpleMedium,
        border: 'none !important',
        transition: '.3s ease-in-out all',
        'div:after': {
          display: 'none !important',
        },
      },
      rootDisabled: {
        backgroundColor: 'transparent',
      },
      rootHovered: {
        backgroundColor: MEETINGFLOW_COLORS.purpleDarker,
      },
      icon: {
        color: 'white',
      },
    }),
    [],
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <div className={tableControlsClass}>
          <Toggle
            label={'Active'}
            checked={
              searchParams.has('active') &&
              searchParams.get('active') === 'true'
            }
            onChange={(e, checked) => {
              if (checked) {
                setSearchParams(setValue(searchParams, 'active', 'true'));
              } else {
                setSearchParams(deleteValue(searchParams, 'active'));
              }
            }}
            styles={{
              root: {
                display: 'inline-block',
                margin: 0,
                flexGrow: 1,
                transition: '.3s ease-in-out all',
                height: '1.5rem',
                position: 'relative',
                minWidth: '7rem',

                '.ms-Toggle-innerContainer': {
                  display: 'inline-block',
                  position: 'relative',
                  top: '.2rem',

                  button: {
                    transition: 'all .3s ease-in-out',
                    backgroundColor: isDark
                      ? NeutralColors.gray180
                      : MEETINGFLOW_COLORS.purpleGrey,
                  },
                },
                '.ms-Toggle-thumb': {
                  backgroundColor:
                    searchParams.has('active') &&
                    searchParams.get('active') === 'true'
                      ? MEETINGFLOW_COLORS.purpleTertiary
                      : isDark
                        ? NeutralColors.gray130
                        : NeutralColors.gray70,
                },
                'button:disabled .ms-Toggle-thumb': {
                  backgroundColor: NeutralColors.gray70,
                },
                'button:hover': {
                  backgroundColor: isDark
                    ? NeutralColors.gray190
                    : NeutralColors.gray40,
                },
                button: {},
              },
              label: {
                padding: 0,
                height: '1.5rem',
                lineHeight: '1.5rem',
                fontSize: FontSizes.mini,
                color: NeutralColors.gray120,
                maxWidth: '5rem',
                marginRight: '.25rem',
              },
              pill: {
                backgroundColor: NeutralColors.gray40,
                outlineWidth: `0 !important`,
                borderWidth: `0 !important`,
              },
            }}
          />

          <Toggle
            label={'Mine'}
            checked={
              searchParams.has('my') && searchParams.get('my') === 'true'
            }
            onChange={(e, checked) => {
              if (checked) {
                setSearchParams(setValue(searchParams, 'my', 'true'));
              } else {
                setSearchParams(deleteValue(searchParams, 'my'));
              }
            }}
            styles={{
              root: {
                display: 'inline-block',
                margin: 0,
                flexGrow: 1,
                transition: '.3s ease-in-out all',
                height: '1.5rem',
                position: 'relative',
                minWidth: '7rem',

                '.ms-Toggle-innerContainer': {
                  display: 'inline-block',
                  position: 'relative',
                  top: '.2rem',

                  button: {
                    transition: 'all .3s ease-in-out',
                    backgroundColor: isDark
                      ? NeutralColors.gray180
                      : MEETINGFLOW_COLORS.purpleGrey,
                  },
                },
                '.ms-Toggle-thumb': {
                  backgroundColor:
                    searchParams.has('my') && searchParams.get('my') === 'true'
                      ? MEETINGFLOW_COLORS.purpleTertiary
                      : isDark
                        ? NeutralColors.gray130
                        : NeutralColors.gray70,
                },
                'button:disabled .ms-Toggle-thumb': {
                  backgroundColor: NeutralColors.gray70,
                },
                'button:hover': {
                  backgroundColor: isDark
                    ? NeutralColors.gray190
                    : NeutralColors.gray40,
                },
                button: {},
              },
              label: {
                padding: 0,
                height: '1.5rem',
                lineHeight: '1.5rem',
                fontSize: FontSizes.mini,
                color: NeutralColors.gray120,
                maxWidth: '5rem',
                marginRight: '.25rem',
              },
              pill: {
                backgroundColor: NeutralColors.gray40,
                outlineWidth: `0 !important`,
                borderWidth: `0 !important`,
              },
            }}
          />

          <DefaultButton
            iconProps={{ iconName: 'ClearFilter' }}
            label="Clear filters"
            alt="Clear filters"
            disabled={Array.from(searchParams.keys()).length === 0}
            onClick={async () => setSearchParams(new URLSearchParams())}
            styles={clearButtonStyles}
          />

          <AsyncDefaultButton
            iconProps={{ iconName: 'Add' }}
            label="New"
            text="New"
            alt="New"
            disabled={!canCreatePlans}
            styles={clearButtonStyles}
            onClick={async () => {
              try {
                const newDealRoom = await createNewDealRoomDeferred().promise;

                const token = await getAccessTokenSilently();

                const createdDealRoom = await DealRoomsApiClient.createDealRoom(
                  slug!,
                  {
                    name: newDealRoom.name,
                    description: newDealRoom.description,
                    contacts: newDealRoom.contacts,
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  },
                );

                if (createdDealRoom.data.id) {
                  appInsights.trackEvent({
                    name: 'CREATE_DEALROOM',
                    properties: {
                      organizationSlug: slug,
                      dealRoomId: createdDealRoom.data.id,
                    },
                  });

                  navigate(
                    `/organization/${slug}/dealroom/${createdDealRoom.data.id}`,
                  );
                }
              } catch (err: unknown) {
                if (err instanceof Error) {
                  toast.error(`Something went wrong creating Deal Room`);
                }
              }
            }}
          />
        </div>
        <div
          ref={parentRef}
          style={{
            maxHeight: 'calc(100% - 4rem)',
            overflowY: 'auto',
            height: `calc(100% - 4rem)`,
            backgroundColor: isDark
              ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
              : MEETINGFLOW_COLORS.white,
          }}
        >
          {dealRoomsData?.pages?.length ? (
            dealRoomsData?.pages.map((page, index) => {
              return (
                <StyledDetailsList
                  key={index}
                  enableShimmer={dealRoomsFetching && !dealRoomsFetched}
                  shimmerLines={25}
                  items={page?.data || EMPTY_ARRAY}
                  selectionMode={SelectionMode.none}
                  columns={dealRoomColumns}
                  isHeaderVisible={index === 0}
                  className="small-header"
                />
              );
            })
          ) : (
            <StyledDetailsList
              enableShimmer
              shimmerLines={25}
              items={EMPTY_ARRAY}
              selectionMode={SelectionMode.none}
              columns={dealRoomColumns}
              isHeaderVisible={false}
              className="small-header"
            />
          )}

          {dealRoomsData?.pages ? (
            <div ref={ref}>
              <button
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                style={{
                  display: hasNextPage ? 'block' : 'none',
                  margin: '0 auto',
                }}
              >
                {isFetchingNextPage
                  ? 'Loading more...'
                  : hasNextPage
                    ? 'Load More'
                    : 'Nothing more to load'}
              </button>
              <div>
                {dealRoomsFetching && !isFetchingNextPage
                  ? 'Background Updating...'
                  : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {newDealRoomDialog}
      {confirmDeleteDialog}
    </>
  );
};
